import React, { Component } from "react";
import { Form, FormGroup, Row, Col, Button, Input, Label } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import { ViewState, EditingState, IntegratedEditing  } from "@devexpress/dx-react-scheduler";
import {
  Scheduler,
  DayView,
  ViewSwitcher,
  Toolbar,
  WeekView,
  MonthView,
  DateNavigator,
  TodayButton,
  Appointments,
  AppointmentTooltip,
  AppointmentForm,
  DragDropProvider,
  ConfirmationDialog,
  AllDayPanel,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler-material-ui";
import { makeStyles } from '@material-ui/core/styles';
import { getFieldEvents, addFieldEvent, deleteFieldEvent, editFieldEvent, clearReduxEvents } from "../../../../actions/areas"
import { connect } from "react-redux";
import classnames from "classnames";
import { TypesToColors } from "../../../../reducers/areas";
import { LoadingSpinerComponent } from "../../../partials/LoadingSpiner";

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.state = {
        data: [],
        action: Object.keys(TypesToColors)[0],
        currentDate: new Date()};

    this.onCommitChanges = this.commitChanges.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps != prevState) {
      if(nextProps.active && nextProps.events.length == 0) 
        nextProps.getFieldEvents(nextProps.field.id);
      return {data: nextProps.events};
    } else return null;
  }

  componentWillUnmount(){
    this.props.clearReduxEvents();
  }

  commitChanges({ added, changed, deleted }) {
    this.setState((state) => {
      let { data } = state;
      const { isShiftPressed } = this.state;
      if (added) {
        const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
        data = [...data, { id: startingAddedId, ...added }];
        this.props.addFieldEvent(this.props.field.id, added, this.state.action);
      }
      if (changed) {
        if (isShiftPressed) {
          const changedAppointment = data.find(appointment => changed[appointment.id]);
          const startingAddedId = data.length > 0 ? data[data.length - 1].id + 1 : 0;
          data = [
            ...data,
            { ...changedAppointment, id: startingAddedId, ...changed[changedAppointment.id] },
          ];
        } else {
          data = data.map(appointment => (
            changed[appointment.id]
              ? { ...appointment, ...changed[appointment.id] }
              : appointment));
        }
        this.props.editFieldEvent(this.props.field.id, changed, this.state.action);
      }
      if (deleted !== undefined) {
        data = data.filter(appointment => appointment.id !== deleted);
        this.props.deleteFieldEvent(this.props.field.id,deleted)
      }
    });
  }

  useStyles = makeStyles(theme => ({
    line: {
      height: '2px',
      borderTop: `2px ${theme.palette.primary.main} dotted`,
      width: '100%',
      transform: 'translate(0, -1px)',
    },
    circle: {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      borderRadius: '50%',
      transform: 'translate(-50%, -50%)',
      background: theme.palette.primary.main,
    },
    nowIndicator: {
      position: 'absolute',
      zIndex: 1,
      left: 0,
      top: ({ top }) => top,
    },
  }));

  BasicLayout = ({ onFieldChange, appointmentData, ...restProps }) => {
    const onActionChange = (nextValue) => {
      this.setState({action: nextValue})
      onFieldChange({action: nextValue});
    }
    let SelectOptions = [];
    Object.keys(TypesToColors).forEach((type) => {
      let option = {};
      option.text = type;
      option.id = type;
      SelectOptions.push(option); //create the option Object that AppointmentForm.Select requires for its "availableOptions" property
    });

    return (
      <AppointmentForm.BasicLayout
        appointmentData={appointmentData}
        onFieldChange={onFieldChange}
        {...restProps}
      >
        <AppointmentForm.Label className="mt-3" text="Select action"/>
        <AppointmentForm.Select
          onValueChange={onActionChange} 
          availableOptions={SelectOptions} 
          type = "outlinedSelect" 
          value={this.state.action} readOnly={false}
        />
      </AppointmentForm.BasicLayout>
    );
  };

  Appointment = ({
    children, style, ...restProps
  }) => { 
  return(
    <Appointments.Appointment
      {...restProps}
      style={{
        backgroundColor: restProps.data.backgroundColor,
        // color: restProps.data.textColor, //this should change appointment text color but it doesn't ??
        borderRadius: '8px',
      }}
    >
      {children}
    </Appointments.Appointment>
  )};

  TimeIndicator = ({
    top, ...restProps
  }) => {
    const classes = this.useStyles({ top });
    return (
      <div {...restProps}>
        <div className={classnames(classes.nowIndicator, classes.circle)} />
        <div className={classnames(classes.nowIndicator, classes.line)} />
      </div>
    );
  };
  

  render() {
    const { data, currentDate } = this.state; 
    const messages = {
        moreInformationLabel: '',
      };
    return (
      <Form>
        <Row>
          <Paper>
            <Scheduler data={data} height={700}>
              <ViewState
                defaultCurrentDate={currentDate}
                defaultCurrentViewName={"Month"}
              />

              <EditingState
                onCommitChanges={(e) => {
                  this.commitChanges(e);
                }}
                onEditingAppointmentChange={(e)=>{
                  if (e != undefined) this.setState({action: e.action}) //when an event is opened, set state's action to the current event's action
                  else this.setState({action: Object.keys(TypesToColors)[0]}) //when an event is closed -> e == undefined. So, this resets the state's action to the 1st value of TypesToColors
                }}
              />

              <IntegratedEditing />

              <DayView startDayHour={0} endDayHour={24} />
              <WeekView startDayHour={0} endDayHour={24} />
              <MonthView />

              <Toolbar />
              <DateNavigator />
              <TodayButton />
              <ViewSwitcher />
              <AllDayPanel/>
              {/* {data.filter(event => event.allDay == true).length > 0 ? <AllDayPanel/> : null} */}

              <Appointments appointmentComponent={this.Appointment} />
              <AppointmentTooltip showOpenButton />
              <ConfirmationDialog />
              <AppointmentForm
                basicLayoutComponent={this.BasicLayout}
                messages={messages}
              />
              {/* <DragDropProvider/> drag & drop | resize are a bit buggy*/}
              <CurrentTimeIndicator
                shadePreviousCells
                indicatorComponent={this.TimeIndicator}/>
            </Scheduler>
          </Paper>
        </Row>
      </Form>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    field: state.areas.selectedField.field,
    events: state.areas.selectedField.events
  };
}

export default connect(mapStateToProps, { getFieldEvents, addFieldEvent, deleteFieldEvent, editFieldEvent, clearReduxEvents })(Calendar);
