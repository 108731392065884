import React, { Component } from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getAreaSoilTypes } from "../../../../../actions/areas";
import { getCultivations } from "../../../../../actions/cultivations";
import EditForm from "./Edit";
import ViewForm from "./View";

class Basic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      submitted: false,
    };
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {
    this.props.getCultivations();
    this.props.getAreaSoilTypes();
  }

  toggleEdit = (e) => {
    this.setState({ editing: !this.state.editing });
  };

  render() {
    const { editing } = this.state;
    return (
      <Row>
        {editing ? (
          <EditForm submitted={false} toggleEdit={this.toggleEdit} />
        ) : (
          <ViewForm toggleEdit={this.toggleEdit} />
        )}
      </Row>
    );
  }
}

export default connect(null, {
  getAreaSoilTypes,
  getCultivations,
})(withRouter(Basic));
