import React, { Component } from "react";
import { saveAs } from 'file-saver';
import { Input, Label, FormGroup, Form, Col, Row, Button, Card, CardBody} from "reactstrap"
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators, CarouselCaption } from "reactstrap";
import Lightbox from "react-image-lightbox";
import EmptyDataset from '../../../../../../layout/EmptyDataset';
import "react-image-lightbox/style.css";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import { connect } from 'react-redux';

class CarouselImagesUAV extends Component {
    constructor(props) {
        super(props);
        this.state = {
            photoIndex: 0,
            LightBoxIsOpen: false,
            weeds: [],
            activeIndex: 0,
            weedSample: "1",
            componentMounted: false,
            containsSamples: false,
        }
        this.handleSelection = this.handleSelection.bind(this);
        this.closeModal = this.closeModal.bind(this);

        //Carousel
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
        this.downloadWeedSample = this.downloadWeedSample.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.weeds.length !== 0)
            this.setState({ containsSamples: true, field:this.props.field, weeds: nextProps.weeds });
        else
            this.setState({ containsSamples: false, field:this.props.field, weeds: nextProps.weeds });
    }

    componentDidMount() {
        this.setState({ componentMounted: true, weeds: this.props.weeds });
    }

    handleSelection(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex == this.state.weeds["samples"][this.state.weedSample].length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({ activeIndex: nextIndex });
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex == 0 ? this.state.weeds["samples"][this.state.weedSample].length - 1 : this.state.activeIndex - 1;
        this.setState({ activeIndex: nextIndex });
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({ activeIndex: newIndex });
    }

    downloadWeedSample() {
        var Download_Link = this.state.weeds["samples"][this.state.weedSample][this.state.activeIndex].file;
        var filename_link = Download_Link.split('/');
        var filename = this.state.field["properties"]["name"] + "-" + filename_link[filename_link.length - 2] + "-" + filename_link[filename_link.length - 1];
        fetch(Download_Link + "?"+(new Date()).getTime())
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, filename);
            });
    }

    closeModal() {
        this.props.onCloseClick(false);
    }

    render() {
        const { activeIndex } = this.state;
        if (!this.state.componentMounted) {
            return <div />;
        }

        if(this.state.weeds.length==0) 
            return (
                <EmptyDataset message="There are no available UAV Samples" icon={faDatabase} />
            );

        if (this.state.containsSamples)
            var samplesQ = (Object.keys(this.state.weeds["samples"]).length + 1);

        const isOpen = this.state.LightBoxIsOpen;

        return (
            <Row className="h-100">
                <Col md="8">
                    <Card>
                        <CardBody>
                            <Form className="h-100">
                                <Row>
                                    <Col className="d-flex justify-content-between">
                                        <FormGroup>
                                            {this.state.weeds.length != 0 ?
                                                [
                                                    <Input key={1} type="select" onChange={(event) => { this.handleSelection(event); this.goToIndex(0); this.setState({activeIndex: 0}); document.activeElement.blur() }} required defaultValue="1" name="weedSample" id="dateForWeeds">
                                                        {[...Array(samplesQ)].map((__UNUSED__, sampleIndex) =>
                                                            sampleIndex != 0 ? <option key={sampleIndex} value={sampleIndex}>Sample {sampleIndex}</option> : null
                                                        )}
                                                    </Input>
                                                ] :
                                                [
                                                    <Label key={2} className="text-right" for="dateForWeeds">There are no available images</Label>
                                                ]
                                            }
                                        </FormGroup>
                                        <FormGroup className="text-center">
                                            <Button color="primary" onClick={this.downloadWeedSample}>Download</Button>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup>
                                    <Carousel key={0} activeIndex={activeIndex} next={this.next} previous={this.previous} interval={false} >
                                        <CarouselIndicators items={this.state.weeds["samples"][this.state.weedSample]} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
                                        {this.state.weeds["samples"][this.state.weedSample].map((image, key) => {
                                            return (
                                                <CarouselItem onExiting={this.onExiting} onExited={this.onExited} key={key} >
                                                    <a onClick={()=>this.setState({LightBoxIsOpen: true})}  data-lightbox={"collection-" + this.state.weedSample}>
                                                        <img onClick={() => {this.setState({ isOpen: true }); }} className="carousel-image img-fluid" src={image.thumbnail} alt="test" style={{ cursor: "zoom-in" }} />
                                                    </a>
                                                    <CarouselCaption className="carouselCaption text-white d-block" captionText={image.created_at} />
                                                </CarouselItem>
                                            );
                                        })
                                        }
                                        <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
                                        <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
                                    </Carousel>


                                    {isOpen && (
                                        <Lightbox id="myLightbox" style={{zIndex:'2000'}}
                                            mainSrc={this.state.weeds["samples"][this.state.weedSample][activeIndex].file}
                                            nextSrc={this.state.weeds["samples"][this.state.weedSample][(activeIndex + 1) % this.state.weeds["samples"][this.state.weedSample].length].file}
                                            prevSrc={this.state.weeds["samples"][this.state.weedSample][(activeIndex + this.state.weeds["samples"][this.state.weedSample].length - 1) % this.state.weeds["samples"][this.state.weedSample].length].file}
                                            onCloseRequest={() => this.setState({ LightBoxIsOpen: false })}
                                            onMovePrevRequest={() =>
                                                this.setState({
                                                    activeIndex: (activeIndex + this.state.weeds["samples"][this.state.weedSample].length - 1) % this.state.weeds["samples"][this.state.weedSample].length
                                                })
                                            }
                                            onMoveNextRequest={() =>
                                                this.setState({
                                                    activeIndex: (activeIndex + 1) % this.state.weeds["samples"][this.state.weedSample].length
                                                })
                                            }
                                        />
                                    )}
                                </FormGroup>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => {
    return {
        field: state.areas.selectedField.field
    }
}

export default connect(mapStateToProps,{})(CarouselImagesUAV);