
export const EGSA87_to_WGS84 = (x_, y_) => {
    //converts x,y (easting, northing) of  EGSA87 projection to φ,λ of WGS84
    let temparr = to_fl_from_proj_xy(x_, y_);

    temparr=flh2xyz(temparr[0], temparr[1], 0);

    temparr=egsa87xy_to_wgs84xy(temparr[0], temparr[1], temparr[2]);

    temparr=xyz2flh(temparr[0], temparr[1], temparr[2]);
    return [temparr[1], temparr[0]];
    }


function to_fl_from_proj_xy(east_, north_, type_ = "EGSA87", )
//given x,y (east,north in greek UTM projection this function calulates the f,l on greek ellipsoid (GRS80) 
//the $type can be either EGSA87 or HTRS07 which differ only in the False Northing value
{
    let equatorial_radius = 6378137;  //equatorial radius
    let f = 1 / 298.257222100882711;
    //equatorial_radius=6377563.396;			//airy
    //f=1/299.32496;	//Airy
    let e = Math.sqrt(2 * f - f * f);
    let l_orig = 24 * Math.PI / 180;				//Longitude of natural origin
    let f_orig = 0 * Math.PI / 180;				//Latitude of natural origin
    let k_orig = 0.9996;			//Scale factor at natural origin
    let false_easting = 500000;							//False easting
    let false_northing = 0;
    if (type_ == "EGSA87") false_northing = 0;			//False northing
    else if (type_ == "HTRS07") false_northing = -2000000;

    //$b=6356752.314140347;  //grs80: polar radius
    //f=(equatorial_radius-$b)/equatorial_radius;

    let n = f / (2 - f);
    let B = equatorial_radius / (1 + n) * (1 + n * n / 4 + n * n * n * n / 64);

    let h1 = n / 2 - 2 / 3 * n * n + 5 / 16 * n * n * n + 41 / 180 * n * n * n * n;
    let h2 = 13 / 48 * n * n - 3 / 5 * n * n * n + 557 / 1440 * n * n * n * n;
    let h3 = 61 / 240 * n * n * n - 103 / 140 * n * n * n * n;
    let h4 = 49561 / 161280 * n * n * n * n;

    //meridional arc distance from equator to projection origin (Mo)
    let Qo = Math.asinh(Math.tan(f_orig)) - e * Math.atanh(e * Math.sinh(f_orig));
    let bo = Math.atanh(Math.sinh(Qo));
    let ksio0 = Math.asin(Math.sin(bo));
    let ksio1 = h1 * Math.sin(2 * ksio0);
    let ksio2 = h2 * Math.sin(4 * ksio0);
    let ksio3 = h3 * Math.sin(6 * ksio0);
    let ksio4 = h4 * Math.sin(8 * ksio0);
    let ksio = ksio0 + ksio1 + ksio2 + ksio3 + ksio4;
    let Mo = B * ksio;
    //up to here the same as toxy

    let h1t = n / 2 - 2 / 3 * n * n + 37 / 96 * n * n * n - 1 / 360 * n * n * n * n;
    let h2t = 1 / 48 * n * n + 1 / 15 * n * n * n - 437 / 1440 * n * n * n * n;
    let h3t = 17 / 480 * n * n * n - 37 / 840 * n * n * n * n;
    let h4t = 4397 / 161280 * n * n * n * n;
    let itat = (east_ - false_easting) / (B * k_orig);
    let ksit = (north_ - false_northing + k_orig * Mo) / (B * k_orig);
    let ksi1t = h1t * Math.sin(2 * ksit) * Math.cosh(2 * itat);
    let ita1t = h1t * Math.cos(2 * ksit) * Math.sinh(2 * itat);
    let ksi2t = h2t * Math.sin(4 * ksit) * Math.cosh(4 * itat);
    let ita2t = h2t * Math.cos(4 * ksit) * Math.sinh(4 * itat);
    let ksi3t = h3t * Math.sin(6 * ksit) * Math.cosh(6 * itat);
    let ita3t = h3t * Math.cos(6 * ksit) * Math.sinh(6 * itat);
    let ksi4t = h4t * Math.sin(8 * ksit) * Math.cosh(8 * itat);
    let ita4t = h4t * Math.cos(8 * ksit) * Math.sinh(8 * itat);
    let ksi0t = ksit - (ksi1t + ksi2t + ksi3t + ksi4t);
    let ita0t = itat - (ita1t + ita2t + ita3t + ita4t);
    let vitat = Math.asin(Math.sin(ksi0t) / Math.cosh(ita0t));
    let Qt = Math.asinh(Math.tan(vitat));
    let Qtt = Qt + e * Math.atanh(e * Math.tanh(Qt));
    let Qtt_previous;
    do {
        Qtt_previous = Qtt;
        Qtt = Qt + e * Math.atanh(e * Math.tanh(Qtt));
    } while (Math.abs(Qtt - Qtt_previous) > 1e-12);
    let phi = Math.atan(Math.sinh(Qtt));
    let lambda = l_orig + Math.asin(Math.tanh(ita0t) / Math.cos(vitat));
    return [rad2deg(phi), rad2deg(lambda)];


}
function rad2deg(radians_){
    let pi = Math.PI;
    return radians_*(180/pi);
}
function deg2rad(degrees_){
    let pi = Math.PI;
    return degrees_ * (pi/180);
}

function flh2xyz(phi_, lambda_, h_, ellipsoid_ = "EGSA87")
//convert phi, lambda, h  to x,y,z - h height above ellipsoid surface
//EGSA87 and HTRS07 ellipsoids are the same and differ only by a fraction of a millimeter to WGS84 ellipsoid in small semi-axis
{
    let phi = deg2rad(phi_);
    let lambda = deg2rad(lambda_);
    let f;
    let equatorial_radius = 6378137;  //equatorial radius
    if (ellipsoid_ == "WGS84") f = 1 / 298.257223563;
    else if (ellipsoid_ == "EGSA87" || ellipsoid_ == "HTRS07") f = 1 / 298.257222100882711243;
    let e2 = 2 * f - f * f;
    //$epsilon=e2_/(1-e2_);
    let greekni = equatorial_radius / Math.sqrt(1 - e2 * Math.sin(phi) * Math.sin(phi));  //prime vertical radius of curvature at latitude φ
    let X = (greekni + h_) * Math.cos(phi) * Math.cos(lambda);
    let Y = (greekni + h_) * Math.cos(phi) * Math.sin(lambda);
    let Z = ((1 - e2) * greekni + h_) * Math.sin(phi);
    //echo "\nIn function:".__FUNCTION__."\n";
    //print_r( get_defined_vars()); 
    return [X, Y, Z];
}

function egsa87xy_to_wgs84xy(x_, y_, z_) {
    return [x_ - 199.723, y_ + 74.03, z_ + 246.018];
}

function xyz2flh(xo_, yo_, zo_, ellipsoid_="WGS84")
//convert x,y,z to phi, lambda, h 
//EGSA87 and HTRS07 ellipsoids are the same and differ only by a fraction of a millimeter to WGS84 ellipsoid in small semi-axis
{
    let equatorial_radius = 6378137;  //equatorial radius
    let f;
    if (ellipsoid_ == "WGS84") f = 1 / 298.257223563;
    else if (ellipsoid_ == "EGSA87" || ellipsoid_ == "HTRS07") f = 1 / 298.257222100882711243;
    let e2 = 2 * f - f * f;
    let epsilon = e2 / (1 - e2);
    let bi = equatorial_radius * (1 - f);
    let p = Math.sqrt(xo_ * xo_ + yo_ * yo_);
    let q = Math.atan(zo_ * equatorial_radius / (p * bi));
    let phi = Math.atan((zo_ + epsilon * bi * Math.sin(q) * Math.sin(q) * Math.sin(q)) / (p - e2 * equatorial_radius * Math.cos(q) * Math.cos(q) * Math.cos(q)));
    let greekni = equatorial_radius / Math.sqrt(1 - e2 * Math.sin(phi) * Math.sin(phi));  //prime vertical radius of curvature at latitude φ
    let lambda = Math.atan(yo_ / xo_);
    let h = p / Math.cos(phi) - greekni;
    //echo "\nIn function:".__FUNCTION__."\n";
    //print_r( get_defined_vars()); 
    return [rad2deg(phi), rad2deg(lambda), h]
}
