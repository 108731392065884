import React, { Component } from "react";
import Chart from "chart.js"
import { connect } from 'react-redux';
import DataProviderCredits from "./WeatherDataProviders"

class HeatChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: [],
    };

    this.parseTemperatures = this.parseTemperatures.bind(this);

    this.renderHeatCharts = this.renderHeatCharts.bind(this);
  }

  componentDidMount() {
    this.setState({ field: this.props.field });
  }

  parseTemperatures() {
    var forecast = this.state.field.OpenWeather.forecast.list;
    var temperatures = {};
    temperatures.max = [];
    temperatures.min = [];
    temperatures.time = [];
    temperatures.day_change = [];
    for (var key in forecast) {
      temperatures["max"].push(forecast[key].main.temp);
      temperatures["min"].push(forecast[key].main.temp_min);

      var date = forecast[key].dt_txt.toString().split(' ')[0].slice(5, 10).split('-');
      var time = forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5);

      if (time == "00:00") {
        temperatures["time"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
        temperatures["day_change"].push("(" + date[1] + "-" + date[0] + ")" + " " + time)
      }
      else
        temperatures["time"].push(forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5));

      if (key == 16)
        break;
    }

    temperatures.color = 'rgba(255, 0, 0, ';
    return temperatures;
  }

  renderHeatCharts() {
    var myCanvas = document.getElementById('HeatChart').getContext('2d');
    var HeatForecast = this.parseTemperatures();
    var annotations = HeatForecast.day_change.map(function (date, index) {
      return {
        type: 'line',
        id: 'vline' + index,
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: date,
        borderColor: 'black',
        borderWidth: 0.5,
        borderDash: [5, 5]
      }
    });

    var mytooltip = {
      callbacks: {
        title: function () {
          return '';
        }
      }
    }

    new Chart(myCanvas, {
      type: 'line',
      data: {
        labels: HeatForecast["time"],
        datasets: [
          {
            label: 'Temperature (\u2103)',
            data: HeatForecast["max"],
            borderWidth: 1,
            fill: false,
            backgroundColor: HeatForecast.color + " 0.2)",
            borderColor: HeatForecast.color + " 1)"
          }
        ]
      },
      options: {
        // responsive: false,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Heat Forecast"
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        annotation: {
          annotations: annotations
        },
        tooltips: mytooltip
      }
    });
  }

  render() {
    if (this.props.fetched && document.getElementById('HeatChart') != null) this.renderHeatCharts();

    return (
      <div>
        <canvas className="weather-chart" id={this.props.title.replace(' ', '') + "Chart"}></canvas>
        <DataProviderCredits DataType="Weather" DataSource={this.props.DataSource} hidden={!this.props.fetched}></DataProviderCredits>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let fetched = false;

  if (Object.keys(state.areas.selectedField.field.OpenWeather.forecast).length != 0)
    fetched = true;
  return { field: state.areas.selectedField.field, fetched: fetched }
}

export default connect(mapStateToProps)(HeatChart)