import React, { Component} from 'react';
import { Col,Card, CardTitle, CardBody, Button, Alert } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

class TractorDetails extends Component {
    constructor(props) {
        super();
    }
    
    static propTypes = {
        tractor: PropTypes.object.isRequired,
    }

    render() {
        const tractor = this.props.tractor;
        return (
            <Col sm="12" md="6" lg="4">
                {  tractor ? (
                <Card body>
                <CardTitle className="text-center bg-dark text-white p-2">{tractor.name}</CardTitle>
                <CardBody className="p-2">
                    <p>Model: {tractor.model}</p>
                    <p>Manufacturer: {tractor.brand}</p>
                    <p>Wheel Drive: {tractor.wheels}</p>
                    <p>Price: {tractor.purchase_value}</p>
                    <p>Rated Engine Power(hp): {tractor.engine_power}</p>
                    <p>Max Engine Power(hp): {tractor.max_power}</p>
                    <p>Purchase Year: {tractor.purchase_year}</p>
                    <p>Extra hours of usage(h): {tractor.extra_hours_of_usage}</p>
                </CardBody>
                <Button onClick={() => {this.props.history.push('/machinery')}}>Go back</Button>
                </Card>
                ) : (
                    <Col>
                        <Alert color="danger">
                            We couldn't find the selected tractor.
                        </Alert>
                        <Button onClick={() => {this.props.history.push('/machinery')}}>Go back</Button>
                    </Col>
                    
                )}
            </Col>
        )
    };
}

const mapStateToProps = (state,ownProps) => {
    const id = ownProps.match.params.id
    return {
        tractor:state.tractors.tractors.find(tractor => tractor.id == id)
    }
};

export default connect(mapStateToProps)(withRouter(TractorDetails));