import React, { Component } from "react";
import MainContent from "./components/layout/MainContent";
import Alerts from "./components/layout/Alerts";
import Login from "./components/auth/Login";
import AcceptInvitationForm from "./components/views/Teams/AcceptInvitationForm";
import { Switch, Route } from "react-router-dom";

class App extends Component {
  state = {};

  componentDidMount() {
    const googleMapScript = document.createElement("script");
    googleMapScript.id = "g-script";
    googleMapScript.src =
      process.env.REACT_APP_GMAP_LINK +
      process.env.REACT_APP_GMAP_KEY +
      process.env.REACT_APP_GMAP_LIBRARIES;
    window.document.body.appendChild(googleMapScript);
  }

  render() {
    return (
      <div className="App">
        <div className="app-body p-0 overflow-hidden">
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              path="/team/invitation/:token"
              component={AcceptInvitationForm}
            />
            <MainContent />
          </Switch>
          <Alerts />
        </div>
      </div>
    );
  }
}

export default App;
