import React, { Component, Fragment } from "react";
import { Row, Col, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faTrash,
  faPlus
} from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { getTeams, deleteTeam } from "../../../actions/teams";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

class Teams extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true
        },
        {
          name: "Description",
          selector: "description",
          hide: "md"
        },
        {
          name: "Created",
          selector: "created_at",
          sortable: true,
          format: row => moment(row.created_at).fromNow()
        },
        {
          name: "Actions",
          cell: row => (
            <div>
              <Button
                color="primary"
                className="mr-2"
                onClick={() => this.handleViewTeam(row.id)}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button
                color="secondary"
                onClick={() => this.handleEditTeam(row.id)}
                className="mr-2"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
              <Button
                color="danger"
                onClick={() => this.props.deleteTeam(row.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          grow: 1,
          button: false,
          right: true,
          center: true
        }
      ]
    };
  }

  componentDidMount() {
    this.props.getTeams();
  }

  handleViewTeam = id => {
    this.props.history.push(`/teams/${id}/members`);
  };

  handleCreateTeam = () => {
    this.props.history.push("/teams/add");
  };

  handleEditTeam = id => {
    this.props.history.push(`/teams/${id}/edit`);
  };

  render() {
    return (
      <Fragment>
        <Row>
          <Col className="d-flex align-items-center flex-row">
            <Button
              color="primary"
              className="mt-4 mb-3"
              onClick={this.handleCreateTeam}
            >
              Create Team
              <FontAwesomeIcon className="ml-2" icon={faPlus} />
            </Button>
          </Col>
        </Row>

        <Row>
          <Col>
            <DataTable
              title="Teams"
              striped={true}
              columns={this.state.columns}
              data={this.props.teams}
              highlightOnHover
              defaultSortField="id"
              defaultSortAsc={true}
              responsive={true}
              pagination
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  teams: state.teams.teams
});

export default connect(mapStateToProps, { getTeams, deleteTeam })(
  withRouter(Teams)
);
