import { GET_CULTIVATIONS, DELETE_CULTIVATION, ADD_CULTIVATION, GET_ERRORS, EDIT_CULTIVATION, UPDATE_CULTIVATION } from './types';
import { createMessage } from '../actions/messages';

// Get all Cultivations
export const getCultivations = () => dispatch => {
    window.axios.get('/cultivation')
        .then(res => {
            dispatch({
                type: GET_CULTIVATIONS,
                payload: res.data
            })
        }).catch(error => console.log(error)); 
};

// Delete Cultivations
export const deleteCultivation = (id) => dispatch => {
    window.axios.delete(`/cultivation/${id}/`)
        .then(res => {
            dispatch(createMessage({ deleteCultivation: "Cultivation deleted." }))
            dispatch({
                type: DELETE_CULTIVATION,
                payload: id
            })
        }).catch(error => console.log(error)); 
}

export const addCultivation = (cultivation) => dispatch => {
    window.axios.post("/cultivation/",cultivation)
        .then(res => {
            dispatch(createMessage({ addCultivation: "Cultivation added." }))
            dispatch({
                type: ADD_CULTIVATION,
                payload: res.data
            })
        }).catch(error => {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
                hasErrors: true
            }
            
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        }); 
};

// Edit Cultivations
export const editCultivation = (id) => dispatch => {
        dispatch({
            type: EDIT_CULTIVATION
        })
}

// Update Cultivation
export const updateCultivation = (cultivation) => dispatch => {
    window.axios.put(`/cultivation/${cultivation.id}/`,cultivation)
    .then(res => {
        dispatch({
            type: UPDATE_CULTIVATION,
            payload: res.data
        })
        dispatch(createMessage({ updateCultivation: "Cultivation updated." }))
    }).catch(error => {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
            hasErrors: true
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    });
}