import React, { Component, Fragment } from "react";
import { Button } from "reactstrap"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getPolygonFeatures } from "../../../../helpers/geo";
import { formatAreaToInt, formatArea } from "../../../../helpers/formaters";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AreaAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: [],
    };
  }

  parseFieldsByMembers(fields_, members_) {
    let totalInformation = {};
    totalInformation["total_area"] = 0;
    totalInformation["clients_area"] = {};

    getPolygonFeatures(fields_).forEach((field) => {
      totalInformation["total_area"] += field.properties.area;
      members_.forEach((member_) => {
        let member = member_.user;

        if (totalInformation["clients_area"][member] == undefined)
          //condition to initialize the sub object for each client
          totalInformation["clients_area"][member] = {
            name: member_.profile.first_name + " " + member_.profile.last_name,
            area: 0,
          };

        if (field.properties.owner == member)
          totalInformation["clients_area"][member]["area"] +=
            field.properties.area; //condition to match username
      });
    });
    return totalInformation;
  }

  parseFieldsByTeams(fields_, teams_, members_) {
    let totalInformation = {
      total_area: 0,
      teams_area: {},
      teams_with_clients: {},
    };

    let teams = Object.keys(teams_).map((team_name) => {
      return teams_[team_name];
    });

    let members = Object.keys(members_).map((member_name) => {
      return members_[member_name];
    });

    //initialize each team's area
    teams.forEach((team_) => {
      let team = team_.name;
      if (totalInformation["teams_area"][team] == undefined)
        totalInformation["teams_area"][team] = 0;
    });

    getPolygonFeatures(fields_).forEach((field) => {
      totalInformation["total_area"] += field.properties.area;

      members.forEach((member) => {
        //initialize sub-property of every teams_with_clients property
        if (totalInformation["teams_with_clients"][member.team] == undefined)
          totalInformation["teams_with_clients"][member.team] = {};

        //initialize user occupied area - need this to show zero contribution on the chart
        if (
          totalInformation["teams_with_clients"][member.team][member.user] ==
          undefined
        )
          totalInformation["teams_with_clients"][member.team][member.user] = {};

        //add field's area to the user who owns this field
        if (field.properties.owner == member.user) {
          totalInformation["teams_area"][member.team] += field.properties.area;
          totalInformation["teams_with_clients"][member.team][member.user][
            field.id
          ] = field;
        }
      });
    });
    return totalInformation;
  }

  componentDidMount(){
    const { fields, teams, members } = this.props;
    let chart_options = this.createParentHighChartsOptions(
      this.parseFieldsByTeams(fields, teams, members)
    );

    this.setState({
      chart: [
        <HighchartsReact key="ParentAreaAllocationChart" highcharts={Highcharts} options={chart_options} />,
      ],
    });
  }

  onSliceClick(e, info_, _this) {
    let team_slice = e.point.name.split(":")[0];
    let members = _this.props.members;
    let team_fields = info_["teams_with_clients"][team_slice];

    let fields_ = {};
    let members_ = [];
    Object.keys(team_fields).forEach((member) => {
      fields_ = Object.assign({}, fields_, team_fields[member]);
      members_.push(members.filter((m_) => m_.user == member)[0]);
    });

    let area_per_member = _this.parseFieldsByMembers(fields_, members_);

    let child_chart_options = _this.createChildHighChartsOptions(
      area_per_member,
      team_slice
    );

    let child_chart = _this.state.chart;
    if (child_chart.length == 2) child_chart.splice(1, 1);
    child_chart.push(
      <div key="ChildAreallocationChart">
        <HighchartsReact highcharts={Highcharts} options={child_chart_options} />
        <Button onClick={() => { _this.collapseCharts(_this); }}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </div>
    );

    _this.setState({
      chart: child_chart,
    });
  }

  collapseCharts(_this) {
    let charts = _this.state.chart;
    charts.splice(1, 1);
    _this.setState({ chart: charts })
  }

  createParentHighChartsOptions(info_) {
    let onSliceClick = this.onSliceClick;
    let _this = this;
    let options_ = {
      chart: {
        backgroundColor: "transparent",
      },
      title: null,
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Area (%)",
          data: [
            Object.keys(info_["teams_area"]).map((key) => {
              return {
                name:
                  key +
                  ": " +
                  formatArea(info_["teams_area"][key], "ha") +
                  " ha",
                y: parseFloat(
                  (
                    formatAreaToInt(info_["teams_area"][key] * 100) /
                    formatAreaToInt(info_["total_area"])
                  ).toFixed(2)
                ),
              };
            }),
          ][0],
          type: "pie",
          cursor: "pointer",
          events: {
            click: function (e) {
              onSliceClick(e, info_, _this);
            },
          },
        },
      ],
    };
    return options_;
  }

  createChildHighChartsOptions(info_, team_) {
    console.log(info_)
    let options_ = {
      chart: {
        backgroundColor: "transparent",
      },
      title: {
        text: "Members of " + `"${team_}"`,
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Area (%)",
          data: [
            Object.keys(info_["clients_area"]).map((key) => {
              return {
                name:
                  info_["clients_area"][key]["name"] +
                  ": " +
                  formatArea(info_["clients_area"][key]["area"], "ha") +
                  " ha",
                y: parseFloat(
                  (
                    formatAreaToInt(info_["clients_area"][key]["area"] * 100) /
                    formatAreaToInt(info_["total_area"])
                  ).toFixed(2)
                ),
              };
            }),
          ][0],
          type: "pie",
          cursor: "default",
        },
      ],
    };
    return options_;
  }

  render() {
    return this.state.chart;
  }
}

export default AreaAllocation;
