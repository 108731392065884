import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, 
         Button, Form, FormGroup,FormFeedback, Input, Label } from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { clearErrors } from '../../../actions/messages';
import { withRouter } from 'react-router-dom';
import {addCrop, updateCrop} from '../../../actions/crops';
 
class CropForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id : '',
            name: this.props.crop ? this.props.crop.name : '',
            color: this.props.crop ? this.props.crop.color : '',
            description: this.props.crop ? this.props.crop.description : '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    static propTypes = {
        addCrop: PropTypes.func.isRequired,
        updateCrop: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
    }

    onChange = e => {
        this.setState({[e.target.name] : e.target.value})
    }

    onSubmit = e => {
        e.preventDefault();
        const {id, name, color, description,} = this.state;
        

        if(this.props.crop !== null) {
            const crop = Object.assign({}, {
                id, name, color, description
            });

            this.props.updateCrop(crop);

            if(!this.props.errors.hasErrors) { 
                this.props.history.push('/crops'); 
            }
            
        }else {
            const crop = Object.assign({}, {
                id, name, color, description
            });

            this.props.addCrop(crop);

            if(!this.props.errors.hasErrors) { 
                this.props.history.push('/crops'); 
            }
        }
    }

    onCancel = e => {
        this.setState({
            name: '',
            color: '',
            description:'',
        });
        this.props.clearErrors();
        this.props.history.goBack();
    }

    render() { 

        const {name, color, description} = this.state;
        const errors = this.props.errors;

        return ( 
            <Row>
                <Col sm="12" md="9" lg="6">
                    <Card>
                        <Form id="cropForm" onSubmit={this.onSubmit}>
                            <CardHeader>{ this.props.crop ? 'Edit Crop' : 'Add Crop'}</CardHeader>
                            <CardBody>
                            <Row form>
                                <Col md={6}>
                                    <Label for="name">Name:</Label>
                                    <FormGroup>
                                        <Input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            value={name} 
                                            invalid={errors.hasErrors && errors.msg.name}
                                            onChange={this.onChange}  />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.name}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <Label for="color">Color:</Label>
                                    <FormGroup>
                                        <Input 
                                            type="color" 
                                            name="color" 
                                            id="color"
                                            value={color} 
                                            invalid={errors.hasErrors && errors.msg.color}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.color}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <Label for="description">Description:</Label>
                                    <FormGroup>
                                            <Input 
                                                type="textarea" 
                                                name="description" 
                                                id="description" 
                                                value={description}
                                                invalid={errors.hasErrors && errors.msg.description}
                                                onChange={this.onChange} />
                                            <FormFeedback 
                                                invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.description}
                                            </FormFeedback>
                                        </FormGroup>
                                </Col>
                            </Row>
                        
    
                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary" className="mr-2">Save</Button>
                                <Button color="danger" onClick={this.onCancel}>Cancel</Button>
                            </CardFooter>
                        </Form>
                    </Card>
                </Col>
          </Row>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    const id = ownProps.match.params.id;

    return {
        errors: state.errors,
        messages: state.messages,
        // eslint-disable-next-line
        crop: id ? state.crops.crops.find(crop => crop.id == id) : null
    }
};

 
export default connect(mapStateToProps, { addCrop, updateCrop,clearErrors })(withRouter(CropForm));