import { AUTH_USER_LOADED, AUTH_USER_LOADING, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, UPDATE_USER, GET_ERRORS } from './types';
import { createMessage, returnErrors } from "./messages";
import { trackPromise } from 'react-promise-tracker';

// Create Validation Message
export const loadUser = () => (dispatch,getState) => {
    dispatch({
        type: AUTH_USER_LOADING
    })

    tokenConfig(getState);

    window.axios.get('/auth/user/')
    .then(res => {
        dispatch({
            type: AUTH_USER_LOADED,
            payload: res.data
        })
        dispatch(createMessage({ loginSuccess: "Welcome back!" }))
    }).catch(error => {
        dispatch(returnErrors(error.response.data, error.response.status, true));
        dispatch({
          type: AUTH_ERROR
        });
    }); 
}

export const updateUser = (user) => (dispatch, getState) => {
  trackPromise(
    window.axios
      .patch("/auth/user/", user)
      .then((res) => {
        dispatch(
          createMessage({ updateUser: "User settings has been updated." })
        );
        dispatch({
          type: UPDATE_USER,
          payload: res.data,
        });
      })
      .catch((error) => {
        const errors = {
          msg: error.response.data,
          status: error.response.status,
          hasErrors: true,
        };
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        });
      }),
    "personalInfoForm"
  );
};

// LOGIN USER
export const login = (username, password) => (dispatch, getState) => {
  trackPromise(
    window.axios.post("/auth/login/", {
      'username': username,
      'password': password
    })
      .then(res => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data
        });
      })
      .catch(error => {
        dispatch({
          type: LOGIN_FAIL
        });
      }), "Login-area");

      tokenConfig(getState);
  };


// LOGOUT USER
export const logout = () => (dispatch, getState) => {

    tokenConfig(getState);

    window.axios.post("/auth/logout/", null)
      .then(res => {
        dispatch({
          type: LOGOUT_SUCCESS
        });
        dispatch(createMessage({ logoutSuccess: res.data.detail }))
      })
      .catch(err => {
        dispatch(returnErrors(err.response.data, err.response.status, true));
      });

    delete window.axios.defaults.headers.common["Authorization"];
};


// Setup config with token - helper function
export const tokenConfig = getState => {
    const token = getState().auth.token;

    if(token) {
        window.axios.defaults.headers.common['Authorization'] = `Token ${token}`;
    }else {
        delete window.axios.defaults.headers.common["Authorization"];
    }

};