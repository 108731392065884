// AUTH Login Types
export const AUTH_USER_LOADING = "AUTH_USER_LOADING";
export const AUTH_USER_LOADED = "AUTH_USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";

// Validation Errors and Messages Types
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

// Tractor Types
export const GET_TRACTORS = "GET_TRACTORS";
export const DELETE_TRACTOR = "DELETE_TRACTOR";
export const ADD_TRACTOR = "ADD_TRACTOR";
export const EDIT_TRACTOR = "EDIT_TRACTOR";
export const UPDATE_TRACTOR = "UPDATE_TRACTOR";

// Area Types
export const GET_AREAS = "GET_AREAS";
export const GET_AREA_FILES = "GET_AREA_FILES";
export const GET_MOBILE_UPLOADS = "GET_MOBILE_UPLOADS";
export const DELETE_FILE = "DELETE_FILE";
export const GET_AREA_TYPES = "GET_AREA_TYPES";
export const GET_AREA_SOIL_TYPES = "GET_AREA_SOIL_TYPES";
export const GET_HEATMAPS = "GET_HEATMAPS";
export const ADD_AREA = "ADD_AREA";
export const DELETE_AREA = "DELETE_AREA";
export const UPDATE_AREA = "UPDATE_AREA";
export const SET_SELECTED_FIELD = "SET_SELECTED_FIELD";
export const GET_LIVE_METEO_FORECAST = "GET_LIVE_METEO_FORECAST";
export const GET_CUSTOM_DATE_METEO = "GET_CUSTOM_DATE_METEO";
export const GET_AREA_HEATMAP = "GET_AREA_HEATMAP";
export const GENERATE_POINTS = "GENERATE_POINTS";
export const GET_SAVED_POINTS = "GET_SAVED_POINTS";
export const GET_SCHEDULER_EVENTS = "GET_SCHEDULER_EVENTS";
export const EDIT_SCHEDULER_EVENT = "EDIT_SCHEDULER_EVENT";
export const CLEAR_REDUX_EVENTS = "CLEAR_REDUX_EVENTS";
export const ADD_SCHEDULER_EVENT = "ADD_SCHEDULER_EVENT";
export const DELETE_SCHEDULER_EVENT = "DELETE_SCHEDULER_EVENT";
export const SAVE_TRANSLATED_COORDINATES = "SAVE_TRANSLATED_COORDINATES";
export const CLEAR_TRANSLATED_COORDINATES = "CLEAR_TRANSLATED_COORDINATES";

// Crop Types
export const GET_CROPS = "GET_CROPS";
export const ADD_CROP = "ADD_CROP";
export const EDIT_CROP = "EDIT_CROP";
export const UPDATE_CROP = "UPDATE_CROP";
export const DELETE_CROP = "DELETE_CROP";

//Cultivation Types
export const GET_CULTIVATIONS = "GET_CULTIVATIONS";
export const ADD_CULTIVATION = "ADD_CULTIVATION";
export const EDIT_CULTIVATION = "EDIT_CULTIVATION";
export const UPDATE_CULTIVATION = "UPDATE_CULTIVATION";
export const DELETE_CULTIVATION = "DELETE_CULTIVATION";

// Team types
export const GET_TEAMS = "GET_TEAMS";
export const ADD_TEAM = "ADD_TEAM";
export const UPDATE_TEAM = "UPDATED_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";

// Member types
export const GET_MEMBERS_BY_TEAM = "GET_MEMBERS_BY_TEAM";
export const GET_ALL_MEMBERS = "GET_ALL_MEMBERS"
export const DELETE_MEMBER = "DELETE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";

// Invitation types
export const INVITE_MEMBER = "INVITE_MEMBER";
export const GET_INVITATIONS = "GET_INVITATIONS";
export const DELETE_INVITATION = "DELETE_INVITATION";
export const ACCEPT_INVITATION = "ACCEPT_INVITATION";

//Weather Types
export const GET_PRECIPITATION = "GET_PRECIPITATION";
export const GET_CLOUD_AND_SOLAR_RADIATION = "GET_CLOUD_AND_SOLAR_RADIATION";
export const GET_HEAT_HUMIDITY_PRESSURE_WIND =
  "GET_HEAT_HUMIDITY_PRESSURE_WIND";

export const SAVE_MARKERS = "SAVE_MARKERS";
