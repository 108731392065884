import {
  GET_AREAS,
  ADD_AREA,
  DELETE_AREA,
  DELETE_FILE,
  UPDATE_AREA,
  GET_AREA_FILES,
  GET_MOBILE_UPLOADS,
  GET_AREA_TYPES,
  GET_AREA_SOIL_TYPES,
  SET_SELECTED_FIELD,
  GET_LIVE_METEO_FORECAST,
  GET_CUSTOM_DATE_METEO,
  GET_PRECIPITATION,
  GET_CLOUD_AND_SOLAR_RADIATION,
  GET_HEAT_HUMIDITY_PRESSURE_WIND,
  SAVE_MARKERS,
  GET_AREA_HEATMAP,
  GENERATE_POINTS,
  GET_SAVED_POINTS,
  GET_SCHEDULER_EVENTS,
  DELETE_SCHEDULER_EVENT,
  ADD_SCHEDULER_EVENT,
  SAVE_TRANSLATED_COORDINATES,
  CLEAR_TRANSLATED_COORDINATES,
  EDIT_SCHEDULER_EVENT,
  CLEAR_REDUX_EVENTS
} from "../actions/types";

import { now } from "moment";
import {convertWKTtoPolygon} from "../components/helpers/geo"

const initialState = {
  fieldsFullData: {},
  markers: {},
  area_types: [],
  soil_types: [],
  selectedField: {
    files: [],
    events: [],
    camera: [],
    field: [],
    indices: [],
    wtk: [],
    polygon: [],
    live_meteo_forecast: []
  },
  fieldByCoords: null
};

export const TypesToColors = {
  sowing: "red",
  fertilizing: "green",
  harvesting: "orange",
  spraying: "Yellow",
  mowing: "Cyan",
  irrigating: "GreenYellow",
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_AREAS: {
      let fieldsFullData = {};
      action.payload.features.forEach(field => {
        fieldsFullData[field.id] = field;
        if (field.OpenWeather != undefined) return;
        fieldsFullData[field.id]["OpenWeather"] = {};
        fieldsFullData[field.id]["OpenWeather"]["forecast"] = {};
        fieldsFullData[field.id]["DarkSky"] = {};
        fieldsFullData[field.id]["Weatherbit"] = {};
      });

      return {
        ...state,
        fieldsFullData: fieldsFullData
      };
    }
    case SET_SELECTED_FIELD:
      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {
          field: action.payload
        })
      };
    case GET_AREA_FILES:
      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {
          files: action.payload
        })
      };
    case CLEAR_REDUX_EVENTS:{
      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {events: []})
      }
    }
    case ADD_SCHEDULER_EVENT:{
      let new_event = action.payload.added_event;
      new_event["id"] = action.payload.id
      new_event["action"] = action.payload.type
      new_event["backgroundColor"] = TypesToColors[action.payload.type]

      let latest_events = [];
      state.selectedField.events.forEach(current_events => {
        latest_events.push(current_events);
      });
      latest_events.push(new_event);

      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {
          events: latest_events})
        }
      }
    case EDIT_SCHEDULER_EVENT:{
      
      let type = action.payload.type;
      let events = [];
      let event_changes = action.payload.event_changes;
      let chosen_event_id = Object.keys(event_changes)[0];
      let updated_event = {};

      state.selectedField.events.forEach(event => {
        if (event.id != chosen_event_id){
          events.push(event);
        }
        else{
          Object.keys(event_changes[chosen_event_id]).forEach(change => {
            updated_event = event; //duplicate the event that is being edited
            updated_event[change] = event_changes[chosen_event_id][change]; //ovewrite the incoming changes 
            updated_event["backgroundColor"] = TypesToColors[type];
          });
          events.push(updated_event); //add the edited event to the EventsArray
        }
      });
      return{
        ...state,
        selectedField: Object.assign({}, state.selectedField, {events: events})
      }
    }
    case DELETE_SCHEDULER_EVENT:{
      let event_to_delete = action.payload;
      let events = state.selectedField.events.filter(event => event.id != event_to_delete);

      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {events: events})
      }
    }
    case GET_SCHEDULER_EVENTS:{
      let dictionary = {
        allDay:"is_all_day",
        startDate:"start_date",
        endDate:"stop_date",
        action:"type",
        customField:"custom_note",
        backgroundColor:"background_color",
      }

      let response_events = action.payload;
      let translated_events = [];
      response_events.forEach(response_event => {
        let translated_event={};
        translated_event["allDay"] = response_event[dictionary["allDay"]];
        translated_event["startDate"] = response_event[dictionary["startDate"]];
        translated_event["endDate"]  = response_event[dictionary["endDate"]];
        translated_event["action"] = response_event[dictionary["action"]]
        translated_event["backgroundColor"] = TypesToColors[response_event[dictionary["action"]]];
        translated_event["title"] = response_event["title"];
        translated_event["notes"] = response_event["notes"] == null? "" : response_event["notes"];
        translated_event["id"] = response_event["id"];
        translated_events.push(translated_event);
      });
      
      return{
        ...state,
        selectedField: Object.assign({}, state.selectedField, {events: translated_events})
      }
    }
    case GET_MOBILE_UPLOADS:{
      return{
        ...state,
        selectedField: Object.assign({}, state.selectedField,{
          camera: action.payload
        })
      }
    }
    case GET_AREA_HEATMAP:
      let filters = action.payload.filters.split(/[&=]/);
      let data_type_index;
      filters.forEach((filter, index) => {
        if (filter.includes("type")) data_type_index = index + 1; //find what kind of data_type is being parsed
      });
      let data_type = filters[data_type_index]; //get data type
      let indices_ = Object.assign({}, state.selectedField.indices);
      indices_[data_type] = action.payload.indices;
      return{
        ...state,
        selectedField: Object.assign({}, state.selectedField, { 
          indices: indices_
        })
      }
    case GET_LIVE_METEO_FORECAST:
      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {
          live_meteo_forecast: action.payload
        })
      };
    case GET_CUSTOM_DATE_METEO:{
      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, {
          live_meteo_forecast: action.payload
        })
      };
    }
    case GET_PRECIPITATION: {
      let fieldsFullData = state.fieldsFullData;
      let selected_field = state.selectedField.field;
      selected_field["DarkSky"] = action.payload;

      fieldsFullData[selected_field.id] = selected_field;
      return{
        ...state,
        fieldsFullData: Object.assign({},  fieldsFullData)
      }
    }
    case GET_CLOUD_AND_SOLAR_RADIATION:{
      let fieldsFullData = state.fieldsFullData;
      let selected_field = state.selectedField.field;
      selected_field["Weatherbit"] = action.payload;

      fieldsFullData[selected_field.id] = selected_field;
      return{
        ...state,
        fieldsFullData: Object.assign({},  fieldsFullData)
      }
    }
    case GET_HEAT_HUMIDITY_PRESSURE_WIND:{ 
      let fieldsFullData = state.fieldsFullData;
      let selected_field = state.selectedField.field;
      selected_field["OpenWeather"]["forecast"] = action.payload;

      fieldsFullData[selected_field.id] = selected_field;
      return{
        ...state,
        fieldsFullData: Object.assign({},  fieldsFullData)
      }
    }
    case GET_AREA_TYPES:
      return {
        ...state,
        area_types: action.payload
      };
    case GET_AREA_SOIL_TYPES:
      return {
        ...state,
        soil_types: action.payload
      };
    case ADD_AREA:{
      let added_field = Object.assign({}, action.payload.field);
      let fieldsFullData_ = Object.assign({}, state.fieldsFullData);
      
      added_field.properties.crop = action.payload.info.crop;
      added_field.properties.color = action.payload.info.color
      added_field["OpenWeather"] = {};
      added_field["OpenWeather"]["forecast"] = {};
      added_field["DarkSky"] = {};
      added_field["Weatherbit"] = {};
      
      fieldsFullData_[added_field.id] = Object.assign({}, added_field);
      return {
        ...state,
        fieldsFullData: Object.assign({}, fieldsFullData_),
        fieldByCoords: null
      };
    }
    case DELETE_AREA: {
      let fieldsFullData = Object.assign({}, state.fieldsFullData);
      delete fieldsFullData[action.payload.id];

      return {
        ...state,
        fieldsFullData: fieldsFullData
      };
    }
    case DELETE_FILE: {
      let selectedField = Object.assign({}, state.selectedField);
      selectedField.camera = selectedField.camera.filter(image => image.id != action.payload);
      return {
        ...state,
        selectedField
      };
    }
    case UPDATE_AREA:{
      let updated_info = Object.assign({}, action.payload.info, {area:action.payload.response_area});
      let selected_field = Object.assign({}, action.payload.field);

      selected_field.properties.name = updated_info.name;
      selected_field.properties.area_type = updated_info.area_type;
      selected_field.properties.soil_type = updated_info.soil_type;
      selected_field.properties.description = updated_info.description;
      selected_field.properties.crop = updated_info.crop
      selected_field.properties.color = updated_info.color
      selected_field.properties.owner = updated_info.owner
      selected_field.properties.is_orchard = updated_info.is_orchard
      selected_field.properties.num_trees = updated_info.num_trees
      selected_field.properties.layout_trees = updated_info.layout_trees
      selected_field.properties.years_input = updated_info.years_input
      selected_field.geometry = convertWKTtoPolygon(updated_info.polygon);
      selected_field.properties.area = updated_info.area;

      let fieldsFullData = Object.assign({}, state.fieldsFullData);
      fieldsFullData[action.payload.field.id] = selected_field;

      return {
        ...state,
        selectedField: Object.assign({}, state.selectedField, [state.selectedField["field"]= selected_field]),
        fieldsFullData: fieldsFullData
      };
    }
    case SAVE_MARKERS:{
      let markers = action.payload;
      return{
        ...state,
        markers: markers
      }
    }
    case GENERATE_POINTS:{
      let points = action.payload.sampling;
      let Field = Object.assign({}, state.selectedField.field, {sampling: points})
      
      let selectedField = Object.assign({}, state.selectedField, {field: Field})
      
      return{
        ...state, selectedField
      }
    }
    case GET_SAVED_POINTS:{
      let points = action.payload.features[0];
      let Field = Object.assign({}, state.selectedField.field, {sampling: points})
      let selectedField = Object.assign({}, state.selectedField, {field: Field})
      return{
        ...state,
        selectedField
      }
    }
    case SAVE_TRANSLATED_COORDINATES:{
     let coords_  = action.payload.coordinates;
     let wkt_     = action.payload.wkt;
     let polygon_ = action.payload.polygon;
      return{
        ...state, 
        fieldByCoords: Object.assign({}, {coords: coords_, wkt: wkt_, polygon: polygon_ })
      }
    }
    case CLEAR_TRANSLATED_COORDINATES:{
       return{
         ...state, 
         fieldByCoords: null
       }
     }
    default:
      return state;
  }
}
