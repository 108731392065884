import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardHeader, CardFooter, 
         Button, Form, FormGroup,FormFeedback, Input, CustomInput} from 'reactstrap';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { addTractor, updateTractor } from '../../../actions/tractors';
import { clearErrors } from '../../../actions/messages';
import { withRouter } from 'react-router-dom';

class TractorForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id ? this.props.match.params.id : '',
            name: this.props.tractor ? this.props.tractor.name : '',
            brand: this.props.tractor ? this.props.tractor.brand : '',
            model: this.props.tractor ? this.props.tractor.model : '',
            wheels: this.props.tractor ? this.props.tractor.wheels : '2wd',
            purchase_value: this.props.tractor ? this.props.tractor.purchase_value : '',
            engine_power: this.props.tractor ? this.props.tractor.engine_power : '',
            max_power: this.props.tractor ? this.props.tractor.max_power : '',
            purchase_year: this.props.tractor ? this.props.tractor.purchase_year : '',
            description: this.props.tractor ? this.props.tractor.description : '',
            extra_hours_of_usage: this.props.tractor ? this.props.tractor.extra_hours_of_usage : '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onCancel = this.onCancel.bind(this);
    }

    static propTypes = {
        addTractor: PropTypes.func.isRequired,
        updateTractor: PropTypes.func.isRequired
    }

    onChange = e => {
        this.setState({[e.target.name] : e.target.value})
    }

    onSubmit = e => {
        e.preventDefault();
        const {id,name,brand,model,wheels,purchase_value,engine_power,max_power, purchase_year, extra_hours_of_usage, description} = this.state;
        

        if(this.props.tractor !== null) {
            const tractor = Object.assign({}, {
                id,name,brand,model,wheels,purchase_value,engine_power,max_power,purchase_year,extra_hours_of_usage,description,owner:4
            });
            this.props.updateTractor(tractor);
            if(!this.props.errors.hasErrors) { 
                this.props.history.push('/machinery'); 
            }
        }else {
            const tractor = Object.assign({}, {
                name,brand,model,wheels,purchase_value,engine_power,max_power,purchase_year,extra_hours_of_usage,description,owner:4
            });
            this.props.addTractor(tractor);
        }
    }

    onCancel = e => {
        this.setState({
            name: '',
            wheels: '2wd',
            brand: '',
            model: '',
            purchase_value: '',
            engine_power: '',
            max_power: '',
            purchase_year: '',
            description: '',
            extra_hours_of_usage: '',
            tractor: '',
        });
        this.props.clearErrors();
        this.props.history.goBack();
    }

    render() { 

        const {name,brand,model,purchase_value,engine_power,max_power, purchase_year, extra_hours_of_usage, description} = this.state;
        const errors = this.props.errors;

        return ( 
            <Row>
                <Col sm="12" md="9" lg="6">
                    <Card>
                        <Form id="tractorForm" onSubmit={this.onSubmit}>
                            <CardHeader>{ this.props.tractor ? 'Edit Tractor' : 'Add Tractor'}</CardHeader>
                            <CardBody>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input 
                                            type="text" 
                                            name="name" 
                                            id="name" 
                                            placeholder="Name" 
                                            value={name} 
                                            invalid={errors.hasErrors && errors.msg.name}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.name}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Input 
                                            type="text" 
                                            name="brand" 
                                            id="brand" 
                                            placeholder="Manufacturer" 
                                            value={brand} 
                                            invalid={errors.hasErrors && errors.msg.brand}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.brand}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={12}>
                                    <FormGroup>
                                        <div>
                                            <CustomInput
                                                type="radio" 
                                                id="wheels1" 
                                                name="wheels" 
                                                label="2 Wheels" 
                                                value="2wd"
                                                // eslint-disable-next-line
                                                defaultChecked={this.state.wheels == '2wd' ? true : false}
                                                inline 
                                                invalid={errors.hasErrors && errors.msg.wheels}
                                                onChange={this.onChange} />
                                            <FormFeedback 
                                                invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.wheel}
                                            </FormFeedback>
                                          <CustomInput
                                                type="radio" 
                                                id="wheels2" 
                                                name="wheels" 
                                                label="4 Wheels" 
                                                value="4wd"
                                                // eslint-disable-next-line
                                                defaultChecked={this.state.wheels == '4wd' ? true : false}
                                                inline 
                                                invalid={errors.hasErrors && errors.msg.wheels}
                                                onChange={this.onChange} />
                                            <FormFeedback 
                                                invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.wheels}
                                            </FormFeedback>
                                        </div>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={4}>
                                    <FormGroup>
                                        <Input 
                                            type="text" 
                                            name="model" 
                                            id="model" 
                                            placeholder="Model" 
                                            value={model} 
                                            invalid={errors.hasErrors && errors.msg.model}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.model}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Input 
                                            placeholder="Purchase Price (&euro;)" 
                                            type="number" 
                                            name="purchase_value" 
                                            id="purchase_value" 
                                            step="1" 
                                            value={purchase_value} 
                                            invalid={errors.hasErrors && errors.msg.purchase_value}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.purchase_value}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup>
                                        <Input 
                                            type="number" 
                                            name="purchase_year" 
                                            id="purchase_year" 
                                            placeholder="Purchase Year" 
                                            value={purchase_year} 
                                            invalid={errors.hasErrors && errors.msg.purchase_year}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.purchase_year}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                                    <FormGroup>
                                        <Input 
                                            type="number" 
                                            name="engine_power" 
                                            id="engine_power"
                                            placeholder="Rated Engine Power (hp)" 
                                            value={engine_power} 
                                            invalid={errors.hasErrors && errors.msg.engine_power}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.engine_power}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input 
                                            type="number" 
                                            name="max_power" 
                                            id="max_power" 
                                            placeholder="Max Engine Power (hp)" 
                                            value={max_power} 
                                            invalid={errors.hasErrors && errors.msg.max_power}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.max_power}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input 
                                            type="number" 
                                            name="extra_hours_of_usage" 
                                            id="extra_hours_of_usage" 
                                            placeholder="Extra hours of usage" 
                                            value={extra_hours_of_usage} 
                                            invalid={errors.hasErrors && errors.msg.extra_hours_of_usage}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.extra_hours_of_usage}
                                        </FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input 
                                            type="textarea" 
                                            name="description" 
                                            placeholder="Description" 
                                            id="description" 
                                            value={description}
                                            invalid={errors.hasErrors && errors.msg.description}
                                            onChange={this.onChange} />
                                        <FormFeedback 
                                            invalid={errors.hasErrors ? "true" : "false"}>{errors.msg.description}
                                        </FormFeedback>
                                    </FormGroup>
                            </CardBody>
                            <CardFooter className="text-right">
                                <Button color="primary" className="mr-2">Save</Button>
                                <Button color="danger" onClick={this.onCancel}>Cancel</Button>
                            </CardFooter>
                        </Form>
                    </Card>
                </Col>
          </Row>
        );
    }
}

const mapStateToProps = (state,ownProps) => {
    const id = ownProps.match.params.id

    return {
        errors: state.errors,
        messages: state.messages,
        // eslint-disable-next-line
        tractor: id ? state.tractors.tractors.find(tractor => tractor.id == id) : null
    }
};

 
export default connect(mapStateToProps, { addTractor, updateTractor,clearErrors })(withRouter(TractorForm));