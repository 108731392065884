import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/images/biocircular-icon-white.png";

const Sidebar = props => {
  if (props.user == null) return <div></div>;

  return (
    <div className="docked-sidebar">
      <Nav vertical className="sidebar-nav">
        <NavItem className="mb-3">
          <NavLink
            className="d-flex align-items-center flex-column mr-0 my-2"
            strict
            exact={true}
            to="/"
          >
            <img className="img-fluid logo d-block" src={Logo} alt="logo" />
          </NavLink>
        </NavItem>
        {props.links
          .filter(link =>
            props.user.groups.some(role => link.roles.includes(role))
          )
          .map((link, key) => {
            return (
              <NavItem className="mb-2" key={key}>
                <NavLink
                  className="nav-link d-flex align-items-center flex-column"
                  activeClassName="is-active"
                  strict
                  exact={link.exact}
                  to={link.slug}
                >
                  <FontAwesomeIcon className="size-32" icon={link.icon} />
                  {link.name}
                </NavLink>
              </NavItem>
            );
          })}
      </Nav>
    </div>
  );
};

export default Sidebar;
