import React, { Component } from "react";
import {
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader
} from "reactstrap";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { addTeam, updateTeam } from "../../../actions/teams";
import { clearErrors } from "../../../actions/messages";
import { withRouter } from "react-router-dom";

class TeamForm extends Component {
  state = {
    id: this.props.team ? this.props.team.id : "",
    name: this.props.team ? this.props.team.name : "",
    description: this.props.team ? this.props.team.description : ""
  };

  restoreInfo() {
    const { name, description } = this.props.team;
    this.setState({
      name: name,
      description: description == "" ? "-" : description
    });
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCancel = e => {
    if (this.state.editing) {
      this.restoreInfo();
    } else {
      this.setState({
        name: "",
        description: ""
      });
    }

    this.props.clearErrors();
    this.props.history.goBack();
  };

  onSubmit = e => {
    e.preventDefault();

    this.setState({
      submitted: true
    });

    const { name, description } = this.state;
    const team = Object.assign(
      {},
      {
        name,
        description
      }
    );

    this.props.clearErrors();

    if (this.props.team !== null) {
      const newTeam = Object.assign(team, {
        id: this.props.team.id
      });
      this.props.updateTeam(team);
    } else {
      const newTeam = Object.assign(team, {
        created_by: this.props.user.username
      });
      this.props.addTeam(newTeam);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitted && !nextProps.errors.hasErrors) {
      this.props.history.push("/teams");
    }
  }

  render() {
    const { team, errors } = this.props;
    const { name, description } = this.state;

    return (
      <Row>
        <Col sm="12" md="9" lg="6">
          <Card>
            <Form id="teamForm" onSubmit={this.onSubmit}>
              <CardHeader>{team ? "Edit Team" : "Create Team"}</CardHeader>
              <CardBody>
                <Row form>
                  <Col md={12}>
                    <Label for="name">Name:</Label>
                    <FormGroup>
                      <Input
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        invalid={errors.hasErrors && errors.msg.name}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.name}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="description">Description:</Label>
                    <FormGroup>
                      <Input
                        type="textarea"
                        name="description"
                        id="description"
                        value={description}
                        invalid={errors.hasErrors && errors.msg.description}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.description}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary" className="mr-2">
                  Save
                </Button>
                <Button color="danger" onClick={this.onCancel}>
                  Cancel
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;

  return {
    errors: state.errors,
    messages: state.messages,
    // eslint-disable-next-line
    team: id ? state.teams.teams.find(team => team.id == id) : null,
    user: state.auth.user
  };
};

export default connect(mapStateToProps, {
  addTeam,
  updateTeam,
  clearErrors
})(withRouter(TeamForm));

TeamForm.propTypes = {
  addTeam: PropTypes.func.isRequired,
  updateTeam: PropTypes.func.isRequired
};
