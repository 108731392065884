import React, {Component } from "react";
import classnames from 'classnames';
import { Row, Col, Card,CardImg, CardBody, CardTitle, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Profile from "../views/Settings/Profile";
import moment from "moment";

class Settings extends Component {
    constructor(props) {
        super(props);
    
        this.toggle = this.toggle.bind(this);
        this.state = {
          activeTab: '1'
        };
      }
    
      toggle(tab) {
        if (this.state.activeTab !== tab) {
          this.setState({
            activeTab: tab
          });
        }
      }


    render() {
    const {
      first_name,
      last_name,
      date_joined,
      groups: role,
    } = this.props.user;
    return (
      <Row>
        <Col md="12">
          <h2 className="mb-3">Settings</h2>
        </Col>

        <Col md="2">
          <Card>
            <CardBody>
              <div className="mb-2 mx-auto text-center d-block">
                <FontAwesomeIcon
                  icon={faUserCircle}
                  className="bg-dark text-white  rounded-circle"
                  size="5x"
                />
              </div>
              <CardTitle className="text-center">
                <div className="font-weight-bold">
                  {first_name} {last_name}
                </div>
                <span className="mb-2">{role} </span>
                <p className="font-size-xs">
                  Joined {moment(date_joined).fromNow()}{" "}
                </p>
              </CardTitle>
              <Nav tabs vertical className="vertical-tabs">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTab === "1",
                    })}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    Profile
                  </NavLink>
                </NavItem>
              </Nav>
            </CardBody>
          </Card>
        </Col>

        <Col md="10">
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col sm={12} md={9} lg={6}>
                  <Profile />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, {})(Settings);
