import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  CustomInput,
  Input,
  Label,
} from "reactstrap";
import { PropTypes } from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { clearErrors } from "../../../../../actions/messages";
import { updateArea } from "../../../../../actions/areas";
import {
  updateCultivation,
  addCultivation,
  deleteCultivation,
} from "../../../../../actions/cultivations";
import { formatName } from "../../../../helpers/formaters";
import EditMap from "../../../../partials/EditMap";
import { default as Switcher } from "bootstrap-switch-button-react";
import { Role } from "../../../../auth/common/Role";
import Authorize from "../../../../auth/common/Authorize"

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.field.properties.name
        ? this.props.field.properties.name
        : "",
      area_type: this.props.field.properties.area_type
        ? this.props.field.properties.area_type
        : "",
      soil_type: this.props.field.properties.soil_type
        ? this.props.field.properties.soil_type
        : "",
      crop: this.props.field.properties.crop,
      description: this.props.field.properties.description
        ? this.props.field.properties.description
        : "",
      owner: "",
      id: this.props.field.id ? this.props.field.id : "",
      polygon: "",
      is_orchard: this.props.field.properties.is_orchard,
      num_trees: this.props.field.properties.num_trees,
      layout_trees: this.props.field.properties.layout_trees,
      years_input: this.props.field.properties.years_input,

      submitted: this.props.submitted,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.applyPolygonChanges = this.applyPolygonChanges.bind(this);
  }

  static propTypes = {
    updateArea: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
  };

  componentDidMount(){
    let current_user_role = this.props.currentUser.groups[0];
    let owner = this.props.currentUser.username;
    if (current_user_role == Role.Agronomist) owner = this.getUserByUsername(this.props.field.properties.owner, this.props.members);

    this.setState({owner: owner})
  }

  getUserByUsername(username_, members_) {
    let currentUser = this.props.currentUser;

    //check to see if Landholder group exists inside current user's groups
    //if Landholder, return empty Owner, since we do not authorize access such users to owner information
    if (
      currentUser.groups.filter((group) => group == Role.Landholder).length > 0
    )
      return "";

    let client = members_.filter((client_) => client_.user == username_)[0];
    return (
      formatName(client.profile.first_name) +
      " " +
      formatName(client.profile.last_name)
    );
  }

  getUserByFullname(fullname_, members_) {
    let first_name = fullname_.split(" ")[0];
    let last_name = fullname_.split(" ")[1];
    let client = members_.filter(
      (client_) =>
        formatName(first_name) + " " + formatName(last_name) ==
        formatName(client_.profile.first_name) +
          " " +
          formatName(client_.profile.last_name)
    )[0];
    return client.user;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitted && !nextProps.errors.hasErrors)
      nextProps.toggleEdit();
  }

  onSubmit = (e) => {
    this.state.submitted = true;
    e.preventDefault();

    const {
      name,
      area_type,
      soil_type,
      description,
      crop,
      id,
      is_orchard,
      num_trees,
      layout_trees,
      years_input,
    } = this.state;
    let owner = this.state.owner;
    if (this.props.currentUser.groups[0] == Role.Agronomist) owner = this.getUserByFullname(owner, this.props.members);
    this.props.clearErrors();

    if (this.props.field !== null) {
      let current_crop = this.props.crops.filter(
        (crop_) => crop_.name == crop
      )[0];
      const color = current_crop == undefined ? "#000000" : current_crop.color; //if current_crop == undefined, it means crop is null (Free)

      const updatedField = Object.assign(
        {},
        {
          name,
          area_type,
          soil_type,
          description,
          polygon: this.state.polygon,
          crop,
          color,
          owner,
          is_orchard,
          num_trees: is_orchard == false ? null : num_trees,
          layout_trees: is_orchard == false ? null : layout_trees,
          years_input: is_orchard == false ? null : years_input,
        }
      );
      let cultivation_ = this.props.cultivations.filter(
        (cult) => cult.field == id
      )[0];
      let cultivation_msg = Object.assign(
        {},
        {
          field: id,
          crop: crop,
          active: true,
          owner: owner,
        }
      );
      //Cultivation should only be touched if the selected crop is different than the field's current crop
      if (crop != this.props.field.properties.crop) {
        if (crop == "Free") {
          this.props.deleteCultivation(cultivation_.id);
        } else if (this.props.field.properties.crop == "Free" || this.props.field.properties.crop == null) {
          this.props.addCultivation(cultivation_msg);
        } else {
          this.props.updateCultivation(
            Object.assign({}, cultivation_msg, { id: cultivation_.id })
          );
        }
      }
      this.props.updateArea(this.props.field, updatedField);
    }
  };

  onChange = (e) => {
    let value_ = e.target.value;
    this.setState({ [e.target.name]: value_ });
  };

  handleSwitch = (value) => {
    this.setState({
      is_orchard: value,
    });
  };

  applyPolygonChanges(wkt_polygon) {
    this.state.polygon = wkt_polygon;
  }

  render() {
    const {
      name,
      soil_type,
      crop,
      description,
      owner,
      num_trees,
      layout_trees,
      years_input,
      is_orchard
    } = this.state;

    const { crops, soil_types, members, errors } = this.props;
    const layouts = ["Rectangle", "Triangle", "Diamond"];

    return [
      <Col key="1" sm="12" md="9" lg="6">
        <Card>
          <Form id="fieldForm" onSubmit={this.onSubmit}>
            <CardHeader className="bg-primary text-white">
              Edit Field
            </CardHeader>
            <CardBody>
              <Row form>
                <Col md={6}>
                  <Label for="name">Name:</Label>
                  <FormGroup>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      value={name}
                      invalid={errors.hasErrors && errors.msg.name}
                      onChange={this.onChange}
                    />
                    <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                      {errors.msg.name}
                    </FormFeedback>
                  </FormGroup>
                  <Row>
                    <Col md={4}>
                      <Label for="soil_type">Soil Type:</Label>
                      <FormGroup>
                        <CustomInput
                          type="select"
                          name="soil_type"
                          id="soil_type"
                          value={soil_type}
                          invalid={errors.hasErrors && errors.msg.soil_type}
                          onChange={this.onChange}
                        >
                          {soil_types !== null
                            ? soil_types.map((soil_type) => {
                                return (
                                  <option
                                    key={soil_type.formal_name}
                                    value={soil_type.formal_name}
                                  >
                                    {soil_type.formal_name}
                                  </option>
                                );
                              })
                            : ""}
                        </CustomInput>
                        <FormFeedback
                          invalid={errors.hasErrors ? "true" : "false"}
                        >
                          {errors.msg.soil_type}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <Label for="crop">Crop:</Label>
                      <FormGroup>
                        <CustomInput
                          type="select"
                          name="crop"
                          id="crop"
                          defaultValue={crop == null ? null : crop}
                          invalid={errors.hasErrors && errors.msg.crop}
                          onChange={this.onChange}
                        >
                          {crops !== null
                            ? crops.map((crop_) => {
                                return (
                                  <option key={crop_.id} value={crop_.name}>
                                    {crop_.name}
                                  </option>
                                );
                              })
                            : ""}
                        </CustomInput>
                        <FormFeedback
                          invalid={errors.hasErrors ? "true" : "false"}
                        >
                          {errors.msg.crop}
                        </FormFeedback>
                      </FormGroup>
                    </Col>

                    <Authorize roles={[Role.Agronomist]}>
                      <Col md={4}>
                        <Label for="owner">Owner:</Label>
                        <FormGroup>
                          <CustomInput
                            type="select"
                            name="owner"
                            id="owner"
                            value={owner}
                            invalid={errors.hasErrors && errors.msg.owner}
                            onChange={this.onChange}
                          >
                            {members !== null
                              ? members.map((member) => {
                                  return (
                                    <option key={member.id} value={member.name}>
                                      {formatName(member.profile.first_name) +
                                        " " +
                                        formatName(member.profile.last_name)}
                                    </option>
                                  );
                                })
                              : ""}
                          </CustomInput>
                          <FormFeedback
                            invalid={errors.hasErrors ? "true" : "false"}
                          >
                            {errors.msg.crop}
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    </Authorize>
                  </Row>
                </Col>

                <Col md={6}>
                  <Row>
                    <Col xs="2" sm="2" md="2">
                      <Label for="type">Orchard:</Label>
                    </Col>
                    <Col xs="1" sm="1" md="1">
                      <Switcher
                        checked={is_orchard}
                        onlabel="Yes"
                        offlabel="No"
                        onstyle="primary"
                        offstyle="outline-primary"
                        color="primary"
                        width={70}
                        size="sm"
                        style="ml-3"
                        onChange={this.handleSwitch}
                      />
                    </Col>
                  </Row>
                  {this.state.is_orchard ? (
                    <Row>
                      <Col lg="4" md="4" sm="4" xs="4">
                        <Label for="trees_no">Number of trees:</Label>
                        <Input
                          type="number"
                          required
                          min={1}
                          defaultValue={num_trees == null ? null : num_trees}
                          placeholder={
                            num_trees == null ? "e.g 150" : num_trees
                          }
                          onChange={this.onChange}
                          name="num_trees"
                          id="num_trees"
                        ></Input>
                      </Col>
                      <Col lg="5" md="5" sm="5" xs="4">
                        <Label for="trees_lay">Trees layout:</Label>
                        <Input
                          type="select"
                          required
                          defaultValue={
                            layout_trees == null ? "" : layout_trees
                          }
                          onChange={this.onChange}
                          name="layout_trees"
                          id="layout_trees"
                        >
                          <option value="" disabled>
                            Select layout
                          </option>
                          {layouts.map((layout) => {
                            return (
                              <option
                                value={layout}
                                key={layout}
                                id={layout + "_id"}
                              >
                                {layout}
                              </option>
                            );
                          })}
                        </Input>
                      </Col>
                      <Col lg="3" md="3" sm="3" xs="4">
                        <Label for="trees_year">Active years:</Label>
                        <Input
                          type="number"
                          required
                          min={0}
                          defaultValue={
                            years_input == null ? null : years_input
                          }
                          placeholder={
                            years_input == null ? "e.g 2" : years_input
                          }
                          onChange={this.onChange}
                          name="years_input"
                          id="years_input"
                        ></Input>
                      </Col>
                    </Row>
                  ) : null}
                </Col>

                <Col md={12}>
                  <Label for="description">Description:</Label>
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="description"
                      id="description"
                      value={description == "null" ? "" : description}
                      invalid={errors.hasErrors && errors.msg.description}
                      onChange={this.onChange}
                    />
                    <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                      {errors.msg.description}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="text-right">
              <Button color="primary" className="mr-2">
                <FontAwesomeIcon className="mr-2" icon={faCheck} />
                Update
              </Button>
              <Button
                color="danger"
                onClick={() => {
                  this.props.toggleEdit();
                  this.props.clearErrors();
                }}
              >
                <FontAwesomeIcon className="mr-2" icon={faTimes} />
                Cancel
              </Button>
            </CardFooter>
          </Form>
        </Card>
      </Col>,
      <Col key="2" sm="12" md="9" lg="6">
        <Card>
          <Form id="fieldForm1" onSubmit={this.onSubmit}>
            <CardHeader className="bg-primary text-white">
              Edit Field
            </CardHeader>
            <CardBody>
              <Row form>
                <EditMap
                  submitPolygon={this.applyPolygonChanges}
                  el={"edit-map-" + name}
                  height="100%"
                ></EditMap>
              </Row>
            </CardBody>
          </Form>
        </Card>
      </Col>,
    ];
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    errors: state.errors,
    messages: state.messages,
    field: state.areas.selectedField.field,
    soil_types: state.areas.soil_types,
    crops: state.crops.crops,
    members: state.members.members,
    cultivations: state.cultivations.cultivations,
  };
};

export default connect(mapStateToProps, {
  updateArea,
  clearErrors,
  updateCultivation,
  addCultivation,
  deleteCultivation,
})(EditForm);
