import React, {Fragment, Component} from 'react';
import Tractor from './TractorItem';
import { Button, Row, Col, } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTractors, deleteTractor, editTractor } from '../../../actions/tractors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';

class Tractors extends Component {
    constructor(props) {
        super();
        this.handleAddTractor = this.handleAddTractor.bind(this);
    }
    
    static propTypes = {
        tractors: PropTypes.array.isRequired,
        getTractors: PropTypes.func.isRequired,
        deleteTractor: PropTypes.func.isRequired,
        editTractor: PropTypes.func.isRequired
    }

    componentDidMount(){
        this.props.getTractors();
    }

    handleAddTractor = () => {
        this.props.history.push('/machinery/tractors/add')
    };

    render() {
        return (
            <Fragment>
                    <Row>
                        <Col>
                            <Button color="success" className="my-3" onClick={this.handleAddTractor}>
                            Add Tractor<FontAwesomeIcon className="ml-2" icon={faPlus} />
                            </Button>
                        </Col>
                    </Row>
            <Row>
                {this.props.tractors.map(tractor => {
                    return (
                        <Col className="mb-3" md="4" sm="6" lg="3" key={tractor.id}>
                            <Tractor 
                                tractor={tractor} 
                                key={tractor.id} 
                                editTractor={this.props.editTractor}
                                deleteTractor={this.props.deleteTractor.bind(this,tractor.id)}
                            />
                        </Col>
                        )
                    })
                }
            </Row>
            </Fragment>
            // <Table className="mt-3" striped>
            //     <thead>
            //         <tr className="text-center">
            //             <th>#</th>
            //             <th>Model Name</th>
            //             <th>Manufacturer</th>
            //             <th>Model</th>
            //             <th>Price (€)</th>
            //             <th>Rated Engine Power (hp)</th>
            //             <th>Max Engine Power (hp)</th>
            //             <th>Purchase Year</th>
            //             <th>Operation Width (m)</th>
            //             <th>Drive</th>
            //             <th>Actions</th>
            //         </tr>
            //     </thead>
            //     <tbody>
            //         {this.props.tractors.map(tractor => {
            //             return (
            //                 <tr className="text-center" key={tractor.id}>
            //                     <Tractor 
            //                         tractor={tractor} 
            //                         key={tractor.id} 
            //                         editTractor={this.props.editTractor}
            //                         deleteTractor={this.props.deleteTractor.bind(this,tractor.id)}
            //                     />
            //                 </tr>
            //             )
            //         })}
            //     </tbody>
            // </Table>

        )
    };
}

const mapStateToProps = state => ({
    tractors:state.tractors.tractors
});


export default connect(mapStateToProps, { getTractors, deleteTractor,editTractor })(withRouter(Tractors));