import React, { Component } from "react";
import { Button, Toast, ToastBody, ToastHeader } from "reactstrap";

class SaveDiscardPolygon extends Component {
  state = {
    show: this.props.show ? this.props.show : false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps != prevState) return { show: nextProps.show };
    else return null;
  }

  toggle = () => {
    this.setState({ show: !this.state.show });
  };

  onDiscard = () => {
    this.props.onDiscard();
  };

  onSave = () => {
    this.props.onSave();
  };

  render() {
    const { show } = this.state;

    return (
      <Toast className="map-toast" isOpen={show}>
        <ToastHeader>Do you wish to save the drawn area?</ToastHeader>
        <ToastBody className="d-flex justify-content-center">
          <Button
            color="primary"
            size="sm"
            className="mr-3"
            onClick={this.onSave}
          >
            Save
          </Button>
          <Button color="danger" size="sm" onClick={this.onDiscard}>
            Discard
          </Button>
        </ToastBody>
      </Toast>
    );
  }
}

export default SaveDiscardPolygon;
