/**
 * @param {Array} _teams  Array of team names
 * @param {Array} _members Array of all member names
 * @param {Object} _fields Object of fieldsFullData as they are received from Redux
 * @returns Object
 */
export const sortFieldsByTeamAndClient = (_teams, _members, _fields)  => {
    var sortedFields = {};
    var fields = JSON.parse(JSON.stringify(_fields));

    let members = _members;


    Object.keys(_teams).forEach(team_name => {
      sortedFields[team_name] = {}
      sortedFields[team_name]["All"] = [];
      _teams[team_name].forEach(team_member => {
        let client = members.filter(
          member => team_member == member.profile.last_name + " " + member.profile.first_name
        );

        sortedFields[team_name][team_member] = [];

        for (var field in fields) {
          if (fields[field]["properties"]["owner"].toLowerCase() == client[0].user.toLowerCase()) {
            let field_ = fields[field]
            field_.properties.crop = field_.properties.crop == null ? "Free" : field_.properties.crop
            sortedFields[team_name][team_member].push(fields[field]);
            sortedFields[team_name]["All"].push(fields[field])
          }
        }
      });
    });

    sortedFields["All"]["All"] = {}
    sortedFields["All"]["All"] = Object.keys(fields).map(field => {
      let field_ = fields[field];
      field_.properties.crop = field_.properties.crop == null ? "Free" : field_.properties.crop
      return field_;
    });

    /*
    returned object follows this format:
    {
      "All" : {
        "All" : [feature1, feature2, feature3, feature4, feature5]
        "Client1" : [feature1, feature2]
        "Client2" : [feature3, feature4]
        "Client3" : [feature5]
        "Client4" : []
      }
      "Team_1" : {
        "All" : [feature1, feature2, feature3, feature4]
        "Client1" : [feature1, feature2]
        "Client2" : [feature3, feature4] 
      }
      "Team_2" : {
        "All" : [feature5]
        "Client3" : [feature5]
        "Client4" : []
      }
    }
    *where feature# = field as retrieved from server and as is inside Redux fieldsFullData object
    */
    return sortedFields;
  }

  /**
   * @param {Array} teams_ Array of team objects 
   * @returns Array
   */
  export const getTeamsAsArray = (teams_) => {
    let teams_as_array = teams_.map(team => {return team.name})
    teams_as_array.splice(0, 0, "All");
    return teams_as_array;
  }

  /**
   * @description Returns an object with all members, sorted by team
   * @param {Array} teams_ Array of team names
   * @param {Array} members_ Array of all member names
   * @param {Boolean} usernames_ Set True to return clients by username. False by default if omitted)
   * @returns Object
   */
  export const createTeamsWithMembers = (teams_, members_, usernames_=false) => {
    var teams_with_members = {};
    let clients = members_;
    
    teams_with_members["All"] = [];
    clients.forEach(client => {
      if (!usernames_) teams_with_members["All"].push(client.profile.last_name + " " + client.profile.first_name);
      else teams_with_members["All"].push(client.profile.username)
    });

    teams_.forEach(team => {
      if(team == "All" ) return;
      teams_with_members[team] = []
      clients.forEach(client => {
        if(client.team == team) {
          if (!usernames_) teams_with_members[team].push(client.profile.last_name + " " + client.profile.first_name);
          else teams_with_members[team].push(client.profile.username);
        }
      });
    });
    /*
    returned object follows this format:
    {
      "All" : ["Client1,", "Client2", "Client3"]
      "Team_1" : ["Client1", "Client2"]
      "Team_2" : ["Client3"]
    }
    */

    return teams_with_members;
  }

  /**
   * @param {Object} fields_ Object of fieldsFullData as they are received from Redux
   * @returns Array
   */
  export const getUniqueCropsOfCurrentFields = (fields_) => {
    var crops = [];
    if (Object.keys(fields_).length == 0) return crops;

    var fields = JSON.parse(JSON.stringify(fields_))

    for (var key in fields) {
      if (!crops.includes(fields[key]["properties"]["crop"]) && fields[key]["properties"]["crop"] != null && fields[key]["properties"]["crop"] != "Free")
        crops.push(fields[key]["properties"]["crop"]);
    }
    crops = crops.sort(); //sort the crop list, alphabetically
    crops.splice(crops.indexOf("Free"), 0) //remove the "Free" crop from the random position it has been placed at
    crops.splice(0, 0, "Free"); //set the "Free" crop as the 1st element of the crop list
    return crops;
  }