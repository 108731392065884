import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth, roles, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      if (auth.isLoading) {
        return <h2>Loading</h2>;
      }

      if (!auth.isAuthenticated) {
        return <Redirect to="/login" />;
      }

      if (roles && !auth.user.groups.some(role => roles.includes(role))) {
        return <Redirect to="/" />;
      }

      return <Component {...props} />;
    }}
  />
);

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);
