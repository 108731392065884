import {
  GET_TEAMS,
  ADD_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM
} from "../actions/types";

const initialState = {
  teams: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_TEAMS:
      return {
        ...state,
        teams: action.payload
      };
    case ADD_TEAM:
      return {
        ...state,
        teams: [...state.teams, action.payload]
      };
    case DELETE_TEAM:
      return {
        ...state,
        teams: state.teams.filter(team => team.id !== action.payload)
      };
    case UPDATE_TEAM:
      return {
        ...state,
        teams: state.teams.map(team => {
          if (team.id === action.payload.id) {
            return {
              ...team,
              name: action.payload.name,
              description: action.payload.description
            };
          } else {
            return team;
          }
        })
      };
    default:
      return state;
  }
}
