import React, { Component } from "react";
import Chart from "chart.js"
import { connect } from 'react-redux';
import DataProviderCredits from "./WeatherDataProviders"

class PrecipitationProbabilityChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: [],
        };

        this.parsePrecipitationProbability = this.parsePrecipitationProbability.bind(this);
        this.convertTimestamp = this.convertTimestamp.bind(this);

        this.renderPrecipitationProbabilityCharts = this.renderPrecipitationProbabilityCharts.bind(this);
    }

    componentDidMount() {
        this.setState({ field: this.props.field });
    }

    convertTimestamp(timestamp) {
        var d = new Date(timestamp * 1000),	// Convert the passed timestamp to milliseconds
            yyyy = d.getFullYear(),
            mm = ('0' + (d.getMonth() + 1)).slice(-2),	// Months are zero based. Add leading 0.
            dd = ('0' + d.getDate()).slice(-2),			// Add leading 0.
            hh = d.getHours() >= 10 ? d.getHours() : "0" + d.getHours(),
            h = hh,
            min = ('0' + d.getMinutes()).slice(-2),		// Add leading 0.
            time;

        if (hh == 0)
            h = "00";

        // ie: "2013-02-18 20:35"
        time = yyyy + '-' + mm + '-' + dd + ' ' + h + ':' + min;

        return time;
    }

    parsePrecipitationProbability() {
        var forecast = this.props.field.DarkSky.hourly.data;
        var precipitation = {};
        precipitation.probability = [];
        precipitation.time = [];
        precipitation.day_change = [];

        var i = 0;
        for (var key in forecast) {

            precipitation["probability"].push(forecast[key].precipProbability * 100);

            var YYYYMMDD = this.convertTimestamp(forecast[key].time);
            var date = YYYYMMDD.toString().split(' ')[0].slice(5, 10).split('-');
            var time = YYYYMMDD.toString().split(' ')[1].slice(0, 5);

            if (time == "00:00") {
                precipitation["time"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
                precipitation["day_change"].push("(" + date[1] + "-" + date[0] + ")" + " " + time)
            }
            else
                precipitation["time"].push(time);

            /*if( i % 3 != 0){
              precipitation["probability"].pop();
              precipitation["intensity"]  .pop();
              if(time!="00:00")
                precipitation["time"].pop();
            }
            i++;*/
        }

        precipitation.probability_color = 'rgba(79, 40, 0, ';
        return precipitation;
    }

    renderPrecipitationProbabilityCharts() {
        var myCanvas = document.getElementById('PrecipitationProbabilityChart').getContext('2d');
        var Precipitation = this.parsePrecipitationProbability();
        var annotations = Precipitation.day_change.map(function (date, index) {
            return {
                type: 'line',
                id: 'vline' + index,
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: date,
                borderColor: 'black',
                borderWidth: 0.5,
                borderDash: [5, 5]
            }
        });

        var mytooltip = {
            callbacks: {
                title: function () {
                    return '';
                }
            }
        }

        new Chart(myCanvas, {
            type: 'bar',
            data: {
                labels: Precipitation["time"],
                datasets: [
                    {
                        label: 'Probability (%)',
                        data: Precipitation["probability"],
                        borderWidth: 1,
                        fill: false,
                        backgroundColor: Precipitation.probability_color + " 0.2)",
                        borderColor: Precipitation.probability_color + " 1)"
                    }
                ]
            },
            options: {
                // responsive: false,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: "Precipitation Probability Forecast"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 100
                        }
                    }]
                },
                annotation: {
                    annotations: annotations
                },
                tooltips: mytooltip
            }
        });

        var maxIntensity = Math.max.apply(null, Precipitation["intensity"]);
        var ChartMax;
        var i = 0;
        do {
            i += 10;
            if (i - maxIntensity > 0) {
                ChartMax = i;
            }
        } while (ChartMax == undefined);
    }

    render() {
        if (this.props.fetched && document.getElementById('PrecipitationProbabilityChart') != null) this.renderPrecipitationProbabilityCharts();

        return (
            <div>
                <canvas className="weather-chart" id={this.props.title.replace(' ', '') + "Chart"}></canvas>
                <DataProviderCredits DataType="Weather" DataSource={this.props.DataSource} hidden={!this.props.fetched}></DataProviderCredits>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let fetched = false;

    if (Object.keys(state.areas.selectedField.field.DarkSky).length != 0)
        fetched = true;
    return { field: state.areas.selectedField.field, fetched: fetched }
}

export default connect(mapStateToProps)(PrecipitationProbabilityChart)