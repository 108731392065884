import {
  GET_MEMBERS_BY_TEAM,
  GET_ALL_MEMBERS,
  DELETE_MEMBER,
  GET_INVITATIONS,
  INVITE_MEMBER,
  DELETE_INVITATION,
  ACCEPT_INVITATION,
  UPDATE_MEMBER
} from "../actions/types";

const initialState = {
  members: [],
  team_members: [],
  invitatons: [],
  editing: false,
  accepted_invitation: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MEMBERS_BY_TEAM:
      return {
        ...state,
        team_members: action.payload
      };
    case GET_ALL_MEMBERS:
      return{
        ...state,
        members:action.payload
      }
    case DELETE_MEMBER:
      return {
        ...state,
        members: state.members.filter(member => member.id !== action.payload)
      };
    case GET_INVITATIONS:
      return {
        ...state,
        invitations: action.payload
      };
    case INVITE_MEMBER:
      return {
        ...state,
        invitations: [...state.invitations, action.payload]
      };
    case UPDATE_MEMBER:{
      let memberID = action.payload.memberID;
      let updatedInfo = action.payload.updatedInfo;
      let selectedUser = state.team_members.filter(member => member.profile.id == memberID)[0];

      selectedUser["profile"]["afm"] = updatedInfo["afm"];
      selectedUser["profile"]["amka"] = updatedInfo["amka"];
      selectedUser["profile"]["email"] = updatedInfo["email"];
      selectedUser["profile"]["father_name"] = updatedInfo["father_name"];
      selectedUser["profile"]["first_name"] = updatedInfo["first_name"];
      selectedUser["profile"]["iban"] = updatedInfo["iban"];
      selectedUser["profile"]["last_name"] = updatedInfo["last_name"];
      selectedUser["profile"]["mobile_phone"] = updatedInfo["mobile_phone"];
      selectedUser["profile"]["postal_code"] = updatedInfo["postal_code"];

      let team_members = state.team_members;
      team_members.splice(state.team_members.indexOf(state.team_members.find(member => member.profile.id == memberID)), 1, selectedUser );

      return {
        ...state,
        team_members: team_members
      }
    }
    case DELETE_INVITATION:
      return {
        ...state,
        invitations: state.invitations.filter(
          invitation => invitation.id !== action.payload
        )
      };
    case ACCEPT_INVITATION:
      return {
        ...state,
        accepted_invitation: action.payload
      }
    default:
      return state;
  }
}
