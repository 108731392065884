import { combineReducers } from "redux";
import { reducer as sweetalert } from "react-redux-sweetalert";

import errors from "./errors";
import messages from "./messages";
import auth from "./auth";
import tractors from "./tractors";
import areas from "./areas";
import crops from "./crops";
import cultivations from "./cultivations";
import teams from "./teams";
import members from "./members";

export default combineReducers({
  sweetalert,
  errors,
  messages,
  auth,
  tractors,
  areas,
  crops,
  cultivations,
  teams,
  members
});
