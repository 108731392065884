import React from "react";
import { connect } from "react-redux";

const CropsLegend = props => {
  const { crops } = props;

  return (
    <div className="crops-legend">
      <p className="mb-1 font-weight-bold">Crop Legend</p>
      {crops.map(crop => {
        return (
          <div key={crop.id} className="d-flex align-items-center">
            <div
              className="size-15 mr-2 border border-secondary text-dark font-weight-bold"
              style={{ backgroundColor: crop.color }}
            ></div>
            <div> {crop.name}</div>
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    crops: state.crops.crops
  };
};

export default connect(mapStateToProps)(CropsLegend);
