import React, { Component } from "react";
import PropTypes from "prop-types";
import EmptyDataset from "../../../../layout/EmptyDataset";
import { connect } from "react-redux";
import { getLiveMeteoForecast, getCustomDateMeteo } from "../../../../../actions/areas";
import { faCloud, faDownload, faCalendar } from "@fortawesome/free-solid-svg-icons";

import classnames from 'classnames';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more.src';
import { Row, Col, Button } from "reactstrap";
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingSpinerComponent } from "../../../../partials/LoadingSpiner"
HighchartsMore(Highcharts);

class MeteoForecast extends Component {
  constructor(props){
    super(props);
    this.state = {
      ranges:[
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection'
        }
      ],
      datePickerStatus:"hidden",
      datePickerColor: "primary",
      open: false
    };
    this.hangleChange = this.hangleChange.bind(this);
    this.toggleDatePicker = this.toggleDatePicker.bind(this);
  }
  static propTypes = {
    getLiveMeteoForecast: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getLiveMeteoForecast(
      this.props.selectedField.field.properties.meteo_station
    );
  }
  hangleChange(event){
    let TimeZoneOffsetInSeconds = new Date().getTimezoneOffset()  * 60;
    let start = (new Date(event.selection.startDate).getTime() / 1000) - TimeZoneOffsetInSeconds;
    //endDate differs when it is picked by click from when it's automatically set by a range preset (Last Week etc.)
    //When manually picked, the end is set to 00:00:00 of the selected day.
    //When automatically set, the end is set to 23:59:59 instead. So, we forcely set it to 00:00:00 in order to have a common point of reference
    let end = (new Date(event.selection.endDate.toString().replace("23:59:59","00:00:00")).getTime()/1000) - TimeZoneOffsetInSeconds;
    end += 86400; //adding the seconds of a whole day to properly show the endDate 
    let {meteo_station} = this.props.selectedField.field.properties;

    this.props.getCustomDateMeteo(meteo_station, start, end)
    this.setState({ranges:[event.selection]})
  }

  toggleDatePicker(){
    let open_ = !this.state.open;
    let color_ = !open_? "primary" : "danger";
    this.setState({open: open_, datePickerColor:color_})
  }

  render() {
    const {
      meteo_station: stationID
    } = this.props.selectedField.field.properties;
    const { live_meteo_forecast: meteo } = this.props.selectedField;

    if (stationID === "" || stationID===null)
      return (
        <EmptyDataset
          icon={faCloud}
          message="You haven't installed any meteo station in this area."
        />
      );

      return (
        <div>
          <div className={classnames("meteo-sticky", {open: this.state.open})}>
            <Button color={this.state.datePickerColor} onClick={this.toggleDatePicker}><FontAwesomeIcon icon={faCalendar} /></Button>
          </div>
          <div className="meteo-picker">
              <DateRangePicker
                onChange={this.hangleChange}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                showPreview={true}
                dragSelectionEnabled={true}
                months={1}
                ranges={this.state.ranges}
                direction="horizontal"
                maxDate={new Date()}
              />
            </div>
          <LoadingSpinerComponent area="getCustomDateMeteo-area">
            <Row className="charts">
              <Col>
                {meteo.length == 0 ? <EmptyDataset icon={faDownload} message="Loading live meteo data..." /> :
                  Object.keys(meteo).map((option, key) => {
                    return (
                      <div key={key} className="my-3">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={meteo[option]}
                        />
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </LoadingSpinerComponent>
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    selectedField: state.areas.selectedField
  };
};

export default connect(mapStateToProps, { getLiveMeteoForecast, getCustomDateMeteo })(
  MeteoForecast
);
