import React, { Component, Fragment } from "react";
import { Row, Col, Button, Badge } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { getInvitations, deleteInvitation } from "../../../actions/members";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import MemberInvitation from "./MemberInvitation";

class Invitations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          grow: 0
        },
        {
          name: "Inviter",
          selector: "inviter",
          sortable: true
        },
        {
          name: "Invitee E-mail",
          selector: "invitee_email"
        },
        {
          name: "Status",
          selector: "status"
        },
        {
          name: "Sent",
          selector: "created_at",
          sortable: true,
          format: row => moment(row.created_at).fromNow()
        },
        {
          name: "Last updated",
          selector: "updated_at",
          sortable: true,
          format: row => moment(row.updated_at).fromNow()
        },
        {
          name: "Actions",
          cell: row => (
            <div>
              <Button
                color="danger"
                onClick={() =>
                  this.props.deleteInvitation(
                    this.props.match.params.id,
                    row.id
                  )
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          grow: 1,
          button: true,
          right: true
        }
      ]
    };
  }

  componentDidMount() {
    const { id: teamID } = this.props.match.params;
    this.props.getInvitations(teamID);
  }

  render() {
    const { team, owner } = this.props;

    return (
      <Fragment>
        <Row>
          <Col className="d-flex align-items-center justify-content-between flex-row mt-3">
            <Button
              color="secondary"
              className="my-3"
              onClick={() => this.props.history.goBack()}
            >
              <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
              Back to Teams
            </Button>
            {(team, owner) ? (
              <MemberInvitation team={team} owner={owner} />
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row>
          <Col>
            <DataTable
              noHeader={true}
              subHeader={true}
              subHeaderAlign="left"
              subHeaderComponent={
                (team,
                owner ? (
                  <div className="d-flex flex-row justify-content-between flex-grow-1 align-items-center">
                    <h3 className="mr-3">Invitations</h3>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="my-3 mr-3 p-2 font-weight-normal text-center">
                        Team:
                        <Badge
                          color="secondary"
                          className="ml-1 font-size-normal text-light bg-dark"
                        >
                          {team.name}
                        </Badge>
                      </div>
                      <div className="my-3 p-2 font-weight-normal text-center">
                        Team Owner:
                        <Badge
                          color="secondary"
                          className="ml-1 font-size-normal text-light bg-dark"
                        >
                          {owner.profile.first_name} {owner.profile.last_name}
                        </Badge>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))
              }
              striped={true}
              columns={this.state.columns}
              data={this.props.invitations}
              highlightOnHover
              defaultSortField="id"
              defaultSortAsc={true}
              responsive={true}
              pagination
              persistTableHead
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const teamID = ownProps.match.params.id;
  return {
    invitations: state.members.invitations,
    team: state.teams.teams.find(team => team.id == teamID),
    owner: state.members.members.find(member => member.is_owner === true)
  };
};

export default connect(mapStateToProps, { getInvitations, deleteInvitation })(
  withRouter(Invitations)
);
