import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import EmptyDataset from '../../../../layout/EmptyDataset';
import classNames from 'classnames';
import NDVI from './Indices/NDVI';
import EVI from './Indices/EVI';
import SAVI from './Indices/SAVI';
import { getAreaHeatmap } from "../../../../../actions/areas"
import { connect } from 'react-redux';

class RemoteSensing extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeTab: 1,
        }

        this.toggleTab         = this.toggleTab.bind(this);
    }

    toggleTab(selectedTab) {
      this.setState({
        activeTab: selectedTab
      })
    }

    render() {

        const _tabs = [
          { id: 1, title: 'NDVI', component: <NDVI selectedField={this.props.selectedField}/>},
          { id: 2, title: 'EVI', component: <EVI selectedField={this.props.selectedField}/>},
          { id: 3, title: 'SAVI', component: <SAVI selectedField={this.props.selectedField} />},
          ];

        return (
          <Row>
            <Col md={2}>
              <Nav tabs vertical className="vertical-tabs">
                {
                  _tabs.map((tab) => {
                    return (
                      <NavItem key={tab.id}>
                        <NavLink key={tab.id} 
                          className={classNames({ active: this.state.activeTab === tab.id })}
                          onClick={() => { this.toggleTab(tab.id); }}>

                        { tab.title }
                        </NavLink>
                      </NavItem>
                    )
                  })
                }
              </Nav>
            </Col>
            <Col md={10}>
              <TabContent activeTab={this.state.activeTab}>
              {   _tabs.map(tab => {
                      return(
                      <TabPane key={tab.id} tabId={tab.id}>
                          { tab.component }
                      </TabPane>
                      )
                  })
              }
              </TabContent>
            </Col>
          </Row>
        )

    }
}

const mapStateToProps = state => {
  return {
    selectedField: state.areas.selectedField
  }
}

export default connect(mapStateToProps,{getAreaHeatmap})(RemoteSensing);