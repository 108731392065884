import React, { Component } from 'react';
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";

class MetricsCard extends Component {

    render() { 
        const { heading, count, countLabel, icon, linkTo } = this.props;

        return ( 
            <NavLink className="metrics-link" to={linkTo}>
                <Card className="metrics-card my-2">
                    <CardBody className="metrics-body p-3">
                        <h3 className="metrics-heading mb-0">{ heading } </h3>
                        <span className="metrics-count"> 
                            { count } 
                            <p className="metrics-label">{ countLabel }</p>
                        </span>
                        <FontAwesomeIcon className="metrics-icon" icon={icon} size="4x" />
                    </CardBody>
                </Card>
            </NavLink>
        );
    }
}

export default withRouter(MetricsCard) ;