import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class EmptyDataset extends Component {
    render(){
        return (
            <div className="d-flex flex-column flex-grow h-100 justify-content-center align-items-center text-center">
            <FontAwesomeIcon
              className={this.props.spin ? "fa-spin" : ""}
              icon={this.props.icon ? this.props.icon : faTimesCircle}
              size="5x"
            />
            <h5 className="text-muted mt-4">
              {this.props.message ? this.props.message : "Not functional yet!"}
            </h5>
          </div>
        );
    }
}

export default EmptyDataset;