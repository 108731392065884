import React, { Component } from "react";
import { Label, Form, Input, Row, FormGroup, Col } from "reactstrap";
import { connect } from "react-redux";
import FieldCard from "../Modal FieldCard/FieldCard";
import OverviewFieldCard from "./OverviewFieldCard";
import Authorize from "../../../auth/common/Authorize";
import { Role } from "../../../auth/common/Role";
import { sortFieldsByTeamAndClient, getTeamsAsArray, createTeamsWithMembers, getUniqueCropsOfCurrentFields } from "../../../helpers/filters"

class ViewFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      members: [],
      selectedCrop: "All",
      selectedClient: "All",
      selectedTeam: "All",
      showClickedFieldModal: false
    };
    this.handleSelection = this.handleSelection.bind(this);

    this.ToggleClickedFieldModal = this.ToggleClickedFieldModal.bind(this);
    this.GetClickedID = this.GetClickedID.bind(this);
  }

  componentDidMount() {
    this.setState({ fieldsFullData: this.props.fieldsFullData });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.state.members = nextProps.members.filter(
      member => member.manager != null
    );
  }

  ToggleClickedFieldModal() {
    this.setState(prevState => ({
      showClickedFieldModal: !prevState.showClickedFieldModal
    }));
  }

  GetClickedID(ID) {
    this.setState({ clickedId: ID });
  }

  handleSelection(event) {
    let name_ = event.target.name;
    let value_ = event.target.value;

    if (name_ == "selectedTeam") { //whenever user changes the Team filter, Client selection resets to "All"
      document.getElementById('client').value = "All";
      this.setState({ [name_]: value_, selectedClient: "All" })
    }
    else
      this.setState({ [name_]: value_ })
  }

  render() {
    if (Object.keys(this.props.fieldsFullData).length == 0) return <div />;
    const members = this.state.members;
    const fieldsFullData = this.props.fieldsFullData;
    const teams = this.props.teams

    var _uniqueCrops = getUniqueCropsOfCurrentFields(fieldsFullData);

    let _teams = getTeamsAsArray(teams);

    let _teamsWithMembers = createTeamsWithMembers(_teams, members);
    
    let _sortedClients = sortFieldsByTeamAndClient(_teamsWithMembers, members, fieldsFullData);
    
    return (
      <div>
        <Form id="overview-fields-overview" className="my-3">
          <Row>
            <Col className="d-flex flex-row align-items-center ">
              <span className="mr-5 font-weight-bold">Filter by: </span>
              <Authorize roles={[Role.Agronomist]}>
                <FormGroup className="mr-5">
                  <Label className="label font-size-xs" for="Team">
                    Team:
                  </Label>
                  <Input
                    type="select"
                    required
                    defaultValue=""
                    onChange={this.handleSelection}
                    name="selectedTeam"
                    id="team"
                  >
                    {_teams != null
                      ? _teams.map((team, key) => (
                          <option
                            value={team}
                            key={key}
                            id={"sortby_" + team}
                          >
                            {team}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>
              </Authorize>

              <Authorize roles={[Role.Agronomist]}>
                <FormGroup className="mr-5">
                  <Label className="label font-size-xs" for="Client">
                    Client:
                  </Label>
                  <Input
                    type="select"
                    required
                    defaultValue=""
                    onChange={this.handleSelection}
                    name="selectedClient"
                    id="client"
                  >
                    <option value="All">All</option>
                    {_teamsWithMembers[this.state.selectedTeam].length != 0
                      ? _teamsWithMembers[this.state.selectedTeam].map((client, key) => (
                          <option
                            value={client}
                            key={key}
                            id={"sortby_" + client}
                          >
                            {client}
                          </option>
                        ))
                      : null}
                  </Input>
                </FormGroup>
              </Authorize>

              <FormGroup>
                <Label className="label font-size-xs" for="Crop">
                  Crop:
                </Label>
                <Input
                  type="select"
                  required
                  defaultValue=""
                  onChange={this.handleSelection}
                  name="selectedCrop"
                  id="crop"
                >
                  <option value="All">All</option>
                  {_uniqueCrops != null
                    ? _uniqueCrops.map((item, key) => (
                        <option value={item} key={key} id={"sortby_" + item}>
                          {item == null ? "Free" : item}
                        </option>
                      ))
                    : null}
                </Input>
              </FormGroup>

            </Col>
          </Row>
        </Form>
        <hr></hr>
        <Row>
          {_sortedClients[this.state.selectedTeam][this.state.selectedClient] != null
            ? _sortedClients[this.state.selectedTeam][this.state.selectedClient].map((item, key) =>
                item.properties.crop.toLowerCase() ==
                  this.state.selectedCrop.toLowerCase() ||
                this.state.selectedCrop == "All" ? (
                  <OverviewFieldCard
                    key={key}
                    field={item}
                    getField={this.GetClickedID}
                    toggle={this.ToggleClickedFieldModal}
                  />
                ) : null
              )
            : null}
        </Row>
        {this.state.showClickedFieldModal ? (
          <FieldCard
            toggleModal={this.state.showClickedFieldModal}
            _externalToggle={this.ToggleClickedFieldModal}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    fieldsFullData: state.areas.fieldsFullData,
    members: state.members.members,
    teams: state.teams.teams
  };
};

export default connect(mapStateToProps)(ViewFields);
