import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Button,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { formatArea, formatName } from "../../../../helpers/formaters";
import { getScreenShotLink } from "../../../../helpers/geo";
import moment from "moment";
import Authorize from "../../../../auth/common/Authorize";
import { Role } from "../../../../auth/common/Role";

class ViewForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.field.properties.name
        ? this.props.field.properties.name
        : "",
      area: this.props.field.properties.area
        ? this.props.field.properties.area
        : "",
      area_type: this.props.field.properties.area_type
        ? this.props.field.properties.area_type
        : "",
      soil_type: this.props.field.properties.soil_type
        ? this.props.field.properties.soil_type
        : "",
      crop: this.props.field.properties.crop,
      description: this.props.field.properties.description
        ? this.props.field.properties.description
        : "",
      owner: this.getUserByUsername(
        this.props.field.properties.owner,
        this.props.members
      ),
      created_at: this.props.field.properties.created_at
        ? this.props.field.properties.created_at
        : "",
      id: this.props.field.id ? this.props.field.id : "",
      polygon: "",
      is_orchard: this.props.field.properties.is_orchard,
      num_trees: this.props.field.properties.num_trees,
      layout_trees: this.props.field.properties.layout_trees,
      years_input: this.props.field.properties.years_input,
    };
    this.mapReduxFieldPropsToState = this.mapReduxFieldPropsToState.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.mapReduxFieldPropsToState(nextProps.field);
  }

  mapReduxFieldPropsToState(field) {
    const {
      name,
      area,
      crop,
      owner,
      area_type,
      soil_type,
      description,
      is_orchard,
      num_trees,
      layout_trees,
      years_input,
    } = field.properties;
    this.setState({
      name: name,
      area: area,
      crop: crop,
      owner: this.getUserByUsername(owner, this.props.members),
      area_type: area_type,
      soil_type: soil_type,
      description: description == "" ? "-" : description,
      is_orchard: is_orchard,
      num_trees: num_trees,
      layout_trees: layout_trees,
      years_input: years_input,
    });
  }

  getUserByUsername(username_, members_) {
    let currentUser = this.props.currentUser;

    //check to see if Landholder group exists inside current user's groups
    //if Landholder, return empty Owner, since we do not authorize access such users to owner information
    if (
      currentUser.groups.filter((group) => group == Role.Landholder).length > 0
    )
      return "";

    let client = members_.filter((client_) => client_.user == username_)[0];
    return (
      formatName(client.profile.first_name) +
      " " +
      formatName(client.profile.last_name)
    );
  }

  render() {
    const {
      name,
      area,
      soil_type,
      crop,
      description,
      owner,
      is_orchard,
      num_trees,
      layout_trees,
      years_input,
      created_at,
    } = this.state;

    return (
      <Col sm="12" md="12" lg="6">
        <Card>
          <CardHeader className="bg-primary text-white">
            Field Basic Info
          </CardHeader>
          <CardBody>
            <Row>
              <Col>
                <Row>
                  <Col md={4}>
                    <Label for="name">Name:</Label>
                    <p>{name}</p>
                  </Col>
                  <Col md={this.state.is_orchard ? 3 : 4}>
                    <Label for="name">Area:</Label>
                    <p>{formatArea(area) + " m\u00B2"}</p>
                  </Col>
                  <Col md={this.state.is_orchard ? 3 : 4}>
                    <Label for="Type">Type:</Label>
                    <p>{is_orchard ? "Orchard" : "Arable"}</p>
                  </Col>
                  {this.state.is_orchard ? (
                    <Col md={2}>
                      <Label for="trees_number">Trees:</Label>
                      <p>{num_trees}</p>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col md={this.state.is_orchard ? 4 : 6}>
                    <Label for="name">Crop:</Label>
                    <p>{crop == null || crop == "Free" ? "-" : crop}</p>
                  </Col>
                  <Col md={this.state.is_orchard ? 3 : 6}>
                    <Label for="name">Soil:</Label>
                    <p>{soil_type}</p>
                  </Col>
                  {this.state.is_orchard ? (
                    <Col md={3}>
                      <Label for="layout">Layout:</Label>
                      <p>{layout_trees}</p>
                    </Col>
                  ) : null}
                  {this.state.is_orchard ? (
                    <Col md={2} className="pr-0">
                      <Label for="layout">Established:</Label>
                      <p>{years_input + " years"}</p>
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col md={6}>
                    <Label for="created_at">Created:</Label>
                    <p>{moment(created_at).fromNow()}</p>
                  </Col>
                  <Col md={6}>
                    <Label for="name">Description:</Label>
                    <p>
                      {description == "null" || description == ""
                        ? "-"
                        : description}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Authorize roles={[Role.Agronomist]}>
                    <Col md={6}>
                      <Label for="name">Owner:</Label>
                      <p>{owner}</p>
                    </Col>
                  </Authorize>
                </Row>
              </Col>
              <Col className="text-center">
                <img
                  className="shadow-lg border border-primary"
                  src={getScreenShotLink(this.props.field)}
                  title="Google Preview"
                  alt="Google Shot"
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button
              color="primary"
              className="mr-2"
              onClick={() => {
                this.props.toggleEdit();
              }}
            >
              <FontAwesomeIcon className="mr-2" icon={faEdit} />
                Edit
              </Button>
          </CardFooter>
        </Card>
      </Col>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.user,
    errors: state.errors,
    messages: state.messages,
    field: state.areas.selectedField.field,
    soil_types: state.areas.soil_types,
    crops: state.crops.crops,
    members: state.members.members,
    cultivations: state.cultivations.cultivations,
  };
};

export default connect(mapStateToProps)(ViewForm);
