
import { CREATE_MESSAGE, CLEAR_ERRORS, GET_ERRORS } from './types';

// Create Validation Message

export const createMessage = msg => {
    return {
        type: CREATE_MESSAGE,
        payload: msg
    };
}

// RETURN ERRORS
export const returnErrors = (msg, status) => {
    return {
      type: GET_ERRORS,
      payload: { msg, status }
    };
  };

// ClEAR ERRORS
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS,
    }
}