import React, { Fragment, Component } from "react";
import { Button, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { deleteCrop, editCrop } from "../../../actions/crops";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import Authorize from "../../auth/common/Authorize";
import { Role } from "../../auth/common/Role";

class Crops extends Component {
  constructor(props) {
    super();
    this.state = {
      columns: [
        {
          name: "Name",
          selector: "name",
          sortable: true
        },
        {
          name: "Color",
          cell: row => (
            <span
              className="size-32 border border-secondary"
              style={{ backgroundColor: row.color }}
            ></span>
          ),
          sortable: true
        },
        {
          name: "Description",
          selector: "description",
          hide: "md"
        },
        {
          name: "Actions",
          cell: row => (
            <div>
              <Authorize roles={[Role.Agronomist]}>
                <Button
                  color="secondary"
                  onClick={this.toggleEdit.bind(row, row.id)}
                  className="mr-2"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  color="danger"
                  onClick={() => this.props.deleteCrop(row.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </Authorize>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true
        }
      ]
    };
    this.handleAddCrop = this.handleAddCrop.bind(this);
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  static propTypes = {
    crops: PropTypes.array.isRequired,
    deleteCrop: PropTypes.func.isRequired,
    editCrop: PropTypes.func.isRequired
  };

  handleAddCrop = () => {
    this.props.history.push("/crops/add");
  };

  toggleEdit(id) {
    this.props.editCrop();
    this.props.history.push("/crops/" + id + "/edit");
  }

  render() {
    return (
      <Fragment>
        <Authorize roles={[Role.Agronomist]}>
          <Button
            color="primary"
            className="mt-4 mb-3"
            onClick={this.handleAddCrop}
          >
            Add Crop
            <FontAwesomeIcon className="ml-2" icon={faPlus} />
          </Button>
        </Authorize>
        <Row>
          <Col md={7}>
            <DataTable
              title="Crops"
              striped={true}
              columns={this.state.columns}
              data={this.props.crops}
              highlightOnHover
              defaultSortField="name"
              defaultSortAsc={true}
              responsive={true}
              pagination
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  crops: state.crops.crops
});

export default connect(mapStateToProps, { deleteCrop, editCrop })(
  withRouter(Crops)
);
