import React, { Component, Fragment } from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import FieldCardListItem from './FieldCardListItem';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import SearchBar from '../../../partials/SearchBar';
import { getPolygonFeatures } from '../../../helpers/geo';

import Authorize from "../../../auth/common/Authorize";
import {Role} from "../../../auth/common/Role";
import { getTeamsAsArray, createTeamsWithMembers } from '../../../helpers/filters';
import { LoadingSpinerComponent } from "../../../partials/LoadingSpiner"

class FieldSidebarList extends Component {
    constructor(props){
        super(props);

        this.state = {
            fields: this.props.areas,
            open: true,
            selectedTeam: "All"
        }
        this.toggle = this.toggle.bind(this);
        this.getFilteredResults = this.getFilteredResults.bind(this);
        this.handleSelection = this.handleSelection.bind(this);
    }

    toggle() {
        this.setState({
            open: !this.state.open
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({fields: nextProps.areas});
    }

    getFilteredResults(results){
        this.props.sendFiltered(results, this.props.markers);
        this.setState({
            fields: results
        })
    }

    filterFieldsByTeam(teams_, fields_){
        return fields_.filter(field => teams_.includes(field.properties.owner));
    }

    handleSelection(event) {
        let name_ = event.target.name;
        let value_ = event.target.value;

        let fields = this.props.areas;

        let _teams = getTeamsAsArray(this.props.teams);
        let _teams_with_members = createTeamsWithMembers(
          _teams,
          this.props.members,
          true
        );

        if (this.props.user_type.includes("Agronomist")) {
          //filter fields according to teams ONLY IF user is logged in as Agronomist
          fields = this.filterFieldsByTeam(_teams_with_members[value_], fields);
          this.props.sendFiltered(fields, this.props.markers);
        }
        this.setState({ [name_]: value_, fields: fields });
      }

    render() {

        let fields = this.state.fields;
        let _teams = getTeamsAsArray(this.props.teams);
        return(
            <Fragment>
                <Authorize roles={[Role.Agronomist]}>

                    <FormGroup className="map-dropdown-filter-formgroup">
                        <Row>
                            <Col>
                                <Label className="map-dropdown-filter-label label font-size-xs" for="Team">
                                    Team:
                                </Label>
                            </Col>
                            <Col>
                                <Input className="map-dropdown-filter"
                                    type="select"
                                    required
                                    defaultValue=""
                                    onChange={this.handleSelection}
                                    name="selectedTeam"
                                    id="sidebarlist_filter_team">
                                    {_teams != null
                                     ? _teams.map((team, key) => (
                                        <option
                                            value={team}
                                            key={key}
                                            id={"sortby_" + team}
                                        >{team}
                                        </option>
                                        ))
                                    : null}
                                </Input>
                            </Col>
                        </Row>
                    </FormGroup>
                </Authorize>

                <div className={classnames('toogle-sidebar-btn ', { open: this.state.open})} onClick={this.toggle}>
                    <FontAwesomeIcon icon={faLongArrowAltLeft} />
                </div>
                <div className="field-sidebar-list bg-lightgrey">
                    <Row className="d-flex h-100">
                        <Col className="px-4 pt-2">
                            <LoadingSpinerComponent messageh3="Loading fields..." area="getAreas-area">
                                <SearchBar
                                    items={this.props.areas}
                                    placeholderText="Search by name ..."
                                    onFilter={this.getFilteredResults}
                                />
                                {fields.map((field, index) => {
                                    return (
                                        <FieldCardListItem key={index} field={field} map={this.props.map} markers={this.props.markers} />
                                    )
                                })}
                            </LoadingSpinerComponent>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
      areas: getPolygonFeatures(state.areas.fieldsFullData),
      markers: state.areas.markers,
      teams: state.teams.teams,
      members: state.members.members,
      user_type: state.auth.user.groups

    }
  };

export default connect(mapStateToProps)(FieldSidebarList) ;