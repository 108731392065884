import React, { Component, Fragment } from "react";
import MainMap from "./MainMap";
import FieldCard from "../Modal FieldCard/FieldCard";
import { connect } from "react-redux";
import {
  getAreas,
  getAreaSoilTypes,
  addArea,
  saveMarkers,
  setSelectedField,
  ClearTranslatedCoordinates,
} from "../../../../actions/areas";
import { addCultivation } from "../../../../actions/cultivations";
import { withRouter } from "react-router-dom";
import SavePolygonModal from "../SavePolygonModal";
import { importMarkers } from "../../../helpers/assets";
import FieldSidebarList from "./FieldSidebarList";
import {
  getPolygonFeatures,
  getBounds,
  convertPolygonToWKT,
} from "../../../helpers/geo";
import MapSideDock from "../../../partials/MapSideDock";
import { Role } from "../../../auth/common/Role";
import Authorize from "../../../auth/common/Authorize";
import SaveDiscardPolygon from "./SaveDiscardPolygon";
import classnames from "classnames";
import CropsLegend from "../../../partials/Legends/CropsLegend";

const Markers = importMarkers(
  require.context("../../../../assets/markers/", true, /\.svg$/)
);

class MapFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastToggle: false,
      markers: {},
      showModal: false,
      drawn_polygon: [],
      removePolygon: false,
      fieldsFullData: [],
      showClickedFieldModal: false,
      posted_cultivation: false,
      cultivation: "Free",
    };

    this.modalChangedStatus = this.modalChangedStatus.bind(this);
    this.toggle = this.toggle.bind(this);
    this.TriggerSaveModal = this.TriggerSaveModal.bind(this);
    this.Discard = this.Discard.bind(this);

    //map-related functions
    this.PlaceMarkers = this.PlaceMarkers.bind(this);
    this.SetCropColors = this.SetCropColors.bind(this);
    this.collectFiltered = this.collectFiltered.bind(this);

    //functions as props for Maps Component
    this.HandlePolygon = this.HandlePolygon.bind(this);
    this.HandleMap = this.HandleMap.bind(this);
    this.HandleDrawingManager = this.HandleDrawingManager.bind(this);
    this.isPolygonRemoved = this.isPolygonRemoved.bind(this);
    this.triggerSaveToast = this.triggerSaveToast.bind(this);

    //functions as props for FieldsMetaDataModal
    this.ToggleClickedFieldModal = this.ToggleClickedFieldModal.bind(this);
    this.GetClickedID = this.GetClickedID.bind(this);

    //functions as props for SavePolygonModal
    this.SaveField = this.SaveField.bind(this);
  }

  componentDidMount() {
    this.props.getAreaSoilTypes();
    this.props.getAreas();
  }

  UNSAFE_componentWillReceiveProps(nextProps, prevState) {
    if (nextProps == prevState) return;

    if (nextProps.fieldByCoords != null) {
      this.setState({
        drawn_polygon: nextProps.fieldByCoords.wkt,
        polygon_object: nextProps.fieldByCoords.polygon,
      });
      this.triggerSaveToast(true);
    }

    if (nextProps.fieldsFullData) {
      let markers = {};
      this.setState({ fieldsFullData: nextProps.fieldsFullData });
      if (this.state.map) {
        let polygons = { type: "FeatureCollection", features: [] };
        for (var field in nextProps.fieldsFullData)
          polygons.features.push(nextProps.fieldsFullData[field]);
        this.state.map.data.addGeoJson(polygons);
        this.SetCropColors(this.state.map);
        markers = this.PlaceMarkers(nextProps.fieldsFullData, this.state.map);
        this.props.saveMarkers(markers);
      }
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  isPolygonRemoved(flag) {
    this.setState({ removePolygon: !flag });
  }

  ToggleClickedFieldModal() {
    this.setState((prevState) => ({
      showClickedFieldModal: !prevState.showClickedFieldModal,
    }));
  }

  TriggerSaveModal(show_) {
    this.setState({ showModal: show_ });
  }

  HandlePolygon(polygon_) {
    let Polygon = new window.google.maps.Polygon({
      path: polygon_.overlay.getPath(),
    });
    let wkt = convertPolygonToWKT(Polygon, "save"); //wkt that will be saved

    this.setState({
      drawn_polygon: wkt, //drawn_polygon (STRING) contains the coordinates of the polygon_object
      polygon_object: polygon_.overlay, //polygon_object holds the OVERLAY PROPERTY of drawn google.maps.Polygon
    });
  }

  Discard() {
    this.props.ClearTranslatedCoordinates();
    this.setState({ removePolygon: true });
  }

  HandleMap(map) {
    let markers = {};
    if (map) {
      let polygons = { type: "FeatureCollection", features: [] };
      for (var field in this.props.fieldsFullData)
        polygons.features.push(this.props.fieldsFullData[field]);
      map.data.addGeoJson(polygons);
      this.SetCropColors(map);
      markers = this.PlaceMarkers(this.props.fieldsFullData, map);
    }
    this.props.saveMarkers(markers);
    this.setState({ map: map });
  }

  HandleDrawingManager(drawingManager_) {
    this.setState({ drawingManager: drawingManager_ });
  }

  PlaceMarkers(_fieldsFullData, map) {
    var markers = {};
    getPolygonFeatures(_fieldsFullData).forEach((_Field) => {
      if (this.state.markers.hasOwnProperty(_Field.id))
        this.state.markers[_Field.id].setMap(null); //reset all markers

      var selectedPolygonCentroid = getBounds(_Field).getCenter();

      var MarkerLatLng = {
        lat: selectedPolygonCentroid.lat(),
        lng: selectedPolygonCentroid.lng(),
      };

      var icon = {
        url: Markers.filter(
          (marker) =>
            marker
              .toString()
              .split("/")
              [marker.toString().split("/").length - 1].split(".")[0] ==
            _Field.properties.area_type.toLowerCase()
        )[0],
        scaledSize: new window.google.maps.Size(50, 50),
      };

      var mymarker = new window.google.maps.Marker({
        position: MarkerLatLng,
        map: map,
        icon: icon,
        title: _Field.properties.name,
      });

      mymarker.addListener("click", (e) => {
        this.props.setSelectedField(_Field);
        this.GetClickedID(_Field.id);
        this.ToggleClickedFieldModal();
        /*
          do stuff
        */
      });

      markers[_Field.id] = mymarker;
    });
    this.setState({ markers: Object.assign({}, markers) });
    return markers;
  }

  SetCropColors(map) {
    var fields = map.data;
    fields.forEach((field) => {
      field.setProperty(
        "fillColor",
        field.j.color == null ? "black" : field.j.color
      );
      field.setProperty("opacity", 1);
      field.setProperty("stroke", 0.5);
    });

    fields.setStyle(function (feature) {
      return {
        fillColor: feature.getProperty("fillColor"),
        fillOpacity: feature.getProperty("opacity"),
        strokeWeight: feature.getProperty("stroke"),
      };
    });
  }

  modalChangedStatus() {
    this.setState({ showModal: !this.state.showModal });
  }

  GetClickedID(ID) {
    this.setState({ clickedId: ID });
  }

  SaveField(message) {
    let cultivation_message = {
      field: message.id,
      crop: message.crop,
      active: true,
      owner: message.owner,
    };

    this.props.addArea(message);

    //direct state mutation to avoid delays
    this.state.cultivation = cultivation_message;
    this.state.posted_cultivation = true;

    this.triggerSaveToast(false);
    document.getElementById("drawBtn").className = classnames(
      "btn btn-primary"
    );
    document.getElementById("inputBtn").className = classnames(
      "btn btn-primary"
    )
  }

  triggerSaveToast(show_) {
    this.setState({ toastToggle: show_ });
  }

  collectFiltered(fields_, markers_) {
    if (this.state.map) {
      let JSONpolygons = { type: "FeatureCollection", features: [] }; //initialize geoJSON polygons to be added on map
      let map = this.state.map;

      for (var field in fields_) JSONpolygons.features.push(fields_[field]); //adds fields into the geoJSON object in order to replace show only the filtered ones

      map.data.forEach(function (feature) {
        if (markers_[feature.o] != undefined) markers_[feature.o].setMap(null); //hide all markers except the ones that might have already been deleted
        map.data.remove(feature); //removes ALL polygons from the map in order to add only the filtered ones
      });

      for (var field in fields_)
        if (markers_[fields_[field].id] != undefined)
          markers_[fields_[field].id].setMap(map); //show only the markers of the filtered polygons

      this.state.map.data.addGeoJson(JSONpolygons);
      this.SetCropColors(this.state.map);
    }
  }

  render() {
    // if (Object.keys(this.props.fieldsFullData).length == 0) return <div />

    return (
      <Fragment>
        <SavePolygonModal
          save={this.SaveField}
          removeDrawnPolygon={this.Discard}
          shown={this.state.showModal}
          area={this.state.drawn_polygon}
          changed={this.modalChangedStatus}
        />

        <MainMap
          with_drawing_manager={true}
          toggleModal={this.ToggleClickedFieldModal}
          sendClickedFieldID={this.GetClickedID}
          triggerSaveToast={this.triggerSaveToast}
          passMapToParent={this.HandleMap}
          passDrawingManagerToParent={this.HandleDrawingManager}
          passPolygonToParent={this.HandlePolygon}
          erasePolygon={this.state.removePolygon}
          PolygonToErase={this.state.polygon_object}
          isPolygonRemoved={this.isPolygonRemoved}
          el="fields-map"
          height="100%"
        >
          <FieldSidebarList
            map={this.state.map}
            sendFiltered={this.collectFiltered}
          />
          <CropsLegend />
          <MapSideDock
            map={this.state.map}
            drawingManager={this.state.drawingManager}
          />
          <SaveDiscardPolygon
            show={this.state.toastToggle}
            onSave={() => this.TriggerSaveModal(true)}
            onDiscard={() => {
              this.Discard();
              this.triggerSaveToast(false);
            }}
          />
        </MainMap>

        {this.state.showClickedFieldModal ? (
          <FieldCard
            toggleModal={this.state.showClickedFieldModal}
            _externalToggle={this.ToggleClickedFieldModal}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  fieldsFullData: state.areas.fieldsFullData,
  fieldByCoords: state.areas.fieldByCoords,
});

export default connect(mapStateToProps, {
  getAreas,
  getAreaSoilTypes,
  addArea,
  saveMarkers,
  setSelectedField,
  addCultivation,
  ClearTranslatedCoordinates,
})(withRouter(MapFields));
