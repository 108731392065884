import React, {Component,Fragment} from "react";
import MetricsBoard from "../views/Dashboard/MetricsBoard";
import { getAreas } from '../../actions/areas';
import { getCultivations } from '../../actions/cultivations';
import { withRouter } from 'react-router-dom';
import { connect} from 'react-redux';
import { LoadingSpinerComponent } from "../partials/LoadingSpiner"

class Dashboard extends Component {

  componentDidMount(){
    this.props.getAreas();
    this.props.getCultivations();
  }

  render() {

    return (
      <Fragment>
        <LoadingSpinerComponent area="getAreas-area">
          <MetricsBoard />
        </LoadingSpinerComponent>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {

  return {

  }
};


export default connect(mapStateToProps,{ getAreas, getCultivations })(withRouter(Dashboard));