import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import BeatLoader from "react-spinners/BeatLoader";

export const LoadingSpinerComponent = (props) => {
  const { promiseInProgress } = usePromiseTracker({area:props.area});

  const {
    enabledh1: _enabledh1 = true,
    messageh1: _messageh1 = "Please Wait.",
    enabledh3: _enabledh3 = true,
    messageh3: _messageh3 = "Loading data...",
    loaderAnimation: _loaderAnimation = BeatLoader,
    size: _size = 30,
    color: _color = "#c8c3bd",
    className: _className = ""
  } = props;
  return (
    <div>
      {promiseInProgress === true ? (
        <div
          md="12"
          className={"d-flex flex-column flex-grow justify-content-center align-items-center text-center " + _className}
        >
          {_enabledh1 ? <h1>{_messageh1}</h1> : null}
          {_enabledh3 ? <h3>{_messageh3}</h3> : null}
          <_loaderAnimation size={_size} color={_color} />
        </div>
      ) : (
        props.children
      )}
    </div>
  );
};
