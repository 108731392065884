import React, { Component } from "react";
import { Button, UncontrolledTooltip, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDrawPolygon, faSatellite } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { Role } from "../auth/common/Role";
import Authorize from "../auth/common/Authorize";
import AddBatchFieldsModal from "./SaveByCoordinatesModal";
import { connect } from "react-redux";
import { SaveTranslatedCoordinates } from "../../actions/areas";

class MapSideDock extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.getTranslatedCoordinates = this.getTranslatedCoordinates.bind(this);
  }
  handleDrawingMode(drawingManager_) {
    if (document.getElementById("drawBtn").disabled) return;

    if (drawingManager_.drawingMode == null) {
      drawingManager_.setDrawingMode("polygon");
      this.handleDrawingButton(true);
    } else if (drawingManager_.drawingMode == "polygon") {
      drawingManager_.setDrawingMode(null);
      this.handleDrawingButton(false);
    }
  }

  handleDrawingButton(drawingStatus_) {
    document.getElementById("drawBtn").className = classnames({
      btn: true,
      "btn-danger": drawingStatus_,
      "btn-primary": !drawingStatus_,
    });
  }

  handleInputMode(){
    if(document.getElementById("inputBtn").disabled) return;

    this.refs.AddBatchFieldsModal.toggle();
  }

  getTranslatedCoordinates(coordinates, wkt, polygon) {
    this.props.SaveTranslatedCoordinates(coordinates, wkt, polygon);
  }

  render() {
    return (
      <div className="map-side-dock px-2 py-3 bg-dark text-white">
          <Row className="ml-0 mr-0 mb-3">
            <Button
              color="primary"
              id="drawBtn"
              ref={this.drawBtn}
              onClick={() => {
                this.setState({ hidden: true });
                this.handleDrawingMode(this.props.drawingManager);
              }}
            >
              <FontAwesomeIcon
                className="text-light"
                size="1x"
                icon={faDrawPolygon}
              />
            </Button>
            <UncontrolledTooltip placement="right" target="drawBtn">
              Draw an area with mouse
            </UncontrolledTooltip>
          </Row>

          <Row className="mt-1 ml-0 mr-0">
            <Button
              color="primary"
              id="inputBtn"
              onClick={() => {
                this.handleInputMode();
              }}
            >
              <FontAwesomeIcon
                className="text-light"
                size="1x"
                icon={faSatellite}
              />
            </Button>
            <UncontrolledTooltip placement="right" target="inputBtn">
              Draw an area with coordinates
            </UncontrolledTooltip>
          </Row>
          <AddBatchFieldsModal
            map={this.props.map}
            ref="AddBatchFieldsModal"
            returnCoordinates={this.getTranslatedCoordinates}
          />
      </div>
    );
  }
}

export default connect(null, { SaveTranslatedCoordinates })(MapSideDock);
