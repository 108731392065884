import React, { Component } from "react";
import classnames from 'classnames';
import { TabContent, Row, Col, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { get_precipitation, get_cloud_and_solar_radiation, get_heat_wind_humidity_pressure } from "../../../../../actions/areas";
import { connect } from 'react-redux';

import HeatChart from "./HeatChartComponent"
import WindChart from "./WindChartComponent"
// import CloudCoverageChart from "./CloudCoverageComponent"
// import SolarRadiationChart from "./SolarRadiationComponent"
import HumidityChart from "./HumidityChartComponent"
import PressureChart from "./PressureChartComponent"
import PrecipitationIntensityChart from "./PrecipitationIntensityComponent"
import PrecipitationProbabilityChart from "./PrecipitationProbabilityComponent"
import EmptyDataset from "../../../../layout/EmptyDataset";
import { faDatabase } from "@fortawesome/free-solid-svg-icons";
import {LoadingSpinerComponent} from "../../../../partials/LoadingSpiner"

class ForecastCharts extends Component {

    constructor(props) {
      super(props);
      this.state = {
        field: [],
        activeTab: 1
      };
      this.toggle = this.toggle.bind(this);
    }
  
    componentDidMount() {
      this.props.get_heat_wind_humidity_pressure(this.props.field)
      this.props.get_precipitation(this.props.field);
      // this.props.get_cloud_and_solar_radiation(this.props.field);
      
    }
  
    toggle(tab) {
      this.setState({
        activeTab: tab
      });
    }
  
    render() {
  
      var _tabs = [
        {
          id: 1,
          title: "Heat",
          component: (
            <LoadingSpinerComponent area={"get_heat_wind_humidity_pressure-area"}
              messageh3="Loading Heat forecast..."
            >
              <HeatChart
                title={"Heat"}
                DataSource="http://www.openweathermap.org"
              />
            </LoadingSpinerComponent>
          ),
        },
        {
          id: 2,
          title: "Wind",
          component: (
            <LoadingSpinerComponent area={"get_heat_wind_humidity_pressure-area"}
              messageh3="Loading Wind forecast..."
            >
              <WindChart
                title={"Wind"}
                DataSource="http://www.openweathermap.org"
              />
            </LoadingSpinerComponent>
          ),
        },
        {
          id: 3,
          title: "Precipitation Probability",
          component: (
            <LoadingSpinerComponent area="get_precipitation-area"
              messageh3="Loading Precipitation Probability forecast..."
            >
              <PrecipitationProbabilityChart
                title={"Precipitation Probability"}
                DataSource="http://www.darksky.net"
              />
            </LoadingSpinerComponent>
          ),
        },
        {
          id: 4,
          title: "Precipitation Intensity",
          component: (
            <LoadingSpinerComponent area="get_precipitation-area"
              messageh3="Loading Precipitation Intensity forecast..."
            >
              <PrecipitationIntensityChart
                title={"Precipitation Intensity"}
                DataSource="http://www.darksky.net"
              />
            </LoadingSpinerComponent>
          ),
        },
        {
          id: 5,
          title: "Cloud Coverage",
          component: (
            <EmptyDataset message="There are no available Cloud Coverage data" icon={faDatabase}/>
            // <CloudCoverageChart
            //   title={"Cloud Coverage"}
            //   DataSource="http://www.weatherbit.io"
            // />
          )
        },
        {
          id: 6,
          title: "Solar Radiation",
          component: (
            <EmptyDataset message="There are no available Solar Radiation data" icon={faDatabase}/>
            // <SolarRadiationChart
            //   title={"Solar Radiation"}
            //   DataSource="http://www.weatherbit.io"
            // />
          )
        },
        {
          id: 7,
          title: "Humidity",
          component: (
            <LoadingSpinerComponent area={"get_heat_wind_humidity_pressure-area"}
              messageh3="Loading Humidity forecast..."
            >
              <HumidityChart
                title={"Humidity"}
                DataSource="http://www.openweathermap.org"
              />
            </LoadingSpinerComponent>
          ),
        },
        {
          id: 8,
          title: "Pressure",
          component: (
            <LoadingSpinerComponent area={"get_heat_wind_humidity_pressure-area"}
              messageh3="Loading Pressure forecast..."
            >
              <PressureChart
                title={"Pressure"}
                DataSource="http://www.openweathermap.org"
              />
            </LoadingSpinerComponent>
          ),
        },
      ];
  
      return (
        <Row>
          <Col md={2}>
            <Nav tabs vertical className="vertical-tabs">
              {
                _tabs.map((tab) => {
                  return (
                    <NavItem key={tab.id}>
                      <NavLink key={tab.id} 
                        className={classnames({ active: this.state.activeTab === tab.id })}
                        onClick={() => { this.toggle(tab.id); }}>

                      {tab.title}
                      </NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Col>
          <Col md={10}>
            <TabContent activeTab={this.state.activeTab}>
              {_tabs.map((tab)=>{
                return(
                  <TabPane key={tab.id} tabId={tab.id} >
                    {tab.component}
                  </TabPane>
                )
              })}
            </TabContent>
          </Col>
        </Row>
      )
    }
  }

const MapStateToProps = (state) => {
  return {state: state}
}

 export default connect(MapStateToProps, {get_precipitation, get_cloud_and_solar_radiation, get_heat_wind_humidity_pressure})(ForecastCharts)