import {
  GET_MEMBERS_BY_TEAM,
  GET_ALL_MEMBERS,
  DELETE_MEMBER,
  INVITE_MEMBER,
  GET_INVITATIONS,
  DELETE_INVITATION,
  ACCEPT_INVITATION,
  GET_ERRORS,
  UPDATE_MEMBER
} from "./types";
import { createMessage } from "./messages";
import { trackPromise } from "react-promise-tracker";

// Get all teams
export const getMembersByTeam = teamID => dispatch => {
  trackPromise(
  window.axios
    .get(`/team/${teamID}/member`)
    .then(res => {
      dispatch({
        type: GET_MEMBERS_BY_TEAM,
        payload: res.data
      });
    })
    .catch(error => console.log(error)),"Members-area");
};

export const getAllMembers = () => dispatch => {
  trackPromise(
  window.axios
    .get(`/member`)
    .then(res => {
      dispatch({
        type: GET_ALL_MEMBERS,
        payload: res.data
      });
    })
    .catch(error => console.log(error)),"getAllMembers-area");
};

// Delete Member
export const deleteMember = (teamID, memberID) => dispatch => {
  window.axios
    .delete(`/team/${teamID}/member/${memberID}`)
    .then(res => {
      dispatch(createMessage({ deleteMember: "Member has been removed." }));
      dispatch({
        type: DELETE_MEMBER,
        payload: memberID
      });
    })
    .catch(error => console.log(error));
};

export const updateMember = (memberID, updatedInfo) => dispatch => {
  trackPromise(
    window.axios
    .patch(`/user/${memberID}/settings/`, updatedInfo)
    .then(res => {
      dispatch(createMessage({updateMember: "Member's info has been updated."}))
      dispatch({
        type: UPDATE_MEMBER,
        payload: { memberID, updatedInfo }
      })
    }),"Members-area");
}

export const importUser = (memberID, memberTeam, xml_file) => dispatch => {
  let body = new FormData();
  body.append("xml", xml_file)
  
  trackPromise(
    window.axios
      .post(`/user/${memberID}/import/xml`, body,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        dispatch(getMembersByTeam(memberTeam.id));
      })
      .catch(error => console.log(error)), "Members-area");
};

// Invite Member
export const inviteMember = (inviter, invitee_email, team) => dispatch => {
  const invitation = Object.assign({
    inviter: inviter.profile.username,
    invitee_email: invitee_email,
    team: team.name
  });

  window.axios
    .post(`/team/${team.id}/invitation/`, invitation)
    .then(res => {
      dispatch(
        createMessage({ invitation_sent: "Your invitation has been sent." })
      );
      dispatch({
        type: INVITE_MEMBER,
        payload: res.data
      });
    })
    .catch(error => console.log(error));
};

export const acceptInvitation = invitationDetails => dispatch => {
  window.axios
    .post(`/team/invite/accept/`, invitationDetails)
    .then(res => {
      dispatch({
        type: ACCEPT_INVITATION,
        payload: res.data
      });
    })
    .catch(error => {
      const errors = {
        msg: error.response.data,
        status: error.response.status,
        hasErrors: true
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors
      });
    });
};

// Get all invitations
export const getInvitations = teamID => dispatch => {
  window.axios
    .get(`/team/${teamID}/invitation`)
    .then(res => {
      dispatch({
        type: GET_INVITATIONS,
        payload: res.data
      });
    })
    .catch(error => console.log(error));
};

// Delete Member
export const deleteInvitation = (teamID, invitationID) => dispatch => {
  window.axios
    .delete(`/team/${teamID}/invitation/${invitationID}`)
    .then(res => {
      dispatch(
        createMessage({ deleteInvitation: "Invitation has been removed." })
      );
      dispatch({
        type: DELETE_INVITATION,
        payload: invitationID
      });
    })
    .catch(error => console.log(error));
};
