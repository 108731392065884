import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import EditForm from "./EditProfile";
import ViewForm from "./ViewProfile";
import { LoadingSpinerComponent } from "../../../partials/LoadingSpiner";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      submitted: false,
    };
    this.toggleEdit = this.toggleEdit.bind(this);
  }

  componentDidMount() {}

  toggleEdit = (e) => {
    this.setState({ editing: !this.state.editing });
  };

  render() {
    const { editing } = this.state;
    return (
      <Fragment>
        {editing ? (
          <EditForm {...this.props} toggleEdit={this.toggleEdit} />
        ) : (
        <LoadingSpinerComponent area="Members-area">
          <ViewForm {...this.props} toggleEdit={this.toggleEdit} />
        </LoadingSpinerComponent>
        )}
      </Fragment>
    );
  }
}

export default connect(null, {})(withRouter(Profile));
