import React, { Component, Fragment } from "react";
import { Row, Col, Button, Badge, Modal } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft, faFileImport, faEye } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { getMembersByTeam, deleteMember, importUser } from "../../../actions/members";
import { formatName } from "../../helpers/formaters";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import MemberInvitation from "./MemberInvitation";
import { swal, close } from "react-redux-sweetalert";
import { LoadingSpinerComponent } from "../../partials/LoadingSpiner";
import Profile from "../Settings/Profile";

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      columns: [
        {
          name: "Username",
          selector: "user",
          sortable: true
        },
        {
          name: "First name",
          selector: "profile.first_name"
        },
        {
          name: "Last name",
          selector: "profile.last_name"
        },
        {
          name: "E-mail",
          selector: "profile.email"
        },
        {
          name: "Joined",
          selector: "profile.date_joined",
          sortable: true,
          format: row => moment(row.profile.date_joined).fromNow()
        },
        {
          name: "Actions",
          cell: row => (
            <div>
              <Button
                color="primary"
                className="mr-1"
                onClick={() => {
                  this.setState({
                    user_profile: this.props.members.filter(
                      (member) => member.id == row.id
                    )[0].profile,
                  });
                  this.toggle();
                }}
              >
                <FontAwesomeIcon icon={faEye} />
              </Button>
              <Button
                className="mr-1"
                color="secondary"
                onClick={() => this.handleClick(row.id)}
              >
                <FontAwesomeIcon icon={faFileImport} />
              </Button>
              <Button
                color="danger"
                onClick={() =>
                  this.handleUserDelete(this.props.match.params.id, row.id)
                }
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          grow: 1,
          button: true,
          right: true
        },
        {
          name: "",
          cell: (row) => null,
        },
      ],
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  handleUserDelete(team_id, user_id) {
    let user_to_delete = this.props.members.filter(
      (member) => member.id == user_id
    )[0];
    let user_fullname =
      formatName(user_to_delete.profile.first_name) +
      " " +
      formatName(user_to_delete.profile.last_name);
    let user_team = user_to_delete.team;
    let swalOptions = {
      title: "Caution",
      text: `You are about to remove client "${user_fullname}" from "${user_team}".\n\n Are you sure?`,
      type: "warning",
      showCancelButton: true,
      onConfirm: () => {
        this.props.deleteMember(team_id, user_id);
        this.props.close();
      },
    };
    this.props.swal(swalOptions);
  }

  handleClick = (row_id) => {
    this.inputElement.click();
    this.inputElement.selectedUser_id = row_id;
  };

  handleFileSelect = (xml_file) => {
    let member_id = this.inputElement.selectedUser_id;
    let memberTeam = this.props.team;
    let user_to_import = this.props.members.filter(
      (member) => member.id == member_id
    )[0];
    let user_profile_id = user_to_import.profile.id;
    let user_fullname =
      formatName(user_to_import.profile.first_name) +
      " " +
      formatName(user_to_import.profile.last_name);
    let swalOptions = {
      title: "Caution",
      text: `You have selected "${xml_file.name}" in order to import client's "${user_fullname}" data.\n\n Are you sure?`,
      type: "warning",
      showCancelButton: true,
      onConfirm: () => {
        this.props.importUser(user_profile_id, memberTeam, xml_file);
        this.props.close();
      },
    };
    this.props.swal(swalOptions);
  };

  componentDidMount() {
    const { id: teamID } = this.props.match.params;
    this.props.getMembersByTeam(teamID);
  }

  render() {
    const { team, owner } = this.props;

    return (
      <Fragment>
        <Row>
          <Col className="d-flex align-items-center justify-content-between flex-row mt-3">
            <Button
              color="secondary"
              className="my-3"
              onClick={() => this.props.history.goBack()}
            >
              <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
              Back to Teams
            </Button>
            {(team, owner) ? (
              <MemberInvitation team={team} owner={owner} />
            ) : (
              ""
            )}
          </Col>
        </Row>
        <LoadingSpinerComponent area="Members-area">
        <Row>
          <Col>
            <DataTable
              title="Clients"
              noHeader={true}
              subHeader={true}
              subHeaderAlign="left"
              subHeaderComponent={
                (team,
                owner ? (
                  <div className="d-flex flex-row justify-content-between flex-grow-1 align-items-center">
                    <h3 className="mr-3">Clients</h3>
                    <div className="d-flex align-items-center justify-content-center">
                      <div className="my-3 mr-3 p-2 font-weight-normal text-center">
                        Team:
                        <Badge
                          color="secondary"
                          className="ml-1 font-size-normal text-light bg-dark"
                        >
                          {team.name}
                        </Badge>
                      </div>
                      <div className="my-3 p-2 font-weight-normal text-center">
                        Team Owner:
                        <Badge
                          color="secondary"
                          className="ml-1 font-size-normal text-light bg-dark"
                        >
                          {owner.profile.first_name} {owner.profile.last_name}
                        </Badge>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                ))
              }
              striped={true}
              columns={this.state.columns}
              data={this.props.members}
              highlightOnHover
              defaultSortField="id"
              defaultSortAsc={true}
              responsive={true}
              pagination
              persistTableHead
            />
          </Col>
        </Row>
        </LoadingSpinerComponent>
        <div hidden={true}>
          <input
            onChange={(e) => {
              this.handleFileSelect(e.target.files[0]);
            }}
            ref={(input) => (this.inputElement = input)}
            type="file"
            accept=".xml"
          />
        </div>
        <Modal size="lg" isOpen={this.state.modal} toggle={this.toggle}>
          <Profile profile={this.state.user_profile} />
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const teamID = ownProps.match.params.id;
  return {
    members: state.members.team_members.filter(member => member.is_owner !== true),
    team: state.teams.teams.find(team => team.id == teamID),
    owner: state.members.team_members.find(member => member.is_owner === true)
  };
};

export default connect(mapStateToProps, { getMembersByTeam, deleteMember, importUser, swal, close })(
  withRouter(Clients)
);
