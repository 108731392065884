import React, { Component } from "react";
import { Form, FormGroup, Button, Input, FormFeedback } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { inviteMember } from "../../../actions/members";
import PropTypes from "prop-types";

class MemberInvitation extends Component {
  state = {
    invitee_email: ""
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    console.log("Submitted form.");

    const { invitee_email } = this.state;
    const { team, owner: inviter } = this.props;

    this.props.inviteMember(inviter, invitee_email, team);
    this.setState({
      invitee_email: ""
    });
  };

  render() {
    const { invitee_email } = this.state;
    const { errors } = this.props;

    return (
      <Form className="mr-3" onSubmit={this.onSubmit}>
        <FormGroup className="d-flex flex-row justify-content-center align-items-center mb-0">
          <Input
            type="email"
            name="invitee_email"
            placeholder="E-mail Address"
            value={invitee_email}
            onChange={this.onChange}
          />
          <Button
            color="primary"
            className="ml-3 my-3 d-flex align-items-center"
          >
            <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
            Invite
          </Button>
          <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
            {errors.msg.invitee_email}
          </FormFeedback>
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    messages: state.messages
  };
};

export default connect(mapStateToProps, { inviteMember })(MemberInvitation);

MemberInvitation.propTypes = {
  inviteMember: PropTypes.func.isRequired
};
