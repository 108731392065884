import React, { Component } from "react";
import classnames from 'classnames';
import { TabContent, Row, Col, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from 'react-redux';
import PointGenerator from "./PointGenerator";

class Utilities extends Component {

    constructor(props) {
      super(props);
      this.state = {
        field: [],
        activeTab: 1
      };
      this.toggle = this.toggle.bind(this);
    }
  
    toggle(tab) {
      this.setState({
        activeTab: tab
      });
    }
  
    render() {
  
      var _tabs = [
        {
          id: 1,
          title: "Point Generator",
          component: (<PointGenerator selectedField={this.props.field}></PointGenerator>)
        },
      ];
  
      return (
        <Row>
          <Col md={2}>
            <Nav tabs vertical className="vertical-tabs">
              {
                _tabs.map((tab) => {
                  return (
                    <NavItem key={tab.id}>
                      <NavLink key={tab.id} 
                        className={classnames({ active: this.state.activeTab === tab.id })}
                        onClick={() => { this.toggle(tab.id); }}>

                      {tab.title}
                      </NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>
          </Col>
          <Col md={10}>
            <TabContent activeTab={this.state.activeTab}>
              {_tabs.map((tab)=>{
                return(
                  <TabPane key={tab.id} tabId={tab.id} >
                    {tab.component}
                  </TabPane>
                )
              })}
            </TabContent>
          </Col>
        </Row>
      )
    }
  }

 export default Utilities;
