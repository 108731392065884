import {
  GET_TEAMS,
  DELETE_TEAM,
  ADD_TEAM,
  UPDATE_TEAM,
  GET_ERRORS
} from "./types";
import { createMessage } from "./messages";
import { trackPromise } from "react-promise-tracker";

// Get all teams
export const getTeams = () => dispatch => {
  trackPromise(
    window.axios
      .get("/team")
      .then(res => {
        dispatch({
          type: GET_TEAMS,
          payload: res.data
        });
      })
      .catch(error => console.log(error)), "getTeams-area");
};

// Add a new Team
export const addTeam = team => dispatch => {
  window.axios
    .post("/team/", team)
    .then(res => {
      dispatch(createMessage({ addTeam: "Team added." }));
      dispatch({
        type: ADD_TEAM,
        payload: res.data
      });
    })
    .catch(error => {
      const errors = {
        msg: error.response.data,
        status: error.response.status,
        hasErrors: true
      };

      dispatch({
        type: GET_ERRORS,
        payload: errors
      });
    });
};

// Update Team
export const updateTeam = team => dispatch => {
  window.axios
    .patch(`/team/${team.id}/`, team)
    .then(res => {
      dispatch({
        type: UPDATE_TEAM,
        payload: res.data
      });
      dispatch(createMessage({ updateTeam: "Team updated." }));
    })
    .catch(error => {
      const errors = {
        msg: error.response.data,
        status: error.response.status,
        hasErrors: true
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors
      });
    });
};

// Delete Team
export const deleteTeam = id => dispatch => {
  window.axios
    .delete(`/team/${id}/`)
    .then(res => {
      dispatch(createMessage({ deleteTeam: "Team deleted." }));
      dispatch({
        type: DELETE_TEAM,
        payload: id
      });
    })
    .catch(error => console.log(error));
};
