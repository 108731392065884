import React, { Component, createRef, Fragment } from "react";
import classnames from "classnames";
import { connect } from 'react-redux';
import {zoomToField} from "../helpers/geo"
import {convertPolygonToWKT} from "../helpers/geo"

class EditMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      el: this.props.el,
      map: null,
      polygonOverlay: []
    };

    this.init = this.init.bind(this);
  }

  componentDidMount() {
    this.init();
  }

  /**
   *  Initializes Map Component
   */
  init() {
    let map = this.createGoogleMap();
    this.LoadPolygon(map);
    zoomToField(this.props.selectedField,map)

    
  }

  LoadPolygon(_map){
    let field = this.props.selectedField;
    let fieldCoords = field.geometry.coordinates[0];
    let polygonCoordinates = [];

    for(var coord in fieldCoords)
      polygonCoordinates.push({lat: fieldCoords[coord][1], lng: fieldCoords[coord][0]});

      polygonCoordinates.pop();
    // Construct the polygon.
    var fieldPolygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
      strokeColor: field.properties.color,
      strokeOpacity: 1,
      strokeWeight: 0,
      fillColor: field.properties.color,
      fillOpacity: 0.75,
      editable: true,
      draggable: true
    });
    // console.log(bermudaTriangle)
    fieldPolygon.setMap(_map);

    var polygon = {
      overlay: fieldPolygon,
      type: window.google.maps.drawing.OverlayType.POLYGON
    }

    let wkt = convertPolygonToWKT(polygon.overlay,"edit");
    this.props.submitPolygon(wkt, fieldPolygon);

    var props = this.props;

    window.google.maps.event.addListener(polygon.overlay.getPath(), 'insert_at', function (index, obj) {
      let wtk = convertPolygonToWKT(polygon.overlay,"edit");
      props.submitPolygon(wtk, fieldPolygon);
    })

    window.google.maps.event.addListener(polygon.overlay.getPath(), 'set_at', function (index, obj) {
      let wtk = convertPolygonToWKT(polygon.overlay,"edit");
      props.submitPolygon(wtk, fieldPolygon);
    })
  }

  /**
   *  Creates the GoogleMap object and attaches it to the state
   */
  createGoogleMap() {
    if (this.state.el == undefined || this.state.el === "") {
      console.error("Please specify (el) parameter to the map component.");
      return;
    }
    
    let map = new window.google.maps.Map(document.getElementById(this.props.el), {
      center: new window.google.maps.LatLng(39.3666667, 22.9458333),
      zoom: 7,
      disableDefaultUI: false,

      mapTypeControl: false,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,

      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_CENTER
      },
      streetViewControl: false,
      fullscreenControl: true
    });

    this.setState({map: map});
    return map;
  }

  render() {
    return (
      <Fragment>
      <div className="position-relative h-100 w-100">
        <div
          id={this.props.el}
          className={classnames("g-map mh-100", this.props.classes)}
          style={{ height: this.props.height }}
        >
        </div>
        { this.props.children}
      </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
    return{
        selectedField: state.areas.selectedField.field,
        files: state.areas.selectedField.files
    }
}

export default connect(mapStateToProps)(EditMap);
