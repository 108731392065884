import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormFeedback,
  Input,
  Button,
  Label,
  Card,
  CardBody,
  CardHeader,
  CardFooter
} from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { login } from "../../../actions/auth";
import { clearErrors } from "../../../actions/messages";
import { acceptInvitation } from "../../../actions/members";
import { connect } from "react-redux";
import { formatName } from "../../helpers/formaters"

class AcceptInvitationForm extends Component {
  state = {
    username: "",
    password: "",
    first_name: "",
    last_name: "",
    invitation_token: this.props.match.params.token,
    submitted: false
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    this.state.submitted = true;
    const {
      username,
      password,
      invitation_token,
      first_name,
      last_name
    } = this.state;
    const invitationDetails = Object.assign(
      {},
      {
        username: username,
        password: password,
        first_name: formatName(first_name),
        last_name: formatName(last_name),
        invitation_token: invitation_token
      }
    );
    this.props.clearErrors();
    this.props.acceptInvitation(invitationDetails);
  };

  onReject = e => {
    e.preventDefault();
    console.log("Rejected");
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { username, password } = this.state;
    if (this.state.submitted) {
      if (!nextProps.errors.hasErrors) {
        this.props.login(username, password);
        this.props.history.push("/");
      }
    }
  }

  render() {
    const { errors } = this.props;
    const {
      username,
      invitation_token,
      password,
      first_name,
      last_name
    } = this.state;

    return (
      <Row className="d-flex justify-content-center align-items-center h-100">
        <Col sm="12" md="9" lg="4">
          <Card>
            <Form id="teamForm" onSubmit={this.onSubmit}>
              <CardHeader className="bg-dark text-white">
                New Member Invitation
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <p>
                      Please fill in the following form to complete your
                      registration.
                    </p>

                    <FormGroup>
                      <Input
                        type="hidden"
                        name="invitation_token"
                        id="invitation_token"
                        value={invitation_token}
                        invalid={
                          errors.hasErrors && errors.msg.invitation_token
                        }
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.invitation_token}
                      </FormFeedback>
                    </FormGroup>

                    <Label for="name">Username:</Label>
                    <FormGroup>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        value={username}
                        invalid={errors.hasErrors && errors.msg.username}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.username}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <Label for="password">Password:</Label>
                    <FormGroup>
                      <Input
                        type="password"
                        name="password"
                        id="password"
                        value={password}
                        invalid={errors.hasErrors && errors.msg.password}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.password}
                      </FormFeedback>
                    </FormGroup>
                    <Label for="name">First Name:</Label>
                    <FormGroup>
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        value={first_name}
                        invalid={errors.hasErrors && errors.msg.first_name}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.first_name}
                      </FormFeedback>
                    </FormGroup>
                    <Label for="name">Last Name:</Label>
                    <FormGroup>
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        value={last_name}
                        invalid={errors.hasErrors && errors.msg.last_name}
                        onChange={this.onChange}
                      />
                      <FormFeedback
                        invalid={errors.hasErrors ? "true" : "false"}
                      >
                        {errors.msg.last_name}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter className="text-right">
                <Button color="primary" className="mr-2">
                  Accept & Submit
                </Button>
                <Button color="danger" onClick={this.onReject}>
                  Reject
                </Button>
              </CardFooter>
            </Form>
          </Card>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    messages: state.messages,
    accepted_invitation: state.members.accepted_invitation
  };
};

export default connect(mapStateToProps, {
  acceptInvitation,
  clearErrors,
  login
})(withRouter(AcceptInvitationForm));

AcceptInvitationForm.propTypes = {
  acceptInvitation: PropTypes.func.isRequired,
  rejectInvitation: PropTypes.func.isRequired
};
