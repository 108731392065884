import React, { Component } from "react";
import { Col } from "reactstrap"
import { formatArea, formatName } from "../../../../helpers/formaters";
import DataTable from "react-data-table-component";
import Row from "reactstrap/lib/Row";

class MainTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
          parentColumns: [
            {
              name: "Team",
              selector: "name",
              sortable: true,
            },
            {
              name: "Clients",
              selector: "clients",
              sortable: true,
            },
            {
              name: "Fields",
              selector: "fields",
              sortable: true,
            },
            {
              name: "Area (ha)",
              selector: "area",
              sortable: true,
            },
            {
              name: "Created at",
              selector: "created_at",
              sortable: true
            }
          ],

          childColumns: [
            {
              name: "Client",
              selector: "fullname",
              sortable: true,
            },
            {
              name: "Username",
              selector: "username",
              sortable: true,
            },
            {
              name: "Fields",
              selector: "fields",
              sortable: true,
            },
            {
              name: "Area (ha)",
              selector: "area",
              sortable: true,
            },
            {
              name: "Main crop",
              selector: "crop",
              sortable: true,
            },
          ],
          fields: [],
          members: [],
          teams: [],
        };
    }

    getClientsOfTeam(team_, members_){
      let filtered_clients = members_.filter(member => member.team == team_.name)
      return filtered_clients;
    }

    getFieldsOfClients(members_, fields_){
      let member_names = members_.map(member => {return member.user})      
      let filtered_fields = fields_.filter(field => member_names.includes(field.properties.owner))
      return filtered_fields;
    }

    createParentData(fields_, teams_, members_){
      let fields  = Object.keys(fields_).map(key => {return fields_[key]})
      let members = Object.keys(members_).map(key => {return members_[key]})
      let teams   = Object.keys(teams_).map(key => {return teams_[key]})

      let TableData = [];

      teams.forEach(team => {
        let TableRow = {};
        TableRow["name"] = team.name;
        TableRow["agronomist"] = team.created_by;;
        TableRow["created_at"] = team.created_at.split("T")[0];

        let team_clients = this.getClientsOfTeam(team, members);
        TableRow["clients"] = team_clients.length; //number of clients
        
        let client_fields = this.getFieldsOfClients(team_clients, fields);
        
        TableRow["fields"] =  client_fields.length
        TableRow["area"] = formatArea(client_fields.reduce((sum,b) => sum + b.properties.area, 0), "ha");

        TableRow["subTable"] = this.createChildData(fields, team, members);
        TableData.push(TableRow);

      });
      
      return TableData;
    }

    createChildData(fields_, team_, members_){
      let subTableData = [];
      let team_clients = this.getClientsOfTeam(team_, members_);
      team_clients.forEach(client => {
        let TableRow = {};
        TableRow["fullname"] = formatName(client.profile.first_name) + " " + formatName(client.profile.last_name);
        TableRow["username"] = client.profile.username;

        TableRow["fields"] = fields_.reduce((sum,field) => sum + (field.properties.owner == client.profile.username? 1 : 0), 0) //counts client's fields
        TableRow["area"] = formatArea(fields_.reduce((sum, field) => sum + (field.properties.owner == client.profile.username? field.properties.area : 0), 0), "ha" ); //counts client's area
        TableRow["crop"] = this.getClientMainCrop(fields_, client); //finds client's biggest crop

        subTableData.push(TableRow);
      });
      return subTableData;
    }

    getClientMainCrop(fields_, client_){
      let crops = {};
      fields_.forEach(field => {
        let crop_ = field.properties.crop == null? "Free" : field.properties.crop;
        if (field.properties.owner == client_.profile.username) {
          if (crops[crop_] == undefined) crops[crop_] = 0;

          crops[crop_] += field.properties.area;
        }
      });
      let crop_areas = Object.keys(crops).map((function ( key ) { return crops[key]; }));
      let max = Math.max.apply(null,crop_areas);
      max = Object.keys(crops).find(key => crops[key] == max);

      return max != undefined ? max : "-"
    }

    static getDerivedStateFromProps(nextProps, prevState){
      if (nextProps == prevState) return null;
      let {fields, members, teams} = nextProps;

      return {fields, members, teams}
    }

    render() {
      let parentData = this.createParentData(this.state.fields, this.state.teams, this.state.members);

      //this is the child table, that is shown when a team is clicked
      const ExpanableComponent = ({ data }) => 
      <Row>
        <Col className="ml-5" >
          <DataTable
            title={data.name + " overview"}
            columns={this.state.childColumns}
            data={data.subTable}
            striped={true}
            highlightOnHover
            defaultSortField="fullname"
            defaultSortAsc={true}
            responsive={true}
            pagination
          />
        </Col>
      </Row>

        //this is the main table, consisting of Teams
        return (
            <DataTable
              title="Overview"
              columns={this.state.parentColumns}
              data={parentData}
              striped={true}
              highlightOnHover
              defaultSortField="name"
              defaultSortAsc={true}
              responsive={true}
              pagination
              expandableRows
              expandOnRowClicked
              expandableRowsComponent={<ExpanableComponent />}
              expandableRowDisabled={row=> row.clients==0}
            />
        );
    }
}

export default MainTable