import React, { Component } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import EmptyDataset from '../../../../../layout/EmptyDataset';
import classNames from 'classnames';
import CarouselImagesUAV from './Weeds/CarouselImagesUAV';
import { connect } from 'react-redux';
import { getAreaFiles } from '../../../../../../actions/areas';
import Covering from './Covering/Covering';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';

class UAV extends Component {
    constructor(props) {
        super(props);
        this.state = {
          activeTab: 1,
          weeds: []
        }

        this.createWeedsObject = this.createWeedsObject.bind(this);
        this.sortWeeds         = this.sortWeeds.bind(this);  
        this.toggleTab         = this.toggleTab.bind(this);
    }

    componentDidMount(){
      this.props.getAreaFiles(this.props.field.id);
    }

    UNSAFE_componentWillReceiveProps(nextProps){
      var sortedWeedsMaterial = this.createWeedsObject(nextProps.files);
      this.setState({ weeds: sortedWeedsMaterial })
    }

    toggleTab(selectedTab) {
      this.setState({
        activeTab: selectedTab
      })
    }

    /**
     * Creates an object with all the photos
     * that carry a tag equivalent to "weed".
     * @param {*} weeds_photos 
     */

    createWeedsObject(weeds_photos){
      var weeds = [];
      if (weeds_photos.filter(photo => photo.tag == "weed").length > 0) {
        let files = weeds_photos.reduce(function (r, a) {
          r[a.sample] = r[a.sample] || [];
          r[a.sample].push(a);
          return r;
        }, Object.create(null));

        weeds = {
          samples: files
        };
      }
      weeds = this.sortWeeds(weeds);
      return weeds;
    }

    /**
     * Sorts weed samples
     * @param {*} _weeds 
     */
    sortWeeds(_weeds) {
      var weeds = _weeds;
      for (var sample in weeds["samples"]) {
        if (weeds["samples"][sample] != undefined) {
          for (var k = 0; k < weeds["samples"][sample].length - 1; k++) {
            for (var i = weeds["samples"][sample].length - 1; i > k; i--) {
              if (weeds["samples"][sample][i]["id"] < weeds["samples"][sample][i - 1]["id"]) {
                var tmp = weeds["samples"][sample][i];
                weeds["samples"][sample][i] = weeds["samples"][sample][i - 1];
                weeds["samples"][sample][i - 1] = tmp
              }
            }
          }
        }
      }
      return weeds;
    }

    render() {
      let files = this.props.files.filter(file => file.tag == "mosaic");
        const _tabs = [
          { id: 1, title: 'Weeds', component: <CarouselImagesUAV field={this.props.field} weeds={this.state.weeds} /> },
          { id: 2, title: 'Covering', component: files.length > 0? <Covering field={this.props.field} files={files}/> : <EmptyDataset message="There are no Data" icon={faDatabase}/>},
          ];

        return (
          <Row>
            <Col md={2}>
              <Nav tabs vertical className="vertical-tabs">
                {
                  _tabs.map((tab) => {
                    return (
                      <NavItem key={tab.id}>
                        <NavLink key={tab.id} 
                          className={classNames({ active: this.state.activeTab === tab.id })}
                          onClick={() => { this.toggleTab(tab.id); }}>

                        { tab.title }
                        </NavLink>
                      </NavItem>
                    )
                  })
                }
              </Nav>
            </Col>
            <Col md={10}>
              <TabContent activeTab={this.state.activeTab}>
              {   _tabs.map(tab => {
                      return(
                      <TabPane key={tab.id} tabId={tab.id}>
                          { tab.component }
                      </TabPane>
                      )
                  })
              }
              </TabContent>
            </Col>
          </Row>
        )

    }
}


const mapStateToProps = state => {
  return {
    files: state.areas.selectedField.files,
    field: state.areas.selectedField.field
  }
};

export default connect(mapStateToProps, { getAreaFiles })(UAV) ;