import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from "react-router-dom";
import App from './App';
// Load configuration
import { API_URL, ALERT_OPTIONS } from './config';
//Alerts
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import ReduxSweetAlert from 'react-redux-sweetalert';
//Redux
import { Provider } from 'react-redux';
import store from './store';
// Styles
import 'bootstrap/dist/css/bootstrap.css';
import './styles/app.scss';
import 'sweetalert/dist/sweetalert.css';

import * as serviceWorker from './serviceWorker';

window.router = require('react-router-dom');

// Initialize axios
window.axios = require('axios');
window.axios.defaults.baseURL = API_URL;
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

ReactDOM.render(
    <Provider store={store}>
        <AlertProvider template={AlertTemplate} {...ALERT_OPTIONS}>
            <BrowserRouter>
                <App />
                <ReduxSweetAlert store={store} />
            </BrowserRouter>
        </AlertProvider>
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
