import React, {Fragment, Component, createRef} from 'react';
import { saveAs } from 'file-saver';
import { Button, Row, Col, Card, CardTitle, CardBody, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faDatabase, faDownload, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import Lightbox from "react-image-lightbox";
import DataTable from 'react-data-table-component';
import EmptyDataset from '../../../../layout/EmptyDataset';
import { swal } from 'react-redux-sweetalert';
import { deleteFile, getMobileUploads } from "../../../../../actions/areas"


class MobileUploads extends Component {
    constructor(props) {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleMassDelete = this.handleMassDelete.bind(this);
        this.confirmMassDelete = this.confirmMassDelete.bind(this);
        this.state = {
            LightBoxIsOpen: false,
            image: "",
            columns: [
                  {
                    name: 'ID',
                    selector: 'id',
                    cell: row => row.id,
                    sortable: true,
                  },
                  {
                    name: 'Uploaded at',
                    selector: 'updated_at',
                    sortable: true,
                  },
                  {
                    name: 'Image',
                    cell: row => <img className="size-32 border border-secondary" src={row.thumbnail} onClick={()=>{this.togglePreview(row)}} style={{ cursor: "zoom-in" }} ></img>
                  },
                  {
                    name: 'Audio notes',
                    cell: row => 
                    row.audio != null ?
                    <div>
                        <Button onClick={() => {this.playNoteAudio(row.audio)}} style={{ cursor: "pointer" }} >
                            <FontAwesomeIcon icon={faPlay}/>
                        </Button>
                        <Button  onClick={() => {this.PauseNoteAudio()}} style={{ cursor: "pointer" }} className="ml-1">
                            <FontAwesomeIcon icon={faPause}/>
                        </Button>
                    </div> : 
                    ""
                  },
                  {
                    name: 'Actions',
                    cell: (row) => 
                        <div> 
                            <Button color="secondary" onClick={()=>{this.downloadImage(row)}} className="mr-2">
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            <Button color="danger" onClick={() => {this.handleDelete(row)}}>
                                <FontAwesomeIcon icon={faTrash} />
                            </Button>
                        </div>,
                    ignoreRowClick: true,
                    allowOverflow: true,
                    button: true,
                  }
            ],
            selectedRows: [],
            selectedRowsCleared: false,
            expandedContent: "",
            rowExpanded: false
        }

        this.tableRef = createRef();
    }

    componentDidMount(){
        this.props.getMobileUploads(this.props.field.id);
        var audio = new Audio() //initialize state Audio object 
        this.setState({audio: audio})
    }

    playNoteAudio(audio_){
        if(audio_==null) return;
        this.state.audio.pause(); //make sure all other playing audio is stopped before instantiating the clicked one
        this.state.audio = new Audio(audio_);
        this.state.audio.play();
    }

    PauseNoteAudio(){
        this.state.audio.pause()
    }

    downloadImage(imageData_){
        let field_ = this.props.field;
        
        let fieldname_  = field_.properties.name;
        let link_       = imageData_.photo;
        let uploadedAt_ = imageData_.updated_at;
        let id_         = imageData_.id;

        let filename_   = fieldname_ + "_" + uploadedAt_ + "_" + id_ + ".jpg";


        fetch(link_ + "?"+(new Date()).getTime())
            .then(res => res.blob())
            .then(blob => {
                saveAs(blob, filename_);
            });
    }

    handleChange(event){
        this.setState({selectedRows: event.selectedRows})
    }

    confirmMassDelete(){
        let field_id = this.props.field.id
        this.state.selectedRows.forEach(image => {
            this.props.deleteFile(field_id, image.id)
        });
        this.setState({selectedRows: [], selectedRowsCleared: true})
        this.setState({selectedRowsCleared: false});
    }

    handleMassDelete(){
        let swalOptions = {
			title: 'Caution',
			text: `You are about to delete one or more picture(s).\n Are you sure?`,
            type: 'warning',
            showCancelButton: true,
            onConfirm: () => {
                this.confirmMassDelete()
            }
		};
		this.props.swal(swalOptions);
    }

    handleDelete(imageData_){

        let field_id = this.props.field.id
        let swalOptions = {
			title: 'Caution',
			text: `You are about to delete picture "${imageData_.id}", created at "${imageData_.updated_at}".\n Are you sure?`,
            type: 'warning',
            showCancelButton: true,
            onConfirm: () => {
                this.props.deleteFile(field_id, imageData_.id)
            }
		};
		this.props.swal(swalOptions);
    }
    

    togglePreview(row_){
        this.setState({image: row_.thumbnail,LightBoxIsOpen: true})
    }

    render() {
        const images = this.props.files;
        const isOpen = this.state.LightBoxIsOpen;
        const ExpanableComponent = ({ data }) => 
        <Row className="mr-0">
            <Col className="pr-0 pl-5">
                <Card className="notes-card">
                    <CardTitle className="p-1 mb-0 text-center">
                        Notes
                    </CardTitle>
                    <CardBody className="text-center">
                        {data.comment}
                    </CardBody>
                </Card>
            </Col>
        </Row>

        if (images.length == 0)
            return (
            [
                <EmptyDataset key="EmptyText" message="There are no Data. Click below to refresh..." icon={faDatabase}/>,
                <div key="EmptyIcon" md="12" className="d-flex flex-column flex-grow justify-content-center align-items-center text-center">
                    <h1 className="upload-images-refresh-big" onClick={()=>{this.props.getMobileUploads(this.props.field.id);}} >
                        {"\u21BB"} 
                    </h1>
                </div>
            ])
        return (
            <Fragment>
                <Row>
                    <Button className="upload-images-refresh-small" color="primary" onClick={()=>{this.props.getMobileUploads(this.props.field.id);}}>
                        Refresh {"\u21BB"}
                    </Button>

                    <Button hidden={this.state.selectedRows.length==0} className="ml-1" color="danger" onClick={()=>{this.handleMassDelete()}}>
                        Delete ({this.state.selectedRows.length})
                    </Button>
                </Row>
                <Row>
                    <Col >
                        <DataTable
                                title="Uploads"
                                striped={true}
                                columns={this.state.columns}
                                data={images}
                                highlightOnHover
                                defaultSortField='id'
                                defaultSortAsc={true}
                                responsive={true}
                                pagination
                                selectableRows
                                expandableRows
                                expandOnRowClicked
                                expandableRowsComponent={<ExpanableComponent />}
                                expandableRowDisabled={row=> row.comment=="" || row.comment==null}
                                onSelectedRowsChange={this.handleChange}
                                clearSelectedRows={this.state.selectedRowsCleared}
                        ></DataTable>
                    </Col>
                </Row>

                {isOpen && (
                    <Lightbox id="uploads_myLightbox" style={{ zIndex: '2000' }}
                        mainSrc={this.state.image}
                        onCloseRequest={() => this.setState({ LightBoxIsOpen: false })}
                    />
                )}
            </Fragment>
        )
    };
}

const mapStateToProps = state => {
    return{
        files: state.areas.selectedField.camera
    }
}

export default connect(mapStateToProps,{swal, deleteFile, getMobileUploads})(MobileUploads);