
import { GET_TRACTORS, DELETE_TRACTOR, ADD_TRACTOR, GET_ERRORS, EDIT_TRACTOR, UPDATE_TRACTOR } from './types';
import { createMessage } from '../actions/messages';

// Get all tractors
export const getTractors = () => dispatch => {
    window.axios.get('/tractor')
        .then(res => {
            dispatch({
                type: GET_TRACTORS,
                payload: res.data
            })
        }).catch(error => console.log(error)); 
};

// Delete tractor
export const deleteTractor = (id) => dispatch => {
    window.axios.delete(`/tractor/${id}/`)
        .then(res => {
            dispatch(createMessage({ deleteTractor: "Tractor deleted." }))
            dispatch({
                type: DELETE_TRACTOR,
                payload: id
            })
        }).catch(error => console.log(error)); 
}

export const addTractor = (tractor) => dispatch => {
    window.axios.post("/tractor/",tractor)
        .then(res => {
            dispatch(createMessage({ addTractor: "Tractor added." }))
            dispatch({
                type: ADD_TRACTOR,
                payload: res.data
            })
        }).catch(error => {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
                hasErrors: true
            }
            
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        }); 
};

// Edit tractor
export const editTractor = (id) => dispatch => {
        dispatch({
            type: EDIT_TRACTOR
        })
}

// Delete tractor
export const updateTractor = (tractor) => dispatch => {
    window.axios.put(`/tractor/${tractor.id}/`,tractor)
    .then(res => {
        dispatch(createMessage({ updateTractor: "Tractor updated." }))
        dispatch({
            type: UPDATE_TRACTOR,
            payload: res.data
        })
    }).catch(error => {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
            hasErrors: true
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    });
}