import React, { Component } from "react";
import Chart from "chart.js"
import { importArrows } from '../../../../helpers/assets';
import { connect } from 'react-redux';
import DataProviderCredits from "./WeatherDataProviders"

const WindArrows = importArrows(require.context("../../../../../assets/arrows/", true, /\.svg$/));

class WindChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      field: [],
    };

    this.parseWinds = this.parseWinds.bind(this);
    this.parseWindArrows = this.parseWindArrows.bind(this);

    this.renderWindCharts = this.renderWindCharts.bind(this);
  }

  componentDidMount() {
    this.setState({ field: this.props.field });
  }

  parseWinds() {
    var forecast = this.state.field.OpenWeather.forecast.list;
    var winds = {};
    winds.degree = [];
    winds.speed = [];
    winds.time = [];
    winds.day_change = [];
    winds.orientation = [];
    for (var key in forecast) {
      var _d = forecast[key].wind.deg;
      var orientation;

      if (_d > 0 && _d <= 90)
        if (_d <= 22.5)
          orientation = "North [N]";
        else if (_d >= 67.5)
          orientation = "East [E]";
        else
          orientation = "North East [NE]";

      if (_d > 90 && _d <= 180)
        if (_d <= 112.5)
          orientation = "East [E]";
        else if (_d >= 157.5)
          orientation = "South [S]";
        else
          orientation = "South East [SE]";

      if (_d > 180 && _d <= 270)
        if (_d <= 202.5)
          orientation = "South [S]";
        else if (_d >= 247.5)
          orientation = "West [W]"
        else
          orientation = "South West [SW]";

      if (_d > 270 && _d <= 360)
        if (_d >= 337)
          orientation = "North [N]"
        else if (_d <= 292.5)
          orientation = "West [W]"
        else
          orientation = "North West [NW]"

      winds["speed"].push(forecast[key].wind.speed);
      winds["orientation"].push(orientation);

      var date = forecast[key].dt_txt.toString().split(' ')[0].slice(5, 10).split('-');
      var time = forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5);

      if (time == "00:00") {
        winds["time"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
        winds["day_change"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
      }
      else
        winds["time"].push(forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5));

      if (key == 16)
        break;
    }

    winds.color = 'rgba(38, 136, 255, ';
    return winds;
  }

  parseWindArrows(WindOrigins) {
    var images = [];

    let orientations = WindOrigins.orientation.filter(orientation => orientation !== undefined);
    orientations.forEach(origin => {
      let img = new Image();
      var pure_origin = origin.split('[')[1].replace(']', '');
      img.src = WindArrows[pure_origin].toString();
      images.push(img);
    });

    return images;
  }

  renderWindCharts() {
    var myCanvas = document.getElementById('WindChart').getContext('2d');
    var WindsForecast = this.parseWinds();
    var directionImages = this.parseWindArrows(WindsForecast);
    var annotations = WindsForecast.day_change.map(function (date, index) {
      return {
        type: 'line',
        id: 'vline' + index,
        mode: 'vertical',
        scaleID: 'x-axis-0',
        value: date,
        borderColor: 'black',
        borderWidth: 0.5,
        borderDash: [5, 5]
      }
    });

    var mytooltip = {
      callbacks: {
        title: function (tooltipItem) {
          return "Orientation: " + WindsForecast["orientation"][tooltipItem[0]['index']];
        }
      }
    }

    new Chart(myCanvas, {
      type: 'line',
      data: {
        labels: WindsForecast["time"],
        datasets: [
          {
            label: 'Wind speed (km/h)',
            data: WindsForecast["speed"],
            borderWidth: 1,
            fill: false,
            backgroundColor: WindsForecast.color + " 0.2)",
            borderColor: WindsForecast.color + " 1)",
            pointStyle: directionImages
          }
        ]
      },
      options: {
        // responsive: false,
        maintainAspectRatio: false,
        title: {
          display: true,
          text: "Wind Forecast"
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            }
          }]
        },
        annotation: {
          annotations: annotations
        },
        tooltips: mytooltip
      }
    });
  }

  render() {
    if (this.props.fetched && document.getElementById('WindChart') != null) this.renderWindCharts();

    return (
      <div>
        <canvas className="weather-chart" id={this.props.title.replace(' ', '') + "Chart"}></canvas>
        <DataProviderCredits DataType="Weather" DataSource={this.props.DataSource} hidden={!this.props.fetched}></DataProviderCredits>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let fetched = false;

  if (Object.keys(state.areas.selectedField.field.OpenWeather.forecast).length != 0) fetched = true;
  return { field: state.areas.selectedField.field, fetched: fetched }
}

export default connect(mapStateToProps)(WindChart)