import React, { Component } from "react";
import { PropTypes } from "prop-types";
import {
  Row,
  Col,
  CardTitle,
  Card,
  CardBody,
  Button,
  Label,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FieldCard from "../Modal FieldCard/FieldCard";
import { formatArea } from "../../../helpers/formaters";
import { zoomToField } from "../../../helpers/geo";
import { connect } from "react-redux";
import {
  deleteArea,
  setSelectedField,
  saveMarkers,
} from "../../../../actions/areas";
import { withRouter } from "react-router-dom";
import Authorize from "../../../auth/common/Authorize";
import { Role } from "../../../auth/common/Role";
import { swal, close } from "react-redux-sweetalert";

class FieldCardListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClickedFieldModal: false,
      showDropDownItem: false,
    };
    this.handleAnimation = this.handleAnimation.bind(this);
    this.handleOut = this.handleOut.bind(this);
  }

  static propTypes = {
    close: PropTypes.func.isRequired,
    swal: PropTypes.func.isRequired,
  };

  toggleClickedFieldModal = () => {
    Object.keys(this.props.markers).forEach((markerID) => {
      this.props.markers[markerID].setAnimation(null);
    });
    this.setState((prevState) => ({
      showClickedFieldModal: !prevState.showClickedFieldModal,
    }));
  };

  toggleDropDownMenu = (e) => {
    this.setState((prevState) => ({
      showDropDownItem: !prevState.showDropDownItem,
    }));
  };

  removePolygonWithMarker = (field, map, markers) => {
    markers[field.id].setMap(null);
    delete markers[field.id];
    var mymap = map;
    this.props.saveMarkers(markers);

    mymap.data.forEach(function (feature) {
      if (feature.o == field.id) {
        mymap.data.remove(feature);
      }
    });
    map.data.setMap(map);
  };

  handleDelete = () => {
    let { markers, map, field, deleteArea } = this.props;

    let swalOptions = {
      title: "Caution",
      text: `You are about to delete field with name "${field.properties.name}".\n Are you sure?`,
      type: "warning",
      showCancelButton: true,
      onConfirm: () => {
        this.removePolygonWithMarker(field, map, markers);
        deleteArea(field);
        this.props.close();
      },
    };
    this.props.swal(swalOptions);
  };

  handleAnimation() {
    this.props.markers[this.props.field.id].setAnimation(
      window.google.maps.Animation.BOUNCE
    );
  }

  handleOut() {
    this.props.markers[this.props.field.id].setAnimation(null);
  }

  render() {
    const { map, field } = this.props;
    const { name, area, crop } = field.properties;

    return (
      <Card
        className="field-siderbar-list-item my-2"
        onMouseOver={() => {
          this.handleAnimation();
        }}
        onMouseLeave={() => {
          this.handleOut();
        }}
      >
        <CardTitle className="card-title bg-primary text-left px-3 py-2 text-light mb-0 d-flex justify-content-between align-items-center">
          <span>{name}</span>
          <div>
            <ButtonDropdown
              isOpen={this.state.showDropDownItem}
              toggle={this.toggleDropDownMenu}
            >
              <Button
                id="caret"
                color="secondary"
                onClick={() => {
                  this.props.setSelectedField(field);
                  this.toggleClickedFieldModal();
                }}
              >
                <FontAwesomeIcon className="size-16" icon={faEye} />
                <span className="ml-2">View</span>
              </Button>
              <DropdownToggle caret color="secondary" />
              <DropdownMenu className="action-bar">
                <DropdownItem
                  className="btn btn-danger btn-md"
                  onClick={() => {
                    this.handleDelete();
                  }}
                >
                  <FontAwesomeIcon className="size-16" icon={faTrash} />
                  <span className="ml-2">Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
        </CardTitle>
        <CardBody onClick={() => zoomToField(field, map)} className="p-3">
          <Row>
            <Col>
              <Label className="d-block">
                Area:
                <span className="d-block font-weight-bold">
                  {formatArea(area)} m<sup>2</sup>
                </span>
              </Label>
            </Col>
            <Col>
              <Label className="d-block">
                Crop:
                <span className="d-block font-weight-bold">
                  {crop === null || crop === "Free" ? "-" : crop}
                </span>
              </Label>
            </Col>
          </Row>
        </CardBody>
        {this.state.showClickedFieldModal ? (
          <FieldCard
            toggleModal={this.state.showClickedFieldModal}
            _externalToggle={this.toggleClickedFieldModal}
          ></FieldCard>
        ) : null}
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    markers: state.areas.markers,
    auth: state.auth,
  };
};

export default connect(null, {
  deleteArea,
  swal,
  close,
  setSelectedField,
  saveMarkers,
})(withRouter(FieldCardListItem));
