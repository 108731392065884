import React, { Component, Fragment } from "react";
import { Button } from "reactstrap"
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { getPolygonFeatures } from "../../../../helpers/geo";
import {
  formatAreaToInt,
  formatArea,
  formatName,
} from "../../../../helpers/formaters";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

class CropsAllocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chart: [],
    };
  }

  parseFields(fields_) {
    let totalInformation = {};
    totalInformation["total_area"] = 0;
    totalInformation["crops_area"] = {};
    totalInformation["crops_colors"] = {};

    getPolygonFeatures(fields_).forEach((field) => {
      totalInformation["total_area"] += field.properties.area;

      if (totalInformation["crops_area"][field.properties.crop] == undefined)
        if (
          field.properties.crop == null &&
          totalInformation["crops_area"]["Free"] == undefined
        )
          totalInformation["crops_area"]["Free"] = 0;
        else if (field.properties.crop != null)
          totalInformation["crops_area"][field.properties.crop] = 0;

      if (totalInformation["crops_colors"][field.properties.crop] == undefined)
        if (field.properties.crop == null || field.properties.crop == "Free")
          totalInformation["crops_colors"]["Free"] = "#000000";
        else
          totalInformation["crops_colors"][field.properties.crop] =
            field.properties.color;

      totalInformation["crops_area"][
        field.properties.crop == null ? "Free" : field.properties.crop
      ] += field.properties.area;
    });
    return totalInformation;
  }

  componentDidMount(){
    let chart_options = this.createParentHighChartsOptions(
      this.parseFields(this.props.fields)
    );
    this.setState({
      chart: [
        <HighchartsReact key="ParentCropsllocationChart" highcharts={Highcharts} options={chart_options} />,
      ],
    });
  }

  onSliceClick(e, _this) {
    let slice_crop = e.point.name.split(":")[0];
    let members = _this.props.members;
    let fields = _this.props.fields;

    let cropArea_per_team = _this.getSelectedCropByTeam(
      slice_crop,
      members,
      fields
    );

    let child_chart_options = _this.createChildHighChartsOptions(
      cropArea_per_team,
      slice_crop
    );

    let child_chart = _this.state.chart;
    if (child_chart.length == 2) child_chart.splice(1, 1);
    child_chart.push(
      <div key="ChildCropsllocationChart">
        <HighchartsReact highcharts={Highcharts} options={child_chart_options} />
        <Button onClick={() => { _this.collapseCharts(_this); }}>
          <FontAwesomeIcon icon={faArrowUp} />
        </Button>
      </div>
    );

    _this.setState({
      chart: child_chart,
    });
  }

  collapseCharts(_this) {
    let charts = _this.state.chart;
    charts.splice(1, 1);
    _this.setState({ chart: charts })
  }

  getSelectedCropByTeam(crop_, members_, fields_) {
    let crop_per_teams = {};
    crop_per_teams["total_area"] = 0;
    crop_per_teams["teams"] = {};
    members_.forEach((member) => {
      if (crop_per_teams[member.team] == undefined)
        crop_per_teams["teams"][member.team] = 0;
    });

    if (crop_ == "Free") crop_ = null;
    getPolygonFeatures(fields_).forEach((field) => {
      if (field.properties.crop == crop_) {
        let team_that_has_this_crop = members_.filter(
          (member) => member.user == field.properties.owner
        )[0].team;

        let area_in_hectares = field.properties.area;

        crop_per_teams["total_area"] += area_in_hectares;
        crop_per_teams["teams"][team_that_has_this_crop] += area_in_hectares;
      }
    });
    return crop_per_teams;
  }

  createChildHighChartsOptions(info_, crop_) {
    let options_ = {
      chart: {
        backgroundColor: "transparent",
        borderColor: "#444",
        shadow: false,
      },
      title: {
        text: formatName(crop_) + " by team",
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Area (%)",
          data: [
            Object.keys(info_["teams"]).map((key) => {
              return {
                name:
                  key + ": " + formatArea(info_["teams"][key], "ha") + " ha",
                y: parseFloat(
                  (
                    (formatAreaToInt(info_["teams"][key]) * 100) /
                    formatAreaToInt(info_["total_area"])
                  ).toFixed(2)
                ),
              };
            }),
          ][0],
          type: "pie",
          cursor: "default",
        },
      ],
    };
    return options_;
  }

  createParentHighChartsOptions(info_) {
    let onSliceClick = this.onSliceClick;
    let _this = this;
    let options_ = {
      chart: {
        backgroundColor: "transparent",
        borderColor: "#444",
        shadow: false,
      },
      title: null,
      credits: {
        enabled: false
      },
      series: [
        {
          name: "Area (%)",
          data: [
            Object.keys(info_["crops_area"]).map((key) => {
              return {
                name:
                  key +
                  ": " +
                  formatArea(info_["crops_area"][key], "ha") +
                  " ha",
                color: info_["crops_colors"][key],
                y: parseFloat(
                  (
                    (formatAreaToInt(info_["crops_area"][key]) * 100) /
                    formatAreaToInt(info_["total_area"])
                  ).toFixed(2)
                ),
              };
            }),
          ][0],
          type: "pie",
          cursor: "pointer",
          events: {
            click: function (e) {
              onSliceClick(e, _this);
            },
          },
        },
      ],
    };
    return options_;
  }

  render() {
    return this.state.chart;
  }
}

export default CropsAllocation;
