import React, { Fragment, Component } from 'react';
import { withAlert } from 'react-alert';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';

class Alerts extends Component {

    static propTypes = {
        error: PropTypes.object.isRequired,
        message: PropTypes.object.isRequired
    }

    componentDidUpdate(prevProps){
        
        const { error, alert, message } = this.props;
        
        if (error !== prevProps.error && error.status == 401) {
            alert.error(error.msg.detail);
            return;
        }

        if(error !== prevProps.error && error.hasErrors){
            alert.error("Please fix all validation errors.");
            return;
        }

        if(message !== prevProps.message){
            for (let key in message){
                alert.success(`${message[key]}`);
                return;
            }
        }
    }

    render() { 
        return <Fragment />;
    }
}

const mapStateToProps = state => ({
    error: state.errors,
    message: state.messages
});

export default connect(mapStateToProps) (withAlert()(Alerts));