import React, { Component } from "react";
import { Form, FormGroup, Row, Col, Button, Input, Label } from "reactstrap";
import Map from "../../../../../components/partials/Map";
import { connect } from "react-redux";
import {
  callPointGenerator,
  getSavedPoints,
} from "../../../../../actions/areas";
import EmptyDataset from "../../../../layout/EmptyDataset";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { LoadingSpinerComponent } from "../../../../partials/LoadingSpiner"

class PointGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampling: [],
      samples: null,
      lifecycle: 0,
    };
    this.handleSelection = this.handleSelection.bind(this);
  }

  componentWillUnmount() {
    this.setState({ sampling: [] });
  }

  componentDidMount() {
    this.props.getSavedPoints(this.props.selectedField.field.id);
    this.setState({ sampling: [], lifecycle: this.state.lifecycle + 1 });
  }

  handleSelection(event) {
    let value = event.target.value;
    this.setState({ [event.target.name]: value });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.selectedField.field.sampling == undefined) return null;

    if (nextProps != prevState) {
      let data_ = nextProps.selectedField.field.sampling.properties.kml;
      return { sampling: data_ };
    } else return null;
  }

  generatePoints() {
    let id = this.props.selectedField.field.id;
    let request_body = { samples: this.state.samples };
    this.setState({ lifecycle: 2 })
    this.props.callPointGenerator(id, request_body);
  }

  render() {
    return (
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          this.generatePoints();
        }}
      >
        <FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Col xs={6} sm={6} md={6} lg={4}>
                  <Label>Number of samples</Label>
                  <Col>
                    <Row>
                      <Col>
                        <Input
                          type="number"
                          required
                          min={1}
                          placeholder="e.g 50"
                          onChange={this.handleSelection}
                          name="samples"
                          id="samples"
                        ></Input>
                      </Col>
                      <Col xs={3} sm={3} md={3}>
                        <Button type="submit">Generate</Button>
                      </Col>
                      {this.state.sampling.length != 0 && this.state.lifecycle <= 1?
                      <Col xs={3} sm={3} md={3}>
                        <Button onClick={()=>{ this.setState({ lifecycle: this.state.lifecycle+1 })} }>Show</Button>
                      </Col> : null
                      }
                    </Row>
                  </Col>
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <FormGroup>
            <Col md={12}>
              <LoadingSpinerComponent area={"callPointGenerator-area"}>
                {this.state.sampling.length == 0 || this.state.lifecycle <= 1 ? (
                  this.state.sampling.length != 0 ?
                    <EmptyDataset
                      message='Please enter number of samples and click "Generate" or Click "Show".'
                      icon={faInfoCircle}
                    /> :
                    <EmptyDataset
                      message='Please enter number of samples and click "Generate".'
                      icon={faInfoCircle}
                    />
                ) : (
                    <Map
                      el={"pointGenerator-map-" + this.props.selectedField.field.id}
                      height="100%"
                      file={this.state.sampling}
                    />
                  )}
              </LoadingSpinerComponent>
            </Col>
          </FormGroup>
        </FormGroup>
      </Form>
    );
  }
}

export default connect(null, { callPointGenerator, getSavedPoints })(
  PointGenerator
);
