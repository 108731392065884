import React, { Component } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';

class SearchBar extends Component {
    state = { 
        search_term: "",
        currentList: [],
    }
    
    onChange = e => {
        this.setState({[e.target.name] : e.target.value})
    }

    componentDidMount() {
        this.setState({
            currentList: this.props.items
        });
      }

    UNSAFE_componentWillReceiveProps(nextProps){
        this.setState({
            currentList: nextProps.items
        })
    }

    onSubmit = e => {
        e.preventDefault();
    }

    search = e => {

        // if pressed ESC then clear search
        if (e.keyCode === 27) {
            this.clearSearch();
        }
        // Variable to hold the original version of the list
        let currentList = [];
        // Variable to hold the filtered list before putting into state
        let results = [];
        
        let { search_term } = this.state;
            
        // If the search bar isn't empty
        if (search_term !== "") {
            // Assign the original list to currentList
            currentList = this.state.currentList;
        
        // Filter based on search term
        results = currentList.filter(item => {
            const list_item = item.properties.name.toLowerCase();
            const filter = search_term.toLowerCase();
            // check to see if the current list item includes the search term
            // If it does, it will be added to results.
            return list_item.includes(filter);
        });
        } else {
            // If the search bar is empty, set results to original list
            results = this.state.currentList;
        }
        this.props.onFilter(results);
    }

    clearSearch = e => {
        this.setState( { search_term: ""});
        this.props.onFilter(this.state.currentList);
    }

    render() { 
        const { search_term }= this.state;

        return ( 
            <Form onSubmit={this.onSubmit}>
                <FormGroup>
                    <div className="position-relative">
                        <Input 
                            className="search-bar"
                            type="text"
                            name="search_term"
                            value={search_term}
                            onChange={this.onChange}
                            onKeyUp={this.search}
                            onKeyDown={this.search}
                            placeholder={this.props.placeholderText}
                        />
                        { search_term.length > 0 
                            ? <FontAwesomeIcon icon={faTimes} className="search-icon clear-search" onClick={this.clearSearch} />
                            : <FontAwesomeIcon icon={faSearch} className="search-icon search-btn" />
                        }
                    </div>
                </FormGroup>
            </Form>
         );
    }
}
 
export default SearchBar;