import { saveAs } from 'file-saver';

/**
 * Returns KML representation of a MapField geographic area
 * @param {*} _Field 
 */
export const getKML = (_Field) => {

        var _coordinates = "", _fillColor, _outLineColor;
        _outLineColor = _fillColor = _Field.properties.color;
    
        _fillColor = "#80" + (_fillColor != null ? _fillColor.slice(5, 7) + _fillColor.slice(3, 5) + _fillColor.slice(1, 3) : "000000");
        _outLineColor = _fillColor.replace("#60", "#ff");
    
        _Field.geometry.coordinates[0].forEach(
          PairOfCoordinates =>
            (_coordinates +=
              PairOfCoordinates[0] + "," + PairOfCoordinates[1] + ",17 ")
        );
    
        var _kml = [];
        _kml.push('<?xml version="1.0" encoding="UTF-8"?>');
        _kml.push('<kml xmlns="http://earth.google.com/kml/2.0"> <Document>');
        _kml.push("<Placemark> ");
        _kml.push("<name>Field Name: " + _Field.properties.name + "</name>");
        _kml.push(
          "<description>" +
          "Crop type: " +
          _Field.properties.crop +
          "\nField's area: " +
          this.props.size +
          "\nField Description: " +
          _Field.properties.description +
          "</description>"
        );
        _kml.push("<Polygon> <outerBoundaryIs>  <LinearRing>  ");
        _kml.push("<coordinates>" + _coordinates + "</coordinates>");
        _kml.push("</LinearRing> </outerBoundaryIs> </Polygon>");
        _kml.push("<Style>");
        _kml.push("<PolyStyle>");
        _kml.push("<color>" + _fillColor + "</color>");
        _kml.push("</PolyStyle>");
        _kml.push("<LineStyle>");
        _kml.push("<color>" + _outLineColor + "</color>");
        _kml.push("<width>2</width>");
        _kml.push("</LineStyle>");
        _kml.push("</Style>");
        _kml.push("</Placemark>");
        _kml.push("</Document> </kml>");
        var kmlCode = "";
        _kml.forEach(element => {
          kmlCode += element + "\n";
        });
    
        saveAs(new Blob([kmlCode], { type: "text/plain;charset=utf-8;" }), _Field.properties.name + ".kml");
}

/**
 * Returns the field's static path as string. Specifically:
 * 1) polygon's bounds color and width.
 * 2) polygon's inside color
 * 3) polygon's perimeter as path
 * @param {object} _Field Field's syntax as polygon feature with "geometry", "properties" and other relative properties.
 */
export const getStaticPath = (_Field) => {
  var _path = "";
  var color = "color:" + (_Field.properties.color != null ? _Field.properties.color.toString().toUpperCase().replace("#", "0x") + "FF|" : "0x000000FF|");
  var weight = "weight:" + 1 + "|";
  var fillColor = "fillcolor:" + (_Field.properties.color != null ? _Field.properties.color.toString().toUpperCase().replace("#", "0x") + "AF|" : "0x000000AA|");
  _Field.geometry.coordinates[0].forEach(element => {
      _path += element[1] + "," + element[0] + "|";
  });
  return color + weight + fillColor + _path.slice(0, _path.length - 1);
}

/**
 * Returns the google's static API link for a given polygon's image.
 * @param {*} _Field Field's syntax as polygon feature with "geometry", "properties" and other relative properties.
 */
export const getScreenShotLink = (_Field) => {
  let center = getBounds(_Field).getCenter();
  return process.env.REACT_APP_GMAP_SS_LINK +"staticmap?center=" + center.lat() + "," + center.lng() +
         "&path=" + getStaticPath(_Field) + "&scale=2&maptype=satellite&zoom=14&size=150x150&" + process.env.REACT_APP_GMAP_KEY
}

/**
 * Parses the Redux fieldsFullData object and returns it as an array of features.
 * @param {*} _Fields Fields object syntax must be as follows: 
 * @obj fieldsFullData{
 * @field1 field.id : {field1 object}
 * @field2 field.id : {field2 object}
 * @field3 field.id : {field3 object}
 * @field4 field.id : {field4 object}
 * @obj }
 */
export const getPolygonFeatures = (_Fields) => {
  return Object.keys(_Fields).map((field) => { return _Fields[field] });
}

/**
 * Zoom in to the given field on the related map
 * @param {*} _Field The field oject to zoom in
 * @param {*} map The related map that is visualizing the field
 */
export const zoomToField = (_Field, map) => {
  let bounds = getBounds(_Field);
  let center = bounds.getCenter();
  
  map.setCenter(center);
  map.fitBounds(bounds,120);
}


export const convertCoordinatestoWKT = array_of_coordinates => {
  let coords = array_of_coordinates;
  let wkt = "POLYGON((";
  
  coords.forEach(set => {
    wkt += set[0] + " " + set[1] + ","
  });

  wkt += coords[0][0] + " " + coords[0][1]
  wkt = wkt.substring(0, wkt.length) + "))";
  return wkt;
}

export const convertPolygonToWKT = (poly,mode) => {
  // Start the Polygon Well Known Text (WKT) expression
  var wkt = "POLYGON(";

  var paths = poly.getPaths();
  for (var i = 0; i < paths.getLength(); i++) {
    var path = paths.getAt(i);

    wkt += "(";
    for (var j = 0; j < path.getLength(); j++) {
      wkt += path.getAt(j).lng().toString() + " " + path.getAt(j).lat().toString() + ",";
    }
    wkt += path.getAt(0).lng().toString() + " " + path.getAt(0).lat().toString() + "),";
  }

  // resolve the last trailing "," and close the Polygon
  wkt = wkt.substring(0, wkt.length - 1) + ")";
  
  if(mode == "save") return wkt //default behavior while saving 

  //behavior while editting
  let wkt_temp = wkt.replace("POLYGON((",'').replace("))",'');
  wkt_temp = wkt_temp.split(',');
  if(wkt_temp[0] == wkt_temp[wkt_temp.length-1])
  {
    if(mode!="edit") wkt_temp.pop();
    return "POLYGON((" + wkt_temp.filter(element=>element==element)+"))";
  }
  else return wkt;
}

export const convertWKTtoPolygon = (wkt) => {
  let polygon = {
    type: "Polygon",
    coordinates: []
  };

  let coordinates = [];
  let split_wkt_coords = wkt.replace("POLYGON((",'').replace("))",'').split(',');
  split_wkt_coords.forEach(coords_set => {
    let lat_lng = coords_set.split(' ');
    let coords = [Number(lat_lng[0]),Number(lat_lng[1])];
    coordinates.push(coords);
  });

  polygon.coordinates.push(coordinates);
  return polygon;
}
/**
 * Returns the bounds of a given Field feature.
 * @param {*} _Field Field's syntax as polygon feature with "geometry", "properties" and other relative properties. 
 * @description User can call getCenter() of the returned object to retrieve the center of the field. 
 * Lat & lng values are also accessible through .getCenter().lat() and .getCenter().lng() respectively.
 */
export const getBounds = (_Field) => {
  
  let fieldPolygon = _Field, coordArray = _Field.geometry.coordinates.toString().split(","), lngLatArray = [];
      new window.google.maps.LatLngBounds();
      for (var i = 0; i < coordArray.length; i += 2) {
        lngLatArray[i / 2] = new window.google.maps.LatLng(
          coordArray[i + 1], coordArray[i]
        );
      }

      fieldPolygon = new window.google.maps.Polygon({
        paths: lngLatArray
      });

  window.google.maps.Polygon.prototype.my_getBounds = function () {
    var bounds = new window.google.maps.LatLngBounds();
    this.getPath().forEach(function (element, index) { bounds.extend(element); });
    return bounds;
  };

  return fieldPolygon.my_getBounds(); //center can be accessed through the .getCenter() function of the returned object
}