import React, { Fragment, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane, Button } from "reactstrap";
import classnames from "classnames";
import Clients from "./Clients";
import Invitations from "./Invitations";
import { withRouter } from "react-router-dom";

const Members = props => {
  const [activeTab, toggle] = useState("1");

  return (
    <Fragment>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Clients
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Invitations
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Clients {...props} />
        </TabPane>
        <TabPane tabId="2">
          <Invitations {...props} />
        </TabPane>
      </TabContent>
    </Fragment>
  );
};

export default withRouter(Members);
