import {
  GET_AREAS,
  GET_AREA_FILES,
  GET_MOBILE_UPLOADS,
  GET_AREA_TYPES,
  GET_AREA_SOIL_TYPES,
  GET_LIVE_METEO_FORECAST,
  GET_CUSTOM_DATE_METEO,
  GET_PRECIPITATION,
  GET_CLOUD_AND_SOLAR_RADIATION,
  GET_HEAT_HUMIDITY_PRESSURE_WIND,
  ADD_AREA,
  GET_ERRORS,
  DELETE_AREA,
  DELETE_FILE,
  UPDATE_AREA,
  SET_SELECTED_FIELD,
  SAVE_MARKERS,
  GET_AREA_HEATMAP,
  ADD_CULTIVATION,
  GENERATE_POINTS,
  GET_SAVED_POINTS,
  GET_SCHEDULER_EVENTS,
  CLEAR_REDUX_EVENTS,
  ADD_SCHEDULER_EVENT,
  EDIT_SCHEDULER_EVENT,
  DELETE_SCHEDULER_EVENT,
  SAVE_TRANSLATED_COORDINATES,
  CLEAR_TRANSLATED_COORDINATES,
} from "./types";
import { createMessage } from "../actions/messages";
import { HmacSHA256 } from "crypto-js";
import { trackPromise } from "react-promise-tracker";

// Get all fields
export const getAreas = () => (dispatch) => {
  trackPromise(
    window.axios
      .get("/area")
      .then((res) => {
        dispatch({
          type: GET_AREAS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }),
    "getAreas-area"
  );
};

export const setSelectedField = (field) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_FIELD,
    payload: field,
  });
};

export const getLiveMeteoForecast = (stationID) => (dispatch) => {
  const public_key = process.env.REACT_APP_FIELDCLIMATE_CLIENT_ID;
  const private_key = process.env.REACT_APP_FIELDCLIMATE_CLIENT_SECRET;
  let date = new Date().toUTCString();
  let sign = HmacSHA256(
    "GET" +
      "/chart/highchart/" +
      stationID +
      "/hourly/last/24h" +
      date +
      public_key,
    private_key
  );
  trackPromise(
    window.axios
      .get(
        `https://api.fieldclimate.com/v1/chart/highchart/${stationID}/hourly/last/24h`,
        {
          headers: {
            "Request-Date": date,
            Authorization: "hmac " + public_key + ":" + sign,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: GET_LIVE_METEO_FORECAST,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }),
    "getCustomDateMeteo-area"
  );
};

export const getCustomDateMeteo = (
  stationID,
  start,
  end,
  interval = "hourly"
) => (dispatch) => {
  const public_key = process.env.REACT_APP_FIELDCLIMATE_CLIENT_ID;
  const private_key = process.env.REACT_APP_FIELDCLIMATE_CLIENT_SECRET;
  let date = new Date().toUTCString();
  let period;

  period = `/${interval}/from/${start}/to/${end}`;
  let sign = HmacSHA256(
    "GET" + "/chart/highchart/" + stationID + period + date + public_key,
    private_key
  );

  trackPromise(
    window.axios
      .get(
        `https://api.fieldclimate.com/v1/chart/highchart/${stationID}${period}`,
        {
          headers: {
            "Request-Date": date,
            Authorization: "hmac " + public_key + ":" + sign,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: GET_CUSTOM_DATE_METEO,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }),
    "getCustomDateMeteo-area"
  );
};

export const get_precipitation = (field) => (dispatch) => {
  //DarkSky Forecast (includes Precipitation - we only use this data from this source)

  var lon = field.geometry.coordinates[0][0][0];
  var lat = field.geometry.coordinates[0][0][1];
  let key = process.env.REACT_APP_DARKSKY_KEY;
  trackPromise(
    window.axios
      .get(
        "https://cors-anywhere.herokuapp.com/https://api.darksky.net/forecast/" +
          key +
          "/" +
          lat +
          "," +
          lon +
          "?units=si"
      )
      .then((res) => {
        dispatch({
          type: GET_PRECIPITATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      })
  );
};

export const get_cloud_and_solar_radiation = (field) => (dispatch) => {
  var lon = field.geometry.coordinates[0][0][0];
  var lat = field.geometry.coordinates[0][0][1];
  let key = process.env.REACT_APP_WEATHERBIT_KEY;
  window.axios
    .get(
      "https://api.weatherbit.io/v2.0/forecast/hourly?lat=" +
        lat +
        "&lon=" +
        lon +
        "&units=S&lang=uk&key=" +
        key
    )
    .then((res) => {
      dispatch({
        type: GET_CLOUD_AND_SOLAR_RADIATION,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const get_heat_wind_humidity_pressure = (field) => (dispatch) => {
  var lon = field.geometry.coordinates[0][0][0];
  var lat = field.geometry.coordinates[0][0][1];
  let key = process.env.REACT_APP_OPENWEATHERMAP_KEY;
  trackPromise(
    window.axios
      .get(
        "https://cors-anywhere.herokuapp.com/https://api.openweathermap.org/data/2.5/forecast?lat=" +
          lat +
          "&lon=" +
          lon +
          "&units=metric&APPID=" +
          key
      )
      .then((res) => {
        dispatch({
          type: GET_HEAT_HUMIDITY_PRESSURE_WIND,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }),
    "get_heat_wind_humidity_pressure-area"
  );
};

export const getAreaTypes = () => (dispatch) => {
  window.axios
    .get("/area_type")
    .then((res) => {
      dispatch({
        type: GET_AREA_TYPES,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAreaSoilTypes = () => (dispatch) => {
  window.axios
    .get("/soil_type")
    .then((res) => {
      dispatch({
        type: GET_AREA_SOIL_TYPES,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getAreaHeatmap = (id, filters = "/?type=") => (dispatch) => {
  window.axios
    .get(`/area/${id}/heatmap${filters}`)
    .then((res) => {
      dispatch({
        type: GET_AREA_HEATMAP,
        payload: {
          indices: res.data,
          filters: filters,
        },
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const generateAreaHeatmapManually = (fieldID) => (dispatch) => {
  window.axios
    .post(`/remote-sensing/manual/generate`, {
      field: fieldID,
    })
    .then((res) => {
      dispatch(
        createMessage({
          generateHeatmaps:
            "You manually triggered a remote sensing process. Please wait until results are available.",
        })
      );
    })
    .catch((error) => {
      console.log(error);
    });
};

// Get all files from a selected field
export const getAreaFiles = (id) => (dispatch) => {
  window.axios
    .get(`/area/${id}/file`)
    .then((res) => {
      dispatch({
        type: GET_AREA_FILES,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

// Get camera uploads from a selected field
export const getMobileUploads = (id) => (dispatch) => {
  window.axios
    .get(`/area/${id}/camera`)
    .then((res) => {
      dispatch({
        type: GET_MOBILE_UPLOADS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const callPointGenerator = (id, samples) => (dispatch) => {
  trackPromise(
    window.axios
      .post(`/area/${id}/sampling/`, samples)
      .then((res) => {
        dispatch({
          type: GENERATE_POINTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }),
    "callPointGenerator-area"
  );
};

export const getSavedPoints = (id) => (dispatch) => {
  window.axios
    .get(`/area/${id}/sample`)
    .then((res) => {
      dispatch({
        type: GET_SAVED_POINTS,
        payload: res.data,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const getFieldEvents = (id) => (dispatch) => {
  trackPromise(
    window.axios
      .get(`/area/${id}/event`)
      .then((res) => {
        dispatch({
          type: GET_SCHEDULER_EVENTS,
          payload: res.data,
        });
      })
      .catch((error) => {
        console.log(error);
      }), "Calendar-area");
};

export const addFieldEvent = (id, added_event, type) => (dispatch) => {

  let event_to_post = {};
  event_to_post["title"] = added_event["title"];
  event_to_post["start_date"] = added_event["startDate"]
  event_to_post["stop_date"] = added_event["endDate"];
  event_to_post["is_all_day"] = added_event["allDay"];
  event_to_post["type"] = type;
  event_to_post["field"] = id

  window.axios
    .post(`/area/${id}/event/`, event_to_post)
    .then((res) => {
      dispatch({
        type: ADD_SCHEDULER_EVENT,
        payload: { added_event, id: res.data.id, type: type },
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const deleteFieldEvent = (id,event_id) => (dispatch) => {
  window.axios
    .delete(`/area/${id}/event/${event_id}/`)
    .then((res) => {
      dispatch({
        type: DELETE_SCHEDULER_EVENT,
        payload: event_id,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const editFieldEvent = (id, changed_event, type) => (dispatch) => {
  let changed_event_id = Object.keys(changed_event)[0];
  let event_to_post = changed_event[changed_event_id];
  event_to_post["type"] = type

  window.axios
    .patch(`/area/${id}/event/${changed_event_id}/`, event_to_post)
    .then((res) => {
      dispatch({
        type: EDIT_SCHEDULER_EVENT,
        payload: { id, event_changes: changed_event, type: type }
      });
    })
    .catch((error) => {
      console.log(error);
    });
  
}

export const clearReduxEvents = () => (dispatch) =>{
  dispatch({
    type: CLEAR_REDUX_EVENTS,
  })
}

//Delete specific file for specific field
export const deleteFile = (field_id, file_id) => (dispatch) => {
  window.axios
    .delete(`/area/${field_id}/camera/${file_id}/`)
    .then((res) => {
      dispatch({
        type: DELETE_FILE,
        payload: file_id,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const addArea = (field) => (dispatch) => {
  window.axios
    .post(`/area/`, field)
    .then((res) => {
      let response = res;
      let cultivation = Object.assign(
        {},
        {
          field: res.data.id,
          crop: field.crop,
          active: true,
          owner: field.owner,
        }
      );
      let updated_info = Object.assign(
        {},
        {
          name: field.name,
          area_type: field.area_type,
          soil_type: field.soil_type,
          description: "",
          polygon: field.polygon,
          crop: field.crop,
        }
      );
      if (cultivation.crop != "Free") {
        //only post cultivation if the selected crop is NOT "Free"
        window.axios.post(`/cultivation/`, cultivation).then((res) => {
          dispatch(createMessage({ addCultivation: "Cultivation added." }));
          dispatch({
            type: ADD_CULTIVATION,
            payload: response,
          });
          dispatch({
            type: ADD_AREA,
            payload: {
              field: response.data,
              info: Object.assign({}, updated_info, { color: res.data.color }),
            },
          });
          dispatch(createMessage({ addArea: "Area added." }));
        });
      } else {
        //if selected crop is "Free", then just update Redux accordingly
        dispatch({
          type: ADD_AREA,
          payload: {
            field: response.data,
            info: Object.assign({}, updated_info, { color: "#000000" }),
          },
        });
        dispatch(createMessage({ addArea: "Area added." }));
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

export const updateArea = (field, updated_info) => (dispatch) => {
  window.axios
    .patch(`/area/${field.id}/`, updated_info)
    .then((res) => {
      dispatch(createMessage({ updateArea: "Area updated." }));
      dispatch({
        type: UPDATE_AREA,
        payload: {
          field: field,
          info: updated_info,
          response_area: res.data.properties.area,
        },
      });
    })
    .catch((error) => {
      const errors = {
        msg: error.response.data,
        status: error.response.status,
        hasErrors: true,
      };
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      });
      console.log(error);
    });
};

export const deleteArea = (field) => (dispatch) => {
  window.axios
    .delete(`/area/${field.id}`)
    .then((res) => {
      dispatch(createMessage({ deleteArea: "Area deleted." }));
      dispatch({
        type: DELETE_AREA,
        payload: field,
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const saveMarkers = (markers) => (dispatch) => {
  dispatch({
    type: SAVE_MARKERS,
    payload: markers,
  });
};

export const SaveTranslatedCoordinates = (coordinates, wkt, polygon) => (
  dispatch
) => {
  dispatch({
    type: SAVE_TRANSLATED_COORDINATES,
    payload: { coordinates, wkt, polygon },
  });
};

export const ClearTranslatedCoordinates = () => (dispatch) => {
  dispatch({
    type: CLEAR_TRANSLATED_COORDINATES,
  });
};
