import React, { Component } from "react";
import { Col, Card, CardTitle, CardImg, CardBody, CardText, ButtonGroup, Button} from "reactstrap";
import {formatArea} from "../../../helpers/formaters"
import {setSelectedField} from "../../../../actions/areas"
import {getScreenShotLink} from "../../../helpers/geo"
import {connect} from "react-redux";


class OverviewFieldCard extends Component {

    getFieldUser(owner_, members_){
      let client;
      members_.forEach(member => {
        if (member.user == owner_){
          client = member.profile.last_name + " " + member.profile.first_name
        }
      });
      return client;
    }
  
    render() {
      let { field } = this.props;
      let { name, area, crop, soil_type, owner } = this.props.field.properties;

      let client = this.getFieldUser(owner, this.props.members);
      return (
        <Col xl={2} lg={3} md={4} sm={6}>
          <Card className="overview-card-crops" >
            <CardTitle className="overview-card-title"> {name} </CardTitle>
            <CardImg className="pt-3" src={getScreenShotLink(field)}  alt="Google Map Static ScreenShot"  />
            <CardBody className="overview-card-body">
              <CardText>Client: {client}</CardText>
              <CardText>Soil : {soil_type}</CardText>
              <CardText className="overview-card-text"> Area: {formatArea(area) + " m\u00B2"} </CardText>
              <CardText className="mb-3">Crop : {crop !== null ? crop : "Free"} </CardText>
              <ButtonGroup size="md">
                <Button color="primary" onClick={() => {this.props.setSelectedField(field); this.props.getField(field.id); this.props.toggle()}} >Open</Button>
              </ButtonGroup>
            </CardBody>
          </Card>
        </Col>
      );
    }
  }

  const mapStateToProps = state => {
    return{
      members: state.members.members
    }
  }

  export default connect(mapStateToProps,{setSelectedField})(OverviewFieldCard);