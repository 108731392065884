import React, { Component } from "react";
import { Switch } from "react-router-dom";
import { Container, Row } from "reactstrap";
import {
  faTachometerAlt,
  faMap,
  faLeaf,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import Header from "./Header";
import Sidebar from "./Sidebar";
import PrivateRoute from "../auth/common/PrivateRoute";
import { Role } from "../auth/common/Role";

import Dashboard from "../pages/Dashboard";
import Machinery from "../pages/Machinery";
import Crops from "../pages/Crops";
import Fields from "../pages/Fields";
import Clients from "../pages/Clients";
import Settings from "../pages/Settings";

import Members from "../views/Members/index";

import CropForm from "../views/Crops/CropForm";
import { connect } from "react-redux";
import { loadUser } from "../../actions/auth";

import { getCrops } from "../../actions/crops";
import { getTeams } from "../../actions/teams";
import { getAllMembers } from "../../actions/members";

import TractorForm from "../views/Tractors/TractorForm";
import TractorDetails from "../views/Tractors/TractorDetails";
import TeamForm from "../views/Teams/TeamForm";

class MainContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navlinks: [
        {
          name: "Dashboard",
          slug: "/",
          component: Dashboard,
          exact: true,
          icon: faTachometerAlt,
          roles: [Role.Landholder, Role.Agronomist],
        },
        {
          name: "Fields",
          slug: "/fields",
          component: Fields,
          icon: faMap,
          roles: [Role.Landholder, Role.Agronomist],
        },
        {
          name: "Crops",
          slug: "/crops",
          component: Crops,
          icon: faLeaf,
          roles: [Role.Landholder, Role.Agronomist],
        },
        {
          name: "Clients",
          slug: "/teams",
          component: Clients,
          icon: faUsers,
          roles: [Role.Agronomist],
        },
      ],
    };
  }

  componentDidMount() {
    if (this.props.user == null) this.props.loadUser();
    if (this.props.auth.isAuthenticated) {
      this.props.getTeams();
    }
    this.props.getCrops();
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.teams.teams.length != 0) nextProps.getAllMembers();
    return null;
  }

  render() {
    const { navlinks } = this.state;

    return (
      <Container fluid>
        <Row className="flex-sm-nowrap">
          <Sidebar links={navlinks} user={this.props.user} />

          <div className="app-body">
            <Header />
            {navlinks.map((link, key) => {
              return (
                <PrivateRoute
                  key={key}
                  exact
                  path={link.slug}
                  component={link.component}
                />
              );
            })}
            <Switch>
              <PrivateRoute
                path="/teams/add"
                component={TeamForm}
                roles={[Role.Agronomist]}
              />
              <PrivateRoute
                path="/teams/:id/edit"
                component={TeamForm}
                roles={[Role.Agronomist]}
              />
              <PrivateRoute
                path="/teams/:id/members"
                component={Members}
                roles={[Role.Agronomist]}
              />

              <PrivateRoute
                path="/crops/add"
                component={CropForm}
                roles={[Role.Agronomist]}
              />
              <PrivateRoute
                path="/crops/:id/edit"
                component={CropForm}
                roles={[Role.Agronomist]}
              />

              <PrivateRoute
                path="/cultivations/add"
                component={CropForm}
                roles={[Role.Agronomist]}
              />
              <PrivateRoute
                path="/cultivations/:id/edit"
                component={CropForm}
                roles={[Role.Agronomist]}
              />

              <PrivateRoute
                path="/settings"
                component={Settings}
                roles={[Role.Landholder, Role.Agronomist]}
              />
            </Switch>
          </div>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    teams: state.teams,
    auth: state.auth,
    crops: state.crops.crops,
  };
};

export default connect(mapStateToProps, {
  getTeams,
  getAllMembers,
  loadUser,
  getCrops,
})(MainContent);
