import React, { Component, Fragment } from "react";
import MetricsCard from "./_partials/MetricsCard";
import { Row, Col, Card, CardTitle, CardBody } from "reactstrap";
import {
  faMap,
  faLeaf,
  faTractor,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { getPolygonFeatures } from "../../helpers/geo";
import { getUniqueCropsOfCurrentFields } from "../../helpers/filters"
import CropsAllocation from "./_partials/Charts/CropsAllocation";
import AreaAllocation from "./_partials/Charts/AreaAllocation";
import MainTable from "./_partials/Tables/MainTable";
import { LoadingSpinerComponent } from "../../partials/LoadingSpiner";

class MetricsBoard extends Component {
  state = {};

  render() {
    const { areas, cultivations } = this.props;

    return (
      <Fragment>
        <Row className="p-3">
          <Col xs="6" sm="6" md="6" lg="6">
            <MetricsCard
              heading="Fields"
              count={areas.length}
              linkTo="/fields"
              countLabel="currently active"
              icon={faMap}
            />
          </Col>
          <Col xs="6" sm="6" md="6" lg="6">
            <MetricsCard
              heading="Crops"
              count={getUniqueCropsOfCurrentFields(areas).length - 1}
              linkTo="/crops"
              countLabel="currently active"
              icon={faLeaf}
            />
          </Col>
          {/* <Col xs="6" md="6" lg="3">
            <MetricsCard
              heading="Machinery"
              linkTo="/machinery"
              count="15"
              countLabel="currently in use"
              icon={faTractor}
            />
          </Col>
          <Col xs="6" md="6" lg="3">
            <MetricsCard
              heading="Operations"
              count="123"
              linkTo="/operations"
              countLabel="being processed"
              icon={faCogs}
            />
          </Col> */}
        </Row>
        <Row className="mb-5 p-3">
          <Col md="6" lg="6" className="mb-4">
            <Card className="chart-card">
              <CardTitle className="p-3 mb-0 text-center">
                Crops Allocation
              </CardTitle>
              <CardBody>
                <LoadingSpinerComponent area="getAllMembers-area">
                <CropsAllocation
                  fields={this.props.fields}
                  members={this.props.members}
                />
                </LoadingSpinerComponent>
              </CardBody>
            </Card>
          </Col>
          <Col md="6" lg="6" className="mb-4">
            <Card className="chart-card">
              <CardTitle className="p-3 mb-0 text-center">
                Area Allocation
              </CardTitle>
              <CardBody>
              <LoadingSpinerComponent area="getAllMembers-area">
                <AreaAllocation
                  fields={this.props.fields}
                  members={this.props.members}
                  teams={this.props.teams}
                />
                </LoadingSpinerComponent>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <MainTable
              fields={this.props.fields}
              members={this.props.members}
              teams={this.props.teams}
            />
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    areas: getPolygonFeatures(state.areas.fieldsFullData),
    cultivations: state.cultivations.cultivations,
    fields: state.areas.fieldsFullData,
    members: state.members.members,
    teams: state.teams.teams,
  };
};

export default connect(mapStateToProps)(MetricsBoard);
