import { GET_CROPS, DELETE_CROP, ADD_CROP, GET_ERRORS, EDIT_CROP, UPDATE_CROP } from './types';
import { createMessage } from '../actions/messages';

// Get all Crops
export const getCrops = () => dispatch => {
    window.axios.get('/crop')
        .then(res => {
            dispatch({
                type: GET_CROPS,
                payload: res.data
            })
        }).catch(error => console.log(error)); 
};

// Delete Crops
export const deleteCrop = (id) => dispatch => {
    window.axios.delete(`/crop/${id}/`)
        .then(res => {
            dispatch(createMessage({ deleteCrop: "Crop deleted." }))
            dispatch({
                type: DELETE_CROP,
                payload: id
            })
        }).catch(error => console.log(error)); 
}

export const addCrop = (crop) => dispatch => {
    window.axios.post("/crop/",crop)
        .then(res => {
            dispatch(createMessage({ addCrop: "Crop added." }))
            dispatch({
                type: ADD_CROP,
                payload: res.data
            })
        }).catch(error => {
            const errors = {
                msg: error.response.data,
                status: error.response.status,
                hasErrors: true
            }
            
            dispatch({
                type: GET_ERRORS,
                payload: errors
            })
        }); 
};

// Edit Crop
export const editCrop = (id) => dispatch => {
        dispatch({
            type: EDIT_CROP
        })
}

// Update Crop
export const updateCrop = (crop) => dispatch => {
    window.axios.put(`/crop/${crop.id}/`,crop)
    .then(res => {
        dispatch({
            type: UPDATE_CROP,
            payload: res.data
        })
        dispatch(createMessage({ updateCrop: "Crop updated." }))
    }).catch(error => {
        const errors = {
            msg: error.response.data,
            status: error.response.status,
            hasErrors: true
        }
        dispatch({
            type: GET_ERRORS,
            payload: errors
        })
    });
}