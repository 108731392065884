import React, { Component } from "react";
import classnames from 'classnames';
import { TabContent, Row, Col, TabPane, Nav, NavItem, NavLink, Modal, ModalHeader, ModalBody } from "reactstrap";
import UAV from './UAV/Weeds/UAV';
import ForecastCharts from "./Weather Forecast/ForecastCharts";
import RemoteSensing from './Remote Sensing/RemoteSensing';
import Basic from "./Overview/Basic";
import {connect} from "react-redux"
import MeteoForecast from './LiveMeteoForecast/MeteoForecast';
import MobileUploads from "./Mobile uploads/MobileUploads";
import Utilities from "./Utilities/UtilitiesIndex";
import Calendar from "../Calendar/Calendar";


class FieldCard extends Component {
    constructor(props) {
      super(props);

      this.state = {
        toggleModal: false,
        activeTab: 1,
      };


      this.toggle = this.toggle.bind(this);
      this.onModalClose = this.onModalClose.bind(this);
      this.toggleTab = this.toggleTab.bind(this);
    }

    onModalClose(){
      this.props._externalToggle();
    }

    toggle() {
      this.setState({
        toggleModal: !this.state.toggleModal
      });
    }

    toggleTab(selectedTab) {
        this.setState({
          activeTab: selectedTab
        })
    }
  
    render() {
      
      const _tabs = [
        { id: 1, title: 'Overview', component: <Basic /> },
        { id: 3, title: 'Calendar', component: <Calendar active={this.state.activeTab == 3}/> },
        { id: 4, title: 'UAV', component: <UAV /> },
        { id: 5, title: 'Remote Sensing', component: <RemoteSensing />},
        { id: 6, title: 'Weather Forecast', component: <ForecastCharts field={this.props.selectedField} />},
        { id: 7, title: 'Live Meteo', component: <MeteoForecast /> },
        { id: 8, title: 'Uploads', component: <MobileUploads field={this.props.selectedField} files={this.props.files}/>},
        { id: 9, title: 'Utilities', component: <Utilities field={this.props.field} />},
      ]

      return (
        <Modal className="fieldCard" size="lg" isOpen={this.props.toggleModal} fade={false} toggle={this.props._externalToggle} >
          <ModalHeader toggle={this.props._externalToggle}>
            {this.props.selectedField.properties.name}
          </ModalHeader>
          <ModalBody>
            <Nav tabs>
              { _tabs.map((tab) => {
                  return (
                    <NavItem key={tab.id}>
                      <NavLink key={tab.id}
                        className={classnames({ active: this.state.activeTab === tab.id })}
                        onClick={() => { this.toggleTab(tab.id); }} >
                        { tab.title }
                      </NavLink>
                    </NavItem>
                  )
                })
              }
            </Nav>
              <TabContent activeTab={this.state.activeTab}>
                { _tabs.map((tab) => {
                    return (
                      <TabPane tabId={tab.id} key={tab.id}>
                        <Row>
                          <Col sm="12" className="py-3">
                            { tab.component}
                          </Col>
                        </Row>
                      </TabPane>
                    )
                })}
              </TabContent>
          </ModalBody>
        </Modal>
      );
    }
  }
const mapStateToProps = (state) => {
  return{
    fields: state.areas.fieldsFullData,
    selectedField: state.areas.selectedField.field,
    files: state.areas.selectedField.files,
    field: state.areas.selectedField,
    markers: state.areas.markers
  }
}
export default connect(mapStateToProps)(FieldCard) ;
