import React, { Component } from "react";
import {
  Row,
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Input
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { login } from "../../actions/auth";
import Logo from "../../assets/images/biocircular-logo-white.png";
import { Redirect, withRouter } from "react-router-dom";
import { LoadingSpinerComponent } from "../partials/LoadingSpiner"

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  static propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const { username, password } = this.state;

    this.props.login(username.toLowerCase(), password);
  };

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/" />;
    }

    const { username, password } = this.state;
    const errors = this.props.errors;

    return (
      <Container className="d-flex flex-column justify-content-center vh-100">
        <div>
          <LoadingSpinerComponent className="loginOverlay" area="Login-area"></LoadingSpinerComponent>
        </div>
        <Row>
          <Col md={6} className="mx-auto">
            <Card className="bg-dark p-6 w-full max-w-sm rounded overflow-hidden shadow-md">
              <Form id="loginForm" onSubmit={this.onSubmit}>
                <CardHeader className="text-center border-0">
                  <img
                    className="max-w-full w-32 mx-auto block my-3"
                    src={Logo}
                    alt="website-logo"
                  />
                  <p className="text-white font-weight-bold text-center pt-2 pb-2 mb-0">
                    Member Area
                  </p>
                </CardHeader>
                <CardBody>
                  <Row form>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="username"
                          id="username"
                          placeholder="Username"
                          value={username}
                          invalid={errors.hasErrors && errors.msg.username}
                          onChange={this.onChange}
                        />
                        <FormFeedback
                          invalid={errors.hasErrors ? "true" : "false"}
                        >
                          {errors.msg.username}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup>
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="Password"
                          value={password}
                          invalid={errors.hasErrors && errors.msg.password}
                          onChange={this.onChange}
                        />
                        <FormFeedback
                          invalid={errors.hasErrors ? "true" : "false"}
                        >
                          {errors.msg.password}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button color="primary" className="mx-auto d-block">
                    Sign In
                    <FontAwesomeIcon
                      className="size-16 ml-2"
                      icon={faSignInAlt}
                    />
                  </Button>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.errors,
    messages: state.messages,
    isAuthenticated: state.auth.isAuthenticated
  };
};

export default connect(mapStateToProps, { login })(withRouter(Login));
