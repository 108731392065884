import React, {Component} from 'react';
import { Button, Card, CardBody,CardTitle, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye,faEdit,faTrash } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class Tractor extends Component{ 

  static propTypes = {
    tractor: PropTypes.object.isRequired,
    deleteTractor: PropTypes.func.isRequired,
    editTractor: PropTypes.func.isRequired
  }

  toogleEdit(id){
    this.props.editTractor();
    this.props.history.push("/machinery/tractors/" + id + "/edit")
  }

  render() {
    const tractor = this.props.tractor;
    const { id, name, brand, model, purchase_value, engine_power, max_power, purchase_year, extra_hours_of_usage, wheels } = tractor;

    return (
      <Card body >
        <CardHeader>
            <CardTitle className="mb-0">{name}</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="12">
              <p><strong>Brand:</strong> {brand}</p>
            </Col>
            <Col md="12">
              <p><strong>Model:</strong> {model}</p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p><strong>Price:</strong> {purchase_value} &euro;</p>
            </Col>
            <Col md="12">
              <p><strong>Purchase Year:</strong> {purchase_year}</p>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <p><strong>Wheels:</strong> {wheels}</p>
            </Col>
            <Col md="12">
              <p><strong>Engine Power:</strong> {engine_power} hp</p>
            </Col>
            <Col md="12">
              <p><strong>Max Power:</strong> {max_power} hp</p>
            </Col>
            <Col md="12">
              <p><strong>Extra hours of usage:</strong> {extra_hours_of_usage} h</p>
            </Col>
          </Row>
        </CardBody>
        <CardFooter className="text-center text-md-right  p-2">
            <Link className="mr-2 btn btn-primary" to={"machinery/tractors/" + tractor.id}>
                      <FontAwesomeIcon icon={faEye} />
                    </Link>
                    <Button color="secondary" onClick={this.toogleEdit.bind(this,id)} className="mr-2">
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                    <Button color="danger" onClick={this.props.deleteTractor}>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
        </CardFooter>
      </Card>
    )
  }
}


export default withRouter(Tractor);