import React, { Component } from 'react';
import {
    Navbar,
    Nav,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faSignOutAlt, faBars } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { PropTypes } from 'prop-types';
import { logout } from '../../actions/auth';
import { withRouter } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  onLogout = e => {
    e.preventDefault();

    this.props.logout();
    
  }

  render() {

    const {first_name} = this.props.auth.user ? this.props.auth.user : '';

    return (
      <Navbar color="dark" dark expand="lg" className="mb-3 mx-n15">
        <Nav
          className="d-flex justify-content-between align-items-center flex-row flex-grow-1"
          navbar
        >
          <li className="toogleSidebar">
            <FontAwesomeIcon className="text-light" icon={faBars} size="2x" />
          </li>
          <UncontrolledDropdown nav inNavbar className="">
            <DropdownToggle
              nav
              className="profile-wrapper d-flex justify-content-center align-items-center"
            >
              <div className="profile mr-2">Welcome, {first_name}</div>
              <FontAwesomeIcon icon={faUserCircle} size="2x" />
            </DropdownToggle>
            <DropdownMenu right>
              <NavLink strict exact={true} to={"/settings"}>
                <DropdownItem>
                  <FontAwesomeIcon className="size-16 mr-3" icon={faCogs} />
                  Settings
                </DropdownItem>
              </NavLink>
              <DropdownItem divider />
              <DropdownItem onClick={this.onLogout}>
                <FontAwesomeIcon className="size-16 mr-3" icon={faSignOutAlt} />
                Sign Out
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {

  return {
    auth: state.auth,
  }
};


export default connect(mapStateToProps, { logout })(withRouter(Header));