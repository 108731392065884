
import { GET_CROPS, DELETE_CROP, ADD_CROP,EDIT_CROP, UPDATE_CROP } from '../actions/types';

const initialState = {
    crops: [],
    editing: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_CROPS:
            return {
                ...state,
                crops: action.payload
            }
        case DELETE_CROP:
            return {
                ...state,
                crops: state.crops.filter(crop => 
                    crop.id !== action.payload)
            }
        case ADD_CROP:
            return {
                ...state,
                crops: [...state.crops, action.payload]
            }
        case EDIT_CROP:
            return {
                ...state,
                editing: true
            }
        case UPDATE_CROP:
                return {
                    ...state,
                    crops: state.crops.map(crop => {
                        if(crop.id === action.payload.id) {
                            return {
                                ...crop,
                                name: action.payload.name,
                                description: action.payload.description,
                                color: action.payload.color,
                            }
                        }
                        else {
                            return crop;
                        }
                    })
                }
        default:
            return state
    }
}