import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { clearErrors } from "../../../../actions/messages";
import { loadUser } from "../../../../actions/auth";
import { LoadingSpinerComponent } from "../../../partials/LoadingSpiner";
import Authorize from "../../../auth/common/Authorize";
import { Role } from "../../../auth/common/Role";

class ViewProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      username: "",
      first_name: "",
      last_name: "",
      father_name: "",
      afm: "",
      amka: "",
      mobile_phone: "",
      iban: "",
      postal_code: "",
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile == undefined)
      this.mapReduxFieldPropsToState(nextProps.user);
    else this.mapReduxFieldPropsToState(nextProps.profile);
  }

  handleEdit = (e) => {
    e.preventDefault();
    this.props.toggleEdit();
    this.props.clearErrors();
  };

  componentDidMount() {
    this.props.clearErrors();
    if (this.props.profile == undefined)
      this.mapReduxFieldPropsToState(this.props.user);
    else this.mapReduxFieldPropsToState(this.props.profile);
  }

  mapReduxFieldPropsToState = (user) => {
    const {
      email,
      username,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    } = user;

    this.setState({
      email,
      username,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    });
  };

  render() {
    const {
      email,
      username,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    } = this.props.profile == undefined ? this.state : this.props.profile; //if member.profile prop has been given, deconstruct it and use it. Otherwise, use state's values

    const errors = this.props.errors;

    return (
      <Card className="border-0">
        <Form id="personalInfoForm">
          <div>
            <LoadingSpinerComponent
              className="loginOverlay"
              area="personalInfoForm"
            ></LoadingSpinerComponent>
          </div>
          <CardHeader className="bg-dark text-white">
            Profile Settings
          </CardHeader>
          <CardBody>
            <h5>Personal Information</h5>
            <hr />
            <Row form className="mb-3">
              <Col md={4}>
                <Label for="first_name">First Name:</Label>
                <p>{first_name}</p>
              </Col>
              <Col md={4}>
                <Label for="last_name">Last Name:</Label>
                <p>{last_name}</p>
              </Col>
              <Col md={4}>
                <Label for="father_name">Father Name:</Label>
                <p>{father_name}</p>
              </Col>
            </Row>
            <h5>Contact Information</h5>
            <hr />
            <Row form className="mb-3">
              <Col md={5}>
                <Label for="email">E-mail Address:</Label>
                <p>{email}</p>
              </Col>
              <Col md={4}>
                <Label for="mobile_phone">Mobile Phone:</Label>
                <p>{mobile_phone}</p>
              </Col>
              <Col md={3}>
                <Label for="postal_code">Postal Code:</Label>
                <p>{postal_code}</p>
              </Col>
            </Row>
            <h5>Unique Social Numbers</h5>
            <hr />
            <Row form>
              <Col md={6}>
                <Label for="afm">VAT:</Label>
                <p>{afm}</p>
              </Col>
              <Col md={6}>
                <Label for="amka">AMKA:</Label>
                <p>{amka}</p>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <Label for="iban">IBAN:</Label>
                <p>{iban}</p>
              </Col>
            </Row>
          </CardBody>
          {/* <Authorize roles={[Role.Agronomist]}> */}
            <CardFooter className="text-right">
              <Button
                color="primary"
                className="mr-2"
                onClick={this.handleEdit}
              >
                Edit
              </Button>
            </CardFooter>
          {/* </Authorize> */}
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    messages: state.messages,
    user: state.auth.user,
    team_members: state.members.team_members
  };
};

export default connect(mapStateToProps, { loadUser, clearErrors })(ViewProfile);

ViewProfile.propTypes = {
  loadUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};
