import { GET_ERRORS, CLEAR_ERRORS } from '../actions/types';


const initialState = {
    msg: {},
    status: null,
    hasErrors: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_ERRORS:
            return {
                msg: action.payload.msg,
                status: action.payload.status,
                hasErrors: action.payload.hasErrors
            }
        case CLEAR_ERRORS:
            return initialState;
        default:
            return state;
    }
}
