import React, { Component } from "react";
import Chart from "chart.js"
import { connect } from 'react-redux';
import DataProviderCredits from "./WeatherDataProviders"

class PressureChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: [],
        };

        this.parsePressure = this.parsePressure.bind(this);

        this.renderPressureCharts = this.renderPressureCharts.bind(this);
    }

    componentDidMount() {
        this.setState({ field: this.props.field });
    }

    parsePressure() {
        var forecast = this.state.field.OpenWeather.forecast.list;
        var pressure = {};
        pressure.pressure = [];
        pressure.time = [];
        pressure.day_change = [];
        pressure.max = forecast[0].main.pressure;
        pressure.min = forecast[0].main.pressure;
        for (var key in forecast) {
            pressure["pressure"].push(forecast[key].main.pressure);
            pressure["max"] = pressure["max"] < forecast[key].main.pressure ? forecast[key].main.pressure : pressure["max"];
            pressure["min"] = pressure["min"] > forecast[key].main.pressure ? forecast[key].main.pressure : pressure["min"];

            var date = forecast[key].dt_txt.toString().split(' ')[0].slice(5, 10).split('-');
            var time = forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5);

            if (time == "00:00") {
                pressure["time"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
                pressure["day_change"].push("(" + date[1] + "-" + date[0] + ")" + " " + time)
            }
            else
                pressure["time"].push(forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5));

            if (key == 16)
                break;
        }

        pressure.color = 'rgba(255, 132, 0, ';
        return pressure;
    }

    renderPressureCharts() {
        var myCanvas = document.getElementById('PressureChart').getContext('2d');
        var Pressure = this.parsePressure();
        var annotations = Pressure.day_change.map(function (date, index) {
            return {
                type: 'line',
                id: 'vline' + index,
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: date,
                borderColor: 'black',
                borderWidth: 0.5,
                borderDash: [5, 5]
            }
        });

        var mytooltip = {
            callbacks: {
                title: function () {
                    return '';
                }
            }
        }

        new Chart(myCanvas, {
            type: 'line',
            data: {
                labels: Pressure["time"],
                datasets: [
                    {
                        label: 'Pressure (mb)',
                        data: Pressure["pressure"],
                        borderWidth: 1,
                        fill: false,
                        backgroundColor: Pressure.color + " 0.2)",
                        borderColor: Pressure.color + " 1)"
                    }
                ]
            },
            options: {
                // responsive: false,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: "Atmospheric Pressure Forecast"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            suggestedMin: Pressure.min - 5,
                            suggestedMax: Pressure.max + 5
                        }
                    }]
                },
                annotation: {
                    annotations: annotations
                },
                tooltips: mytooltip
            }
        });
    }

    render() {
        if (this.props.fetched && document.getElementById('PressureChart') != null) this.renderPressureCharts();

        return (
            <div>
                <canvas className="weather-chart" id={this.props.title.replace(' ', '') + "Chart"}></canvas>
                <DataProviderCredits DataType="Weather" DataSource={this.props.DataSource} hidden={!this.props.fetched}></DataProviderCredits>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let fetched = false;

    if (Object.keys(state.areas.selectedField.field.OpenWeather.forecast).length != 0)
        fetched = true;
    return { field: state.areas.selectedField.field, fetched: fetched }
}

export default connect(mapStateToProps)(PressureChart)