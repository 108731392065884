import React, { Component, createRef, Fragment } from "react";
import { Input } from "reactstrap";
import classnames from "classnames";
import { connect } from "react-redux";
import { convertPolygonToWKT } from "../../../helpers/geo";
import { CustomDrawingManager } from "../../../helpers/CustomMapControls";
import { setSelectedField } from "../../../../actions/areas";

class MainMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      el: this.props.el,
      map: null,
      loaded: false,
      selectedarea: [],
      removed: false,
      polygon: [],
    };

    this.init = this.init.bind(this);
    this.createPrototypes = this.createPrototypes.bind(this);
    this.createGoogleMap = this.createGoogleMap.bind(this);

    // Register google map events
    this.onDrawingOverlayComplete = this.onDrawingOverlayComplete.bind(this);
    this.onZoomChanged = this.onZoomChanged.bind(this);
    this.onPolygonClick = this.onPolygonClick.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);

    this.googleMapRef = createRef();
  }

  componentDidMount() {
    this.init();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.with_drawing_manager) return;

    if (nextProps.erasePolygon) {
      nextProps.PolygonToErase.setMap(null);

      document.getElementById("drawBtn").disabled = false;
      document.getElementById("drawBtn").className = classnames(
        "btn btn-primary"
      );

      document.getElementById("inputBtn").disabled = false;
      document.getElementById("inputBtn").className = classnames(
        "btn btn-primary"
      );

      nextProps.isPolygonRemoved(true);
    }

    this.setState({ removed: true });
  }

  /**
   *  Initializes Map Component
   */
  init() {
    this.createGoogleMap();

    if (this.props.with_drawing_manager) this.createDrawingManager();

    this.addEvents();
    this.createPrototypes();
    this.addSearchBar();

    if (Object.keys(this.props.fieldsFullData).length > 0) this.ZoomToFields();

    this.setState({ loaded: !this.state.loaded });
  }

  /**
   *  Creates the GoogleMap object and attaches it to the state
   */
  createGoogleMap() {
    if (this.state.el == undefined || this.state.el === "") {
      console.error("Please specify (el) parameter to the map component.");
      return;
    }
    let map = new window.google.maps.Map(this.googleMapRef.current, {
      center: new window.google.maps.LatLng(39.3666667, 22.9458333),
      zoom: 7,
      disableDefaultUI: true,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      mapTypeControl: false,

      zoomControl: true,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_CENTER,
      },
      fullscreenControl: true,
      fullscreenControlOptions:{
        position: window.google.maps.ControlPosition.LEFT_TOP
      },
      streetViewControl: false,
      streetViewControlOptions: {
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
    });
    this.props.passMapToParent(map);

    this.state.map = map;
  }

  createDrawingManager() {
    var polyOptions = {
      strokeWeight: 0,
      fillOpacity: 0.75,
      editable: true,
    };

    var mydrawingManager = new window.google.maps.drawing.DrawingManager({
      drawingMode: window.google.maps.drawing.OverlayType.HAND,
      drawingControl: false,
      polylineOptions: {
        editable: true,
        draggable: true,
      },
      rectangleOptions: polyOptions,
      circleOptions: polyOptions,
      polygonOptions: polyOptions,
      map: this.state.map,
    });

    this.props.passDrawingManagerToParent(mydrawingManager);
    this.state.drawingManager = mydrawingManager;
  }

  addSearchBar() {
    var map = this.state.map;
    // Create the search box and link it to the UI element.
    var input = document.getElementById("map-searchbox");
    var searchBox = new window.google.maps.places.SearchBox(input);
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener("bounds_changed", function () {
      searchBox.setBounds(map.getBounds());
    });

    var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener("places_changed", function () {
      var places = searchBox.getPlaces();

      if (places.length == 0) return;

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      var bounds = new window.google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new window.google.maps.Size(71, 71),
          origin: new window.google.maps.Point(0, 0),
          anchor: new window.google.maps.Point(17, 34),
          scaledSize: new window.google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        markers.push(
          new window.google.maps.Marker({
            map: map,
            icon: icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) bounds.union(place.geometry.viewport);
        // Only geocodes have viewport.
        else bounds.extend(place.geometry.location);
      });
      map.fitBounds(bounds);
    });
  }

  addEvents() {
    this.onZoomChanged();
    this.onDrawingOverlayComplete();
    this.onPolygonClick();
    this.onMouseOver();
    this.onMouseOut();
  }

  onZoomChanged() {
    this.state.map.addListener("zoom_changed", () => {});
  }

  onDrawingOverlayComplete() {
    window.google.maps.event.addListener(
      this.state.drawingManager,
      "overlaycomplete",
      (e) => {
        var parent_state = this.state;
        var parent_props = this.props;

        this.props.passPolygonToParent(e); //e = google.maps.polygon object
        this.setState({ polygon: e.overlay });

        //sets the drawingManager to null and disables the MapSideDock
        this.state.drawingManager.setDrawingMode(null);
        document.getElementById("drawBtn").disabled = true;
        document.getElementById("inputBtn").disabled = true;
        this.props.triggerSaveToast(true);

        window.google.maps.event.addListener(
          e.overlay.getPath(),
          "insert_at",
          function (index, obj) {
            parent_props.passPolygonToParent(e);
            parent_state.polygon = e.overlay;
          }
        );

        window.google.maps.event.addListener(
          e.overlay.getPath(),
          "set_at",
          function (index, obj) {
            parent_props.passPolygonToParent(e);
            parent_state.polygon = e.overlay;
          }
        );
      }
    );
  }

  onPolygonClick() {
    this.state.map.data.addListener("click", (event) => {
      this.props.setSelectedField(this.props.fieldsFullData[event.feature.o]);
      this.props.sendClickedFieldID(event.feature.o);
      this.props.toggleModal();
    });
  }

  onMouseOver() {
    this.state.map.data.addListener("mouseover", (event) => {
      //Hover effect
      this.state.map.data.revertStyle();
      this.state.map.data.overrideStyle(event.feature, {
        strokeOpacity: 1,
        strokeWeight: 5,
        fillOpacity: 0.1,
      });
    });
  }

  onMouseOut() {
    this.state.map.data.addListener("mouseout", (event) => {
      this.state.map.data.revertStyle();
    });
  }

  /**
   *  Add a series of prototypes to google map classes
   */
  createPrototypes() {
    // Assigns getBounds prototype to Polygon class
    window.google.maps.Polygon.prototype.getBounds = function () {
      var bounds = new window.google.maps.LatLngBounds();

      this.getPath().forEach(function (element) {
        bounds.extend(element);
      });

      return bounds;
    };
  }

  ZoomToFields() {
    var _fields = this.props.fieldsFullData;
    var polygons = [],
      _c = 0;

    for (var key in _fields) {
      _fields[key].geometry.coordinates.forEach((_g) => {
        polygons[_c] = _g;
        _c++;
      });
    }

    var maxLat = polygons[0][0].toString().split(",")[1];
    var maxLng = polygons[0][0].toString().split(",")[0];
    var minLat = polygons[0][0].toString().split(",")[1];
    var minLng = polygons[0][0].toString().split(",")[0];
    for (var i = 0; i < polygons.length; i++) {
      for (var j = 0; j < polygons[i].length; j++) {
        if (polygons[i][j].toString().split(",")[1] > maxLat)
          maxLat = polygons[i][j].toString().split(",")[1];
        if (polygons[i][j].toString().split(",")[0] > maxLng)
          maxLng = polygons[i][j].toString().split(",")[0];
        if (polygons[i][j].toString().split(",")[1] < minLat)
          minLat = polygons[i][j].toString().split(",")[1];
        if (polygons[i][j].toString().split(",")[0] < minLng)
          minLng = polygons[i][j].toString().split(",")[0];
      }
    }

    var _lat = (parseFloat(maxLat) + parseFloat(minLat)) / 2;
    var _lng = (parseFloat(maxLng) + parseFloat(minLng)) / 2;
    var myMarker = { lat: _lat, lng: _lng };
    var bounds = new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(minLat, minLng),
      new window.google.maps.LatLng(maxLat, maxLng)
    );

    this.state.map.setCenter(myMarker);
    this.state.map.fitBounds(bounds);
  }

  render() {
    return (
      <Fragment>
        <div>
          {/*Contains the search box*/}
          <Input
            id="map-searchbox"
            className="controls"
            type="text"
            placeholder="Search a place ..."
          />
        </div>
        <div className="map-wrapper position-relative h-100">
          <div
            ref={this.googleMapRef}
            id={this.props.el}
            className={classnames("g-map mh-100", this.props.classes)}
            style={{ height: this.props.height }}
          ></div>
          {this.props.children}
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    fieldsFullData: state.areas.fieldsFullData,
  };
};

export default connect(mapStateToProps, { setSelectedField })(MainMap);
