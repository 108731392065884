/**
 *
 *  Helper function to format area
 * @param {*} area
 */
export const formatArea = function (area, mode = "sm") {
  if (mode == "sm")
    return parseFloat(area * Math.pow(10, 10))
      .toFixed(0)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else if (mode == "ha")
    return parseFloat(area * Math.pow(10, 6))
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 *
 *  Helper function to format area
 * @param {*} area
 */
export const formatAreaToInt = function (area) {
  return parseInt(area * Math.pow(10, 10));
};

/**
 *
 *  Helper function to format given name
 * @param {String} name_ "name"
 * @returns {String} "Name"
 * @description Receives a string and returns it with its 1st letter capitalized and all others as lowercase
 */
export const formatName = function (name_) {
  let name = name_.toLowerCase();
  return name.charAt(0).toUpperCase() + name.slice(1);
};
