import React, { Component } from "react";
import Chart from "chart.js"
import { connect } from 'react-redux';
import DataProviderCredits from "./WeatherDataProviders"

class HumidityChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            field: [],
        };

        this.parseHumidity = this.parseHumidity.bind(this);

        this.renderHumidityCharts = this.renderHumidityCharts.bind(this);
    }

    componentDidMount() {
        this.setState({ field: this.props.field });
    }

    parseHumidity() {
        var forecast = this.state.field.OpenWeather.forecast.list;
        var humidity = {};
        humidity.humidity = [];
        humidity.time = [];
        humidity.day_change = [];
        for (var key in forecast) {
            humidity["humidity"].push(forecast[key].main.humidity);

            var date = forecast[key].dt_txt.toString().split(' ')[0].slice(5, 10).split('-');
            var time = forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5);

            if (time == "00:00") {
                humidity["time"].push("(" + date[1] + "-" + date[0] + ")" + " " + time);
                humidity["day_change"].push("(" + date[1] + "-" + date[0] + ")" + " " + time)
            }
            else
                humidity["time"].push(forecast[key].dt_txt.toString().split(' ')[1].slice(0, 5));

            if (key == 16)
                break;
        }

        humidity.color = 'rgba(62, 207, 62, ';
        return humidity;
    }

    renderHumidityCharts() {
        var myCanvas = document.getElementById('HumidityChart').getContext('2d');
        var Humidity = this.parseHumidity();
        var annotations = Humidity.day_change.map(function (date, index) {
            return {
                type: 'line',
                id: 'vline' + index,
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: date,
                borderColor: 'black',
                borderWidth: 0.5,
                borderDash: [5, 5]
            }
        });

        var mytooltip = {
            callbacks: {
                title: function () {
                    return '';
                }
            }
        }

        new Chart(myCanvas, {
            type: 'bar',
            data: {
                labels: Humidity["time"],
                datasets: [
                    {
                        label: 'Humidity (%)',
                        data: Humidity["humidity"],
                        borderWidth: 1,
                        fill: false,
                        backgroundColor: Humidity.color + " 0.2)",
                        borderColor: Humidity.color + " 1)"
                    }
                ]
            },
            options: {
                // responsive: false,
                maintainAspectRatio: false,
                title: {
                    display: true,
                    text: "Humidity Forecast"
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            suggestedMax: 100
                        }
                    }]
                },
                annotation: {
                    annotations: annotations
                },
                tooltips: mytooltip
            }
        });
    }

    render() {
        if (this.props.fetched && document.getElementById('HumidityChart') != null) this.renderHumidityCharts();

        return (
            <div>
                <canvas className="weather-chart" id={this.props.title.replace(' ', '') + "Chart"}></canvas>
                <DataProviderCredits DataType="Weather" DataSource={this.props.DataSource} hidden={!this.props.fetched}></DataProviderCredits>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    let fetched = false;

    if (Object.keys(state.areas.selectedField.field.OpenWeather.forecast).length != 0)
        fetched = true;
    return { field: state.areas.selectedField.field, fetched: fetched }
}

export default connect(mapStateToProps)(HumidityChart)