import React, { Component } from "react";
class DataProviderCredits extends Component{
    constructor(props) {
      super(props);
    }
  
    //this.props.hidden: true / false / omitted
    //this.props.DataType: "Weather" / "VegetationIndex" (case sensitive)
    //this.props.DataSource: http://www.blablablabla.whatever (must have the prefix "www." for dynamic text display)
    render(){
      return(
        <div hidden={(this.props.hidden == undefined ? false : this.props.hidden) || this.props.DataSource == undefined} className={this.props.DataType + "Attribute " + "text-muted"}>
          <p>{"Data provided by "}
            <a className="DataSource" href={this.props.DataSource != undefined? this.props.DataSource : ''} target="_blank"> {this.props.DataSource!=undefined ? this.props.DataSource.toString().split("www.")[1] : ''}</a>
          </p>
        </div>
      )
    }
  }

  export default DataProviderCredits;