import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { EGSA87_to_WGS84 } from "../helpers/EGSA87toWGS84";
import { convertCoordinatestoWKT, zoomToField } from "../helpers/geo";

class AddBatchFieldsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormat: "",
      fields_cords: null,
      format_options: [
        {
          name: "WGS84",
          description: "World Geodetic System(WGS84) - (lat,lon)",
        },
        {
          name: "EGSA87",
          description: "HEPOS(EGSA87) - (x,y)",
        },
        //   {
        //     name: "utm",
        //     description: "UTM - (x,y,zone)",
        //   },
      ],
    };
    this.toggle = this.toggle.bind(this);
    this.formatChange = this.formatChange.bind(this);
    this.fieldsCoordsChange = this.fieldsCoordsChange.bind(this);
    this.parseCoordinates = this.parseCoordinates.bind(this);
  }

  componentDidMount() {
    this.setState({ modal: this.props.shown });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps == prevState) return null;

    return [{ modal: nextProps.shown }];
  }

  formatChange(e) {
    this.setState({ selectedFormat: e.target.value });
  }

  fieldsCoordsChange(event) {
    let coords = event.target.value;
    //only allow numbers, dots, whitespaces and newLines
    let coords_formated = coords.replace(/([\t])/g,' ');
    let coords_filtered = coords_formated.replace(/[^(0-9.\n\s)]/g,'');

    document.getElementsByName(event.target.name)[0].value = coords_filtered;
    this.setState({ [event.target.name]: event.target.value });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  isWktValid(wkt) {
    return !wkt.includes("NaN");
  }

  /**
   *
   * @param {String} coords_as_raw_text_
   * @param {String} source_
   * @description raw_text_ format:
   * @description x1 y1,
   * @description x2 y2,
   * @description xn yn (no comma for last set of coordinates)
   */
  parseCoordinates(coords_as_raw_text_, source_) {
    if (coords_as_raw_text_ == "") return;

    let reversed_coords = new String(coords_as_raw_text_).split("").reverse().join("");
    let coords_without_trailing_newLines = reversed_coords.slice(reversed_coords.search(/[0-9]/), reversed_coords.length).split("").reverse().join("");

    let coords_as_text = coords_without_trailing_newLines;

    let coords_array = coords_as_text.split("\n");

    let final_translated_coords = [];

    coords_array.forEach((current_coords) => {
      let current_coords_split = current_coords.split(" ");

      if (source_ == "EGSA87")
        final_translated_coords.push(
          EGSA87_to_WGS84(current_coords_split[0], current_coords_split[1])
        );
      else
        final_translated_coords.push([
          parseFloat(current_coords_split[0]),
          parseFloat(current_coords_split[1]),
        ]);
    });

    let wkt = convertCoordinatestoWKT(final_translated_coords);

    if (!this.isWktValid(wkt)) {
      alert("Invalid syntax or coordinates");
      return;
    }

    let fieldPolygon = this.createPolygonByCoords(final_translated_coords);

    //"tricky" way to zoom to a field that actually doesn't yet exist in Redux
    let temp_coords = final_translated_coords;
    temp_coords.push(final_translated_coords[0]); //this was necessary only for the zoom "trick"
    let generated_field = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [final_translated_coords],
      },
    };
    zoomToField(generated_field, this.props.map);
    final_translated_coords.pop(); //removing the set of coordinates previously added to achieve field-zoom

    this.toggle();
    this.props.returnCoordinates(final_translated_coords, wkt, fieldPolygon);
  }

  createPolygonByCoords(coordinates_) {
    let polygonCoordinates = [];
    for (var coord in coordinates_)
      polygonCoordinates.push({
        lat: coordinates_[coord][1],
        lng: coordinates_[coord][0],
      });

    var fieldPolygon = new window.google.maps.Polygon({
      paths: polygonCoordinates,
      strokeWeight: 0,
      fillOpacity: 0.75,
      // editable: true,
      // draggable: true
    });
    fieldPolygon.setMap(this.props.map);

    return fieldPolygon;
  }

  disableDrawingControls(){
    document.getElementById('drawBtn').disabled = true;
    document.getElementById('inputBtn').disabled = true;
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              this.disableDrawingControls();
              this.parseCoordinates(
                this.state.fields_cords,
                this.state.selectedFormat
              );
            }}
          >
            <ModalHeader toggle={this.toggle}>
              Draw an area with coordinates
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Coordinates Format:</Label>
                <Input
                  type="select"
                  required
                  defaultValue=""
                  onChange={this.formatChange}
                  name="format"
                  id="format"
                >
                  <option value="" disabled>
                    Select Coordinates Format
                  </option>
                  {this.state.format_options != null
                    ? this.state.format_options.map((item) => (
                        <option
                          value={item.name}
                          key={item.name}
                          id={item.name}
                        >
                          {item.description}
                        </option>
                      ))
                    : null}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label>Insert each set of coordinates in seperate line:</Label>
                <Input
                  required
                  className={"w-100"}
                  onChange={this.fieldsCoordsChange}
                  type="textarea"
                  name="fields_cords"
                  rows={"10"}
                  placeholder={
                    "Example:\n" +
                    "x1 y1\n" +
                    "x2 y2\n" +
                    "x3 y3\n" +
                    ".\n.\n.\nxn yn"
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="primary">
                Preview
              </Button>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default AddBatchFieldsModal;
