import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import { default as Switcher } from "bootstrap-switch-button-react";
import { Role } from "../../auth/common/Role";
import Authorize from "../../auth/common/Authorize";

class SavePolygonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      areaType: "Field",
      area: "",
      owner: "",
      crop: "Free",
      is_orchard: false,
      num_trees: 0,
      years_input: 0,
      layout_trees: null,
    };

    this.toggle = this.toggle.bind(this);
    this.saveArea = this.saveArea.bind(this);
    this.formUpdate = this.formUpdate.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let current_user_role = nextProps.auth.user.groups[0]; //retrieve the role of the current logged in user (Agronomist or Landholder)
    let owner = nextProps.auth.user.username; //initialize owner as the current logged in user and if current user is Landholder, then use it as the "owner" field for the Post call
    if (current_user_role == Role.Agronomist) owner = ""; //if current user is Agronomist, set owner = "" and let the Agronomist decide who the owner will be

    this.setState({
      modal: nextProps.shown,
      area: nextProps.area,
      owner: owner
    });
  }

  saveArea() {
    const area = {
      name: this.state.name,
      polygon: this.state.area,
      soil_type: this.state.soilType,
      area_type: this.state.areaType,
      owner: this.state.owner,
      description:
        this.state.description == undefined ? null : this.state.description,
      crop: this.state.crop,
      is_orchard: this.state.is_orchard,
      num_trees: this.state.is_orchard ? this.state.num_trees : null,
      years_input: this.state.is_orchard ? this.state.years_input : null,
      layout_trees: this.state.is_orchard ? this.state.layout_trees : null,
    };
    this.props.save(area);
  }

  formUpdate(state_object, state_value) {
    this.setState({ [state_object]: state_value });
  }
  toggle() {
    this.setState({ modal: !this.state.modal });
    this.props.changed();
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          onKeyDown={(event) => {
            if (event.keyCode == 13) this.saveArea();
          }}
        >
          <ModalHeader toggle={this.toggle}>Save New Area</ModalHeader>
          <ModalBody>
            {" "}
            <SaveForm
              onUpdate={this.formUpdate}
              members={this.props.members}
              soil_types={this.props.soil_types}
              crops={this.props.crops}
            />{" "}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn btn-primary"
              onClick={() => {
                this.saveArea();
                this.props.removeDrawnPolygon();
                this.toggle();
              }}
            >
              Save
            </Button>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

class SaveForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      description: "",
      soilType: "",
      owner: "",
      is_orchard: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleTreesNumericInfo = this.handleTreesNumericInfo.bind(this);
  }

  handleTreesNumericInfo(event) {
    let user_input = event.target.value;
    let filtered_input = user_input.replace(/[^0-9]/g, ""); //keep only numbers
    event.target.value = filtered_input;

    if (filtered_input == "") filtered_input = 0;
    this.setState({ [event.target.name]: parseInt(filtered_input) });
    this.props.onUpdate(event.target.name, parseInt(filtered_input));
  }

  handleChange(event) {
    if (event.target.name != "is_orchard") {
      this.setState({ [event.target.name]: event.target.value });
      this.props.onUpdate(event.target.name, event.target.value);
    } else {
      this.setState({ [event.target.name]: event.target.checked });
      this.props.onUpdate(event.target.name, event.target.checked);
    }
  }

  handleSwitch = (value) => {
    this.setState({
      is_orchard: value,
    });
    this.props.onUpdate("is_orchard", value);
  };

  render() {
    const { members: clients, soil_types, crops } = this.props;
    const layouts = ["Rectangle", "Triangle", "Diamond"];
    return (
      <Form>
        <FormGroup>
          <Label for="name">Name:</Label>
          <Input
            type="text"
            name="name"
            id="name"
            onChange={this.handleChange}
            placeholder="Enter a name"
          />
        </FormGroup>
        <FormGroup>
          <Label for="type">Orchard:</Label>
          <Switcher
            checked={false}
            onlabel="Yes"
            offlabel="No"
            onstyle="primary"
            offstyle="outline-primary"
            color="primary"
            width={70}
            size="sm"
            style="ml-3"
            onChange={this.handleSwitch}
          />
        </FormGroup>
        {this.state.is_orchard ? (
          <FormGroup>
            <Row>
              <Col lg="4" md="4" sm="4" xs="4">
                <Label for="trees_no">Number of trees:</Label>
                <Input
                  type="number"
                  required
                  min={1}
                  placeholder="e.g 150"
                  onChange={this.handleTreesNumericInfo}
                  name="num_trees"
                  id="num_trees"
                ></Input>
              </Col>
              <Col lg="5" md="5" sm="5" xs="4">
                <Label for="trees_lay">Trees layout:</Label>
                <Input
                  type="select"
                  required
                  defaultValue=""
                  placeholder="e.g 150"
                  onChange={this.handleChange}
                  name="layout_trees"
                  id="layout_trees"
                >
                  <option value="" disabled>
                    Select layout
                  </option>
                  {layouts.map((layout) => {
                    return (
                      <option value={layout} key={layout} id={layout + "_id"}>
                        {layout}
                      </option>
                    );
                  })}
                </Input>
              </Col>
              <Col lg="3" md="3" sm="3" xs="4">
                <Label for="trees_year">Active years:</Label>
                <Input
                  type="number"
                  required
                  min={0}
                  placeholder="e.g 2"
                  onChange={this.handleTreesNumericInfo}
                  name="years_input"
                  id="years_input"
                ></Input>
              </Col>
            </Row>
          </FormGroup>
        ) : null}
        <FormGroup>
          {/*Show the dropdown for choosing Client to assign, ONLY if the current logged-in user is Agronomist*/}
          <Authorize roles={[Role.Agronomist]}>
            <Label for="owner">Client:</Label>
            <Input
              type="select"
              required
              defaultValue=""
              onChange={this.handleChange}
              name="owner"
              id="owner"
            >
              <option value="" disabled>
                Select client to assign
              </option>
              {clients != null
                ? clients.map((client) => (
                    <option
                      value={client.user}
                      key={client.user}
                      id={client.user}
                    >
                      {client.profile.first_name +
                        " " +
                        client.profile.last_name}
                    </option>
                  ))
                : null}
            </Input>
          </Authorize>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col lg="6">
              <Label for="soil">Soil Type:</Label>
              <Input
                type="select"
                required
                defaultValue=""
                onChange={this.handleChange}
                name="soilType"
                id="SoilType"
              >
                <option value="" disabled>
                  Select soil type
                </option>
                {soil_types != null
                  ? soil_types.map((soil_type) => (
                      <option
                        value={soil_type.formal_name}
                        key={soil_type.formal_name}
                        id={soil_type.formal_name}
                      >
                        {soil_type.formal_name}
                      </option>
                    ))
                  : null}
              </Input>
            </Col>
            <Col lg="6">
              <Label for="crop">Crop:</Label>
              <Input
                type="select"
                required
                defaultValue="Free"
                onChange={this.handleChange}
                name="crop"
                id="crop"
              >
                <option value="Free" disabled>
                  {" "}
                  Select field crop{" "}
                </option>
                {crops != null
                  ? crops.map((crop) => (
                      <option value={crop.name} key={crop.id} id={crop.id}>
                        {crop.name}
                      </option>
                    ))
                  : null}
              </Input>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Label for="description">Description:</Label>
          <Input
            type="text"
            name="description"
            id="description"
            onChange={this.handleChange}
            placeholder="Enter a description"
          />
        </FormGroup>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    soil_types: state.areas.soil_types,
    members: state.members.members,
    crops: state.crops.crops,
  };
};

export default connect(mapStateToProps, {})(SavePolygonModal);
