import React, { Component, Fragment } from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane, Row, Col} from "reactstrap";
import classnames from 'classnames';
import MapFields from "../views/Fields/Map Tab/MapFields"
import ViewFields from "../views/Fields/Overview Tab/OverviewFields";


class FieldsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: '1',
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }
  render() {

    return (
      <Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}>
              Map
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}>
              Overview            
              </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col style={{height: '84vh'}} sm="12">
                <MapFields/>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <ViewFields/>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Fragment>
    );
  }
}


export default FieldsPage;