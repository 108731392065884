import React, { Component, createRef } from "react";
import { Col, Card, CardBody, CardHeader, Form } from 'reactstrap';
import Map from "../../../../../../partials/Map";
import { getBounds, zoomToField } from "../../../../../../helpers/geo";

class Covering extends Component{
  constructor(props){
    super(props)
    this.state={
        
    }
    this.createSVG = this.createSVG.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps){
      if(nextProps.files.length > 0){
        let image = nextProps.files[0].file
        this.createSVG(image);
      }
  }

  createSVG(image){
    var overlay_;
    SVGOverlay.prototype = new window.google.maps.OverlayView();

    var props = this.props;
    var el_ = document.getElementById("svg-map-"+this.props.field.id)
    if(el_ != undefined) initMap(el_);
    
    function initMap(el) {
        var bounds = getBounds(props.field)
        var map = new window.google.maps.Map(el, {
          zoom: 11,
          center: bounds.getCenter(),
          mapTypeId: 'satellite'
        });
      
        
        var bounds = getBounds(props.field)
        var srcImage = image
      
        // The custom SVGOverlay object contains the mosaic image,
        // the bounds of the image, and a reference to the map.
        overlay_ = new SVGOverlay(bounds, srcImage, map);
        zoomToField(props.field, map)
      }

    function SVGOverlay(bounds, image, map) {
        // Initialize all properties.
        this.bounds_ = bounds;
        this.image_ = image;
        this.map_ = map;
      
        // Define a property to hold the image's div. We'll
        // actually create this div upon receipt of the onAdd()
        // method so we'll leave it null for now.
        this.div_ = null;
      
        // Explicitly call setMap on this overlay.
        this.setMap(map);
      }

      SVGOverlay.prototype.onAdd = function() {
        var div = document.createElement('div');
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';
      
        // Create the img element and attach it to the div.
        var img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        div.appendChild(img);
      
        this.div_ = div;
      
        // Add the element to the "overlayLayer" pane.
        var panes = this.getPanes();
        panes.overlayLayer.appendChild(div);
      };

      SVGOverlay.prototype.draw = function() {
        // We use the south-west and north-east
        // coordinates of the overlay to peg it to the correct position and size.
        // To do this, we need to retrieve the projection from the overlay.
        var overlayProjection = this.getProjection();
      
        // Retrieve the south-west and north-east coordinates of this overlay
        // in LatLngs and convert them to pixel coordinates.
        // We'll use these coordinates to resize the div.
        var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());
      
        // Resize the image's div to fit the indicated dimensions.
        var div = this.div_;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';
      };
  }

  render(){    
    return(
        <Col key="1" sm="12" md="6" lg="8">
            <Card>
                <Form id="fieldForm1">
                    <CardHeader className="bg-primary text-white">UAV Covering</CardHeader>
                    <CardBody>
                        <Map el={"svg-map-"+this.props.field.id} height="100%"/>
                    </CardBody>
                </Form>
            </Card>
        </Col>
    )
  }
}

export default Covering