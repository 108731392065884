import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Row,
  Col,
  CustomInput,
  Button,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import Map from "../../../../../partials/Map";
import EmptyDataset from "../../../../../layout/EmptyDataset";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faEye,
  faSpinner,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import IndiceLegend from "../../../../../partials/Legends/IndiceLegend";
import { connect } from "react-redux";
import {
  getAreaHeatmap,
  generateAreaHeatmapManually,
} from "../../../../../../actions/areas";

class NDVI extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      file: "",
      data: [],
    };
    this.handleSelection = this.handleSelection.bind(this);
  }

  handleSelection(event) {
    this.setState({ [event.target.name]: event.target.value.toLowerCase() });
  }

  componentDidMount() {
    this.props.getAreaHeatmap(
      this.props.selectedField.field.id,
      "/?ordering=created_at&type=ndvi"
    );
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps != prevState) {
      let data_ = nextProps.selectedField.indices.ndvi;
      return { data: data_ };
    } else return null;
  }

  getDatasetDates(dataset_) {
    let dates = [];
    dataset_.forEach((data) => {
      if (!dates.includes(data.created_at)) dates.push(data.created_at);
    });
    return dates;
  }

  getKmlFile(dataset_, date_, visualization_, method_, classes_) {
    if (
      date_ == undefined ||
      visualization_ == undefined ||
      method_ == undefined ||
      classes_ == undefined
    )
      return "";

    let files = dataset_.filter((data) => data.created_at == date_);
    files = files.filter((data) => data.method == method_);

    let file = files.filter((data) => data.classes == classes_);
    if (file[0] == undefined) return null;
    return {
      kml: file[0][visualization_],
      legend: file[0][visualization_ + "_legend"],
    };
  }

  render() {
    const v_options = ["Mosaic", "Zones"];
    const v_method = ["Equal_interval", "Quantiles"];
    const v_classes = ["2", "3", "4", "5"];

    let fieldFullData = this.props.selectedField;
    let field = fieldFullData.field;
    if (
      fieldFullData.indices.ndvi == undefined ||
      fieldFullData.indices.ndvi.length == 0
    )
      return (
        <div className="d-block text-center p-4">
          <EmptyDataset message="There are no data." icon={faDatabase} />
          <Button
            color="secondary"
            className="mt-3"
            onClick={() => this.props.generateAreaHeatmapManually(field.id)}
          >
            Generate
          </Button>
        </div>
      );

    const dates_ = this.getDatasetDates(this.state.data);
    const data = this.getKmlFile(
      this.state.data,
      this.state.NDVI_Date,
      this.state.NDVI_Visualization,
      this.state.NDVI_Method,
      this.state.NDVI_Classes
    );

    return (
      <Form className="bg-light p-3">
        <FormGroup className="mb-0">
          <Row className="d-flex flex-row">
            <Col md={9} className="flex-grow">
              <Card className="border-0 h-100">
                <CardBody className="p-0 text-center">
                  {this.state.NDVI_Visualization == undefined ||
                  this.state.NDVI_Date == undefined ||
                  this.state.NDVI_Method == undefined ||
                  this.state.NDVI_Classes == undefined ? (
                    <EmptyDataset
                      message="Please select Visualization, Date, Method and Classes."
                      icon={faEye}
                    />
                  ) : data.kml == null ? (
                    <EmptyDataset
                      message="Visualization is still generated for the selected parameters."
                      icon={faSpinner}
                      spin="True"
                    />
                  ) : (
                    <Map
                      el={"ndvi-map-" + field.id}
                      height="100%"
                      minHeight="calc(85vh - 200px)"
                      file={data.kml}
                    >
                      <IndiceLegend legend={data.legend} />
                    </Map>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <FormGroup>
                <Label for="NDVI_Visualization">Visualization:</Label>
                <CustomInput
                  defaultValue="0"
                  type="select"
                  name="NDVI_Visualization"
                  id={"NDVI_Visualization_" + field.id}
                  onChange={this.handleSelection}
                >
                  <option disabled value="0">
                    Select visualization
                  </option>
                  {v_options.map((type, key) => {
                    return (
                      <option key={key} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="NDVI_Date">Date:</Label>
                <CustomInput
                  defaultValue="0"
                  type="select"
                  name="NDVI_Date"
                  id={"NDVI_Date_" + field.id}
                  onChange={this.handleSelection}
                >
                  <option disabled value="0">
                    Select date
                  </option>
                  {dates_.map((date, key) => {
                    return (
                      <option key={key} value={date}>
                        {date}
                      </option>
                    );
                  })}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="NDVI_Method">Method:</Label>
                <CustomInput
                  defaultValue="0"
                  type="select"
                  name="NDVI_Method"
                  id={"NDVI_Method_" + field.id}
                  onChange={this.handleSelection}
                >
                  <option disabled value="0">
                    Select method
                  </option>
                  {v_method.map((type, key) => {
                    return (
                      <option key={key} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Label for="NDVI_Classes">Classes:</Label>
                <CustomInput
                  defaultValue="0"
                  type="select"
                  name="NDVI_Classes"
                  id={"NDVI_Classes_" + field.id}
                  onChange={this.handleSelection}
                >
                  <option disabled value="0">
                    Select classes
                  </option>
                  {v_classes.map((type, key) => {
                    return (
                      <option key={key} value={type}>
                        {type}
                      </option>
                    );
                  })}
                </CustomInput>
              </FormGroup>
              <FormGroup>
                <Button
                  color="primary"
                  className="mt-3"
                  onClick={() =>
                    this.props.getAreaHeatmap(
                      field.id,
                      "/?ordering=created_at&type=ndvi"
                    )
                  }
                >
                  {" "}
                  <FontAwesomeIcon className="mr-2" icon={faSync} />
                  <span>Sync</span>
                </Button>
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    );
  }
}

export default connect(null, { getAreaHeatmap, generateAreaHeatmapManually })(
  NDVI
);
