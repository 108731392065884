import React, { Component, createRef, Fragment } from "react";
import classnames from "classnames";
import { connect } from 'react-redux';

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      el: this.props.el,
      map: null,
      loaded: false,
      heatmapKMLlayer: {}
    };

    this.init = this.init.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    this.state.heatmapKMLlayer.setUrl(nextProps.file);
  }

  componentDidMount() {
    this.init();
  }

  /**
   *  Initializes Map Component
   */
  init() {
    this.createGoogleMap();

    this.setState({ loaded: !this.state.loaded });
  }

  /**
   *  Creates the GoogleMap object and attaches it to the state
   */
  createGoogleMap() {
    if (this.state.el == undefined || this.state.el === "") {
      console.error("Please specify (el) parameter to the map component.");
      return;
    }

    let map = new window.google.maps.Map(document.getElementById(this.props.el), {
      center: new window.google.maps.LatLng(39.3666667, 22.9458333),
      zoom: 7,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      disableDefaultUI: true,
      zoomControl: true,
      streetViewControl: false,
      zoomControlOptions: {
        position: window.google.maps.ControlPosition.RIGHT_CENTER
      },
      fullscreenControl: false
    });

    this.setState({ 
      map: map,
      heatmapKMLlayer: new window.google.maps.KmlLayer({
        url: this.props.file,
        map: map
      })
     });
  }

  render() {
    return (
      <Fragment>
      <div className="map-wrapper position-relative h-100">
        <div
          id={this.props.el}
          className={classnames("g-map mh-100", this.props.classes)}
          style={{ height: this.props.height,
          minHeight: this.props.minHeight }}
        >
        </div>
        { this.props.children}
      </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
    return{
        selectedField: state.areas.selectedField.field,
        files: state.areas.selectedField.files
    }
}

export default connect(mapStateToProps)(Map);
