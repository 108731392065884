/**
 *  Helper function to retrieve all wind arrow files
 * @param {*} context
 */
export const importArrows = function (context) {
  var files = {};
  context.keys().map((key) => {
    var origin = key.toString().split("/")[1].split(".")[0];
    files[origin] = context(key);
  });

  return files;
};

/**
 *  Helper function to retrieve all files of a specific
 *  context as an object
 * @param {*} context
 */
export const importMarkers = function (context) {
  return context.keys().map(context);
};
