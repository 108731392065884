
import { GET_TRACTORS, DELETE_TRACTOR, ADD_TRACTOR,EDIT_TRACTOR, UPDATE_TRACTOR } from '../actions/types';

const initialState = {
    tractors: [],
    editing: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_TRACTORS:
            return {
                ...state,
                tractors: action.payload
            }
        case DELETE_TRACTOR:
            return {
                ...state,
                tractors: state.tractors.filter(tractor => 
                    tractor.id !== action.payload)
            }
        case ADD_TRACTOR:
            return {
                ...state,
                tractors: [...state.tractors, action.payload]
            }
        case EDIT_TRACTOR:
            return {
                ...state,
                editing: true
            }
        case UPDATE_TRACTOR:
                return {
                    ...state,
                    tractors: state.tractors.map(tractor => {
                        if(tractor.id === action.payload.id) {
                            return {
                                ...tractor,
                                name: action.payload.name,
                                wheels: action.payload.wheels,
                                brand: action.payload.brand,
                                model: action.payload.model,
                                purchase_value: action.payload.purchase_value,
                                engine_power: action.payload.engine_power,
                                max_power: action.payload.max_power,
                                purchase_year: action.payload.purchase_year,
                                operation_width: action.payload.operation_width,
                                description: action.payload.description,
                            }
                        }
                        else {
                            return tractor;
                        }
                    })
                }
        default:
            return state
    }
}