import React from "react";
import { connect } from "react-redux";

const Authorize = props => {
  const { roles } = props;

  return roles && !props.auth.user.groups.some(role => roles.includes(role))
    ? ""
    : props.children;
};

const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps, {})(Authorize);
