
import { GET_CULTIVATIONS, DELETE_CULTIVATION, ADD_CULTIVATION, EDIT_CULTIVATION, UPDATE_CULTIVATION } from '../actions/types';

const initialState = {
    cultivations: [],
    editing: false,
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_CULTIVATIONS:
            return {
                ...state,
                cultivations: action.payload
            }
        case DELETE_CULTIVATION:
            return {
                ...state,
                cultivations: state.cultivations.filter(cultivation => 
                    cultivation.id !== action.payload)
            }
        case ADD_CULTIVATION:
            return {
                ...state,
                cultivations: [...state.cultivations, action.payload]
            }
        case EDIT_CULTIVATION:
            return {
                ...state,
                editing: true
            }
        case UPDATE_CULTIVATION:
                return {
                    ...state,
                    cultivations: state.cultivations.map(cultivation => {
                        if(cultivation.id === action.payload.id) {
                            return {
                                ...cultivation,
                                name: action.payload.name,
                                description: action.payload.description,
                                color: action.payload.color,
                            }
                        }
                        else {
                            return cultivation;
                        }
                    })
                }
        default:
            return state
    }
}