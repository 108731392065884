import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input,
  Button,
} from "reactstrap";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { clearErrors } from "../../..//../actions/messages";
import { loadUser, updateUser } from "../../../../actions/auth";
import { updateMember } from "../../../../actions/members";
import { LoadingSpinerComponent } from "../../../partials/LoadingSpiner";

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      first_name: "",
      last_name: "",
      father_name: "",
      afm: "",
      amka: "",
      mobile_phone: "",
      iban: "",
      postal_code: "",
      submitted: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.submitted && !nextProps.errors.hasErrors)
      nextProps.toggleEdit();
    else if (nextProps.errors.hasErrors)
      this.setState({submitted:false})
  }

  componentDidMount() {
    this.props.clearErrors();
    this.mapReduxFieldPropsToState(this.props);
  }

  mapReduxFieldPropsToState = (props) => {
    const {
      email,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    } = props.profile == undefined ? props.user : props.profile; //if member.profile prop has been given, deconstruct it and use it. Otherwise, use given user's values

    this.setState({
      email,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCancel = (e) => {
    e.preventDefault();
    this.props.clearErrors();
    this.props.toggleEdit();
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitted: true });
    this.props.clearErrors();

    const {
      email,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    } = this.state;

    const user = Object.assign(
      {},
      {
        email,
        first_name,
        last_name,
        father_name,
        afm,
        amka,
        mobile_phone,
        iban,
        postal_code,
      }
    );

    if (Object.keys(this.props).includes("profile")) this.props.updateMember(this.props.profile.id, user);
    else this.props.updateUser(user);
  };

  render() {
    const {
      email,
      first_name,
      last_name,
      father_name,
      afm,
      amka,
      mobile_phone,
      iban,
      postal_code,
    } = this.state

    const errors = this.props.errors;

    return (
      <Card className="border-0">
        <Form id="personalInfoForm" onSubmit={this.onSubmit}>
          <div>
            <LoadingSpinerComponent
              className="loginOverlay"
              area="personalInfoForm"
            ></LoadingSpinerComponent>
          </div>
          <CardHeader className="bg-dark text-white">
            Profile Settings
          </CardHeader>
          <CardBody>
            <h5>Personal Information</h5>
            <hr />
            <Row form className="mb-3">
              <Col md={4}>
                <Label for="first_name">First Name:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="first_name"
                    id="first_name"
                    value={first_name}
                    invalid={errors.hasErrors && errors.msg.first_name}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.first_name}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label for="last_name">Last Name:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="last_name"
                    id="last_name"
                    value={last_name}
                    invalid={errors.hasErrors && errors.msg.last_name}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.last_name}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label for="father_name">Father Name:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="father_name"
                    id="father_name"
                    value={father_name}
                    invalid={errors.hasErrors && errors.msg.father_name}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.father_name}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <h5>Contact Information</h5>
            <hr />
            <Row form className="mb-3">
              <Col md={5}>
                <Label for="email">E-mail Address:</Label>
                <FormGroup>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    invalid={errors.hasErrors && errors.msg.email}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.email}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={4}>
                <Label for="mobile_phone">Mobile Phone:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="mobile_phone"
                    id="mobile_phone"
                    value={mobile_phone}
                    invalid={errors.hasErrors && errors.msg.mobile_phone}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.mobile_phone}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={3}>
                <Label for="postal_code">Postal Code:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="postal_code"
                    id="postal_code"
                    value={postal_code}
                    invalid={errors.hasErrors && errors.msg.postal_code}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.postal_code}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <h5>Unique Social Numbers</h5>
            <hr />
            <Row form>
              <Col md={6}>
                <Label for="afm">VAT:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="afm"
                    id="afm"
                    value={afm}
                    invalid={errors.hasErrors && errors.msg.afm}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.afm}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Label for="amka">AMKA:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="amka"
                    id="amka"
                    value={amka}
                    invalid={errors.hasErrors && errors.msg.amka}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.amka}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <Label for="iban">IBAN:</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="iban"
                    id="iban"
                    value={iban}
                    invalid={errors.hasErrors && errors.msg.iban}
                    onChange={this.onChange}
                  />
                  <FormFeedback invalid={errors.hasErrors ? "true" : "false"}>
                    {errors.msg.iban}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="text-right">
            <Button color="primary" className="mr-2">
              Update
            </Button>
            <Button color="danger" className="mr-2" onClick={this.onCancel}>
              Cancel
            </Button>
          </CardFooter>
        </Form>
      </Card>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
    messages: state.messages,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps, { updateUser, clearErrors, updateMember })(
  EditProfile
);

EditProfile.propTypes = {
  updateUser: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
};
